import { useEffect, useRef, useState } from 'react';

import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { GridDetailledData } from '@app/types/types';
import moment from 'moment';
import { Column, ColumnBodyOptions } from 'primereact/column';
import {
  DataTable,
  DataTableExpandedRows,
  DataTableRowData,
  DataTableRowEvent,
  DataTableRowToggleEvent,
  DataTableSelectionSingleChangeEvent,
  DataTableValue,
  DataTableValueArray,
} from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import colors from '@styles/colors';

// Define a type for your data
interface tableRow extends DataTableValueArray {
  name: string;
  // Define other properties here
}

const HtmlTooltip = (props: TooltipProps) => <Tooltip {...props}></Tooltip>;
interface props {
  fullComponentSize: boolean;
  noTitle?: boolean;
  IdHotel?: number;
  openOnLoad?: boolean;
}

const DataGridView: React.FC<props> = ({ noTitle, IdHotel, openOnLoad }) => {
  const toast = useRef<Toast>(null);
  const userCTX = useUser();
  const filtersCTX = useFilters();
  const dashboardFilters = filtersCTX.filters.dashboardFilters;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const [expandedRows, setExpandedRows] = useState<LineData[] | DataTableExpandedRows>([]);
  const [selectedDetail, setSelectedDetail] = useState<{ [hotelId: string]: DataTableValueArray }>({});
  const authData = userCTX.authenticationInfos;
  const dashboarCTX = useDashboard();
  const gridData = dashboarCTX.GridData;
  const gridDetail = dashboarCTX.GridDetail;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  useEffect(() => {
    if (!openOnLoad) {
      if (gridData) {
        if (expandedRows) {
          (expandedRows as LineData[]).forEach((element) => {
            if (element?.IdHotel) {
              dashboarCTX.getGridDetail({
                dashboardFilters,
                params: { isTech, selectedHotel, IdHotel: element.IdHotel },
              });
            }
          });
        } else {
          if (gridData && IdHotel && IdHotel !== undefined && isTech !== undefined) {
            dashboarCTX.getGridDetail({
              dashboardFilters,
              params: { isTech, selectedHotel, IdHotel: selectedHotel?.IdHotel },
            });
          }
        }
      }
    }
  }, [isTech, gridData, IdHotel, selectedHotel, expandedRows, openOnLoad]);
  useEffect(() => {
    if (gridDetail !== null && gridData && IdHotel !== undefined) {
      setExpandedRows([gridData.Data.filter((e) => e.IdHotel === IdHotel)[0]]);
    }
  }, [gridData, gridDetail, isTech, IdHotel]);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const onRowExpand = (event: DataTableRowEvent) => {
    if (location.pathname.includes('hotel')) {
      dashboarCTX.getGridDetail({
        dashboardFilters,
        params: { isTech, selectedHotel, IdHotel: event.data.IdHotel },
      });
    }
  };

  const onRowCollapse = (event: DataTableRowEvent) => {
    toast.current?.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  };
  /* const { setSubForm, subForm, hideSubForm } = useSubForm();
   */
  /*
  const addNewSubform = () => {
    const newSubform = {
      id: subForm.listOfSubformsDisplayed.length,
      component: MobileGridDetail, // Replace with your subform component
      translatedTitle: t('chart.grid.title'),
      data: null, 
      actions: { hideSubForm }, 
      draggable: false,
      fullWidth: true,
      fullHeight: true,
    };

    setSubForm((prevState) => ({
      ...prevState,
      listOfSubformsDisplayed: [...prevState.listOfSubformsDisplayed, newSubform],
    }));
  };
*/
  const formatHeader = (dateToFormat: string) => {
    const dateTemp = new Date(dateToFormat);
    let localLanguage = authData.language;
    let dayLetter = '';
    if (localLanguage === 'cn') {
      localLanguage = 'zh-CN';
      dayLetter = dateTemp.toLocaleString(localLanguage, { weekday: 'short' })[1];
    } else if (localLanguage === 'ma') {
      localLanguage = 'ar-MA';
      dayLetter = dateTemp.toLocaleString(localLanguage, { weekday: 'short' });
    } else {
      dayLetter =
        dateTemp.toLocaleString(localLanguage, { weekday: 'short' })[0] +
        dateTemp.toLocaleString(localLanguage, { weekday: 'short' })[1];
    }

    const dayOfMonth = dateTemp.getDate();

    return (
      <>
        {dayLetter.toUpperCase()}
        <br />
        {dayOfMonth.toString().length === 1 ? '0' + dayOfMonth.toString() : dayOfMonth.toString()}
      </>
    );
  };
  const rowClass = (data: DataTableValue) => {
    return {
      'total-GridLine': data.DataType === 2 || data.DataType === 4 || data.DataType === 8,
    };
  };

  const formatRoomType = (data: DataTableValue) => {
    let label = '';
    if (data.DataType === 8 || data.DataType === 2) {
      label = t('grid.label.total');
    } else if (data.DataType === 5) {
      label = t('grid.label.TO');
    } else if (data.DataType === 6) {
      label = t('grid.label.TOLY');
    } else if (data.DataType === 3) {
      label = t('grid.label.ALLOTMENT');
    } else if (data.DataType === 4) {
      label = t('grid.label.TOTALTYPEAFTERALLOTMENT');
    } else if (data.DataType === 9) {
      label = t('grid.label.options');
    } else if (data.DataType === 10) {
      label = t('grid.label.OOO');
    } else if (data.DataType === 11) {
      label = t('grid.label.arrival');
    } else if (data.DataType === 12) {
      label = t('grid.label.departure');
    } else if (data.DataType === 13) {
      label = t('grid.label.PAX');
    } else if (data.DataType === 16) {
      label = t('grid.label.NOTALLOCATED');
    } else if (data.DataType === 17) {
      label = t('grid.label.TYPESOVERBOOKED');
    } else {
      label = data.Name;
    }
    if (data.Description !== undefined) {
      return (
        <HtmlTooltip title={<Typography>{data.Description}</Typography>}>
          <div style={{ paddingRight: '10px' }}>{label}</div>
        </HtmlTooltip>
      );
    } else {
      return <div style={{ paddingRight: '10px' }}>{label}</div>;
    }
  };
  const formatBody = (data: DataTableValue, option: ColumnBodyOptions) => {
    const IdHotel: string = option.props.id;
    if (gridDetail) {
      const lastValue =
        gridDetail[IdHotel].Data.find((e) => e.DataType === 6)?.Data[parseInt(option.field)].Value ?? 100000;
      if (
        gridDetail[IdHotel]?.Data[option.rowIndex]?.Data[parseInt(option.field)]?.Value &&
        gridDetail[IdHotel]?.Data.find((e) => e.DataType === 6) &&
        gridDetail[IdHotel]?.Data[option.rowIndex].DataType === 5 &&
        gridDetail[IdHotel]?.Data.find((e) => e.DataType === 6) !== undefined &&
        gridDetail[IdHotel]?.Data[option.rowIndex]?.Data[parseInt(option.field)]?.Value < lastValue
      ) {
        return (
          <>
            <div style={{ color: 'red' }}>
              {gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value === 0
                ? ''
                : gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value + '%'}
            </div>
          </>
        );
      } else if (
        gridDetail[IdHotel].Data[option.rowIndex].DataType === 5 ||
        gridDetail[IdHotel].Data[option.rowIndex].DataType === 6
      ) {
        return (
          <>
            <div style={{ fontWeight: '900' }}>
              {gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value === 0
                ? ''
                : gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value + ' %'}
            </div>
          </>
        );
      } else if (
        gridDetail[IdHotel].Data[option.rowIndex].DataType === 4 ||
        gridDetail[IdHotel].Data[option.rowIndex].DataType === 8 ||
        gridDetail[IdHotel].Data[option.rowIndex].DataType === 2
      ) {
        return (
          <>
            <div style={{ fontWeight: '900' }}>
              {gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value === 0
                ? ''
                : gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value}
            </div>
          </>
        );
      }
      return (
        <>
          <div
            style={{
              color:
                gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Class === 'red' ? 'red' : '',
            }}
          >
            {gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value === 0
              ? ''
              : gridDetail[IdHotel].Data[option.rowIndex].Data[parseInt(option.field)].Value}
          </div>
        </>
      );
    }
  };

  const hotelTemplate = (row: DataTableValue) => {
    return (
      <div className="clickableLink" onClick={() => navigate('/hotel/' + row.IdHotel)}>
        {row.Name}
      </div>
    );
  };
  const handleRowSelection = (e: DataTableSelectionSingleChangeEvent<GridDetailledData>, hotelId: string) => {
    const selectedData = e.value as DataTableValueArray[];

    setSelectedDetail((prevSelectedRows) => ({
      ...prevSelectedRows,
      [hotelId]: selectedData,
    }));
  };
  const rowExpansionTemplate = (data: DataTableRowData<tableRow>) => {
    return (
      <>
        {gridDetail && data.IdHotel && (gridDetail[data.IdHotel as string] as GridDetailledData) && (
          <>
            {/* Main */}
            <div className="detailBorderDiv">
              <DataTable
                id={data.IdHotel}
                selectionMode="single"
                selection={selectedDetail[data.IdHotel]}
                onSelectionChange={(e: DataTableSelectionSingleChangeEvent<GridDetailledData>) =>
                  handleRowSelection(e, data.IdHotel)
                }
                value={gridDetail[data.IdHotel]?.Data}
                showGridlines
                showHeaders={true}
                className="detailGrid"
                rowClassName={rowClass}
              >
                <Column
                  key={`IdHotel`}
                  field={'Name'}
                  header={t('grid.label.roomType')}
                  frozen
                  className={'cell-to-hide titleTr'}
                  style={{
                    minWidth: '250px',
                    maxWidth: '250px',
                    whiteSpace: 'nowrap',
                    display: 'table-cell',
                    textAlign: 'right',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  body={formatRoomType}
                ></Column>

                {gridDetail[data.IdHotel]?.Columns.map((e: ColumnDetail) => {
                  return (
                    <Column
                      key={'IdHotel' + e.KeyIterator}
                      field={e.KeyIterator.toString()}
                      header={
                        e.Label !== '' && e.Label !== undefined ? (
                          <HtmlTooltip
                            title={
                              <>
                                <Typography>
                                  {e.Label}
                                  <br />
                                  {t('header.label.from') +
                                    ' ' +
                                    new Date(e.DateStart).toLocaleDateString(authData.user?.Culture, {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    }) +
                                    ' ' +
                                    t('header.label.to') +
                                    ' ' +
                                    new Date(e.DateEnd).toLocaleDateString(authData.user?.Culture, {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    })}
                                </Typography>
                              </>
                            }
                          >
                            <div
                              style={{
                                color: e.Foreground,
                                backgroundColor: e.Background,
                                width: '100%',
                                cursor: 'pointer',
                              }}
                            >
                              {formatHeader(e.Value)}
                            </div>
                          </HtmlTooltip>
                        ) : (
                          <div style={{ color: e.Color, width: '100%', cursor: 'not-allowed' }}>
                            {formatHeader(e.Value)}
                          </div>
                        )
                      }
                      headerStyle={{
                        fontSize: '9pt',

                        textAlign: 'center',
                      }}
                      style={{
                        minWidth: '40px',
                        maxWidth: '40px',
                        alignContent: 'center',
                        textAlign: 'center',

                        overflow: 'hidden',
                      }}
                      body={formatBody}
                    ></Column>
                  );
                })}
              </DataTable>
            </div>
          </>
        )}
      </>
    );
  };
  /*
  const mobileDataTableTemplate = (data: { [key: string]: any; statistics: { [formattedDate: string]: number } }) => {
    return (
      <DataTable
        value={Object.entries(data.statistics)}
        paginator
        style={{ width: '100%' }}
        stripedRows
        rows={5}
        rowsPerPageOptions={[5, 10, 15]}
        currentPageReportTemplate="{first} - {last} / {totalRecords}"
        paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
      >
        <Column
          field="0"
          header="Jour"
          className="font-bold"
          style={{
            minWidth: '150px',

            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        />
        <Column
          field="1.stat"
          header="Valeur"
          className="font-bold"
          style={{
            minWidth: '150px',

            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        />
      </DataTable>
    );
  };

  */

  return (
    <div className={`small-box bg-gradient-light`}>
      {/*viewMode === 'mobileMode' && (
            <>
              {noTitle === false && (
                <Typography component="h1" variant="h6" sx={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>
                  {t('chart.grid.title')}
                </Typography>
              )}
              {transformedData.length >= 1 && (
                <Accordion activeIndex={0} multiple>
                  {transformedData.map((elt, i) => {
                    return (
                      <AccordionTab
                        key={i}
                        header={elt.name}
                        style={{ justifyContent: 'center', textAlign: 'center', display: 'block', width: '100%' }}
                      >
                        {mobileDataTableTemplate(elt)}
                        <Button
                          style={{ margin: '20px', width: '50%', textAlign: 'center', justifyContent: 'center' }}
                          onClick={addNewSubform}
                        >
                          Detail
                        </Button>
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              )}
              {transformedData.length === 1 && (
                <div
                  style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                >
                  {mobileDataTableTemplate(transformedData[0])}

                  <Button
                    style={{ margin: '20px', width: '50%', textAlign: 'center', justifyContent: 'center' }}
                    onClick={addNewSubform}
                  >
                    Detail
                  </Button>
                </div>
              )}
            </>
              )*/}
      <div className="small-box" style={{ borderRadius: '8px' }}>
        {noTitle === false && (
          <>
            {' '}
            <Typography component="h1" variant="h6" sx={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>
              {t('chart.grid.title')}
            </Typography>
            <Divider variant="middle" />
          </>
        )}
        <DataTable
          className={(darkMode ? 'dark' : '') + ' normalDT'}
          value={IdHotel !== undefined ? gridData?.Data.filter((e) => e.IdHotel === IdHotel) : gridData?.Data}
          paginator={IdHotel !== undefined ? false : true}
          expandedRows={expandedRows}
          stripedRows
          reorderableRows
          onRowToggle={(e: DataTableRowToggleEvent) => {
            setExpandedRows(e.data);
          }}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          rowExpansionTemplate={rowExpansionTemplate}
          rows={15}
          rowsPerPageOptions={[5, 15, 25, 50]}
          scrollable
          rowHover
        >
          <Column
            expander={true}
            frozen
            style={{
              backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
              minWidth: '50px',
              maxWidth: '50px',
              color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
            }}
          />
          <Column
            key={'KeyIterator'}
            field={'Name'}
            header={t(`calendar.monthNames.${moment(new Date()).month()}`)}
            frozen
            className="font-bold"
            style={{
              minWidth: '200px',
              maxWidth: '150px',
              backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
              color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          ></Column>
          {gridData?.Columns.map((e) => {
            return (
              <Column
                key={'KeyIterator'}
                field={`Data.${e.KeyIterator}.Value`}
                header={formatHeader(gridData.Columns.find((t) => t.KeyIterator === e.KeyIterator)?.Value || '')}
                className=""
                style={{
                  minWidth: '40px',
                  maxWidth: '40px',
                  alignContent: 'center',
                  textAlign: 'center',

                  overflow: 'hidden',
                }}
              ></Column>
            );
          })}
        </DataTable>
        {/*tableaux des tottaux */}
        {IdHotel === undefined && (
          <>
            <DataTable value={gridData?.Total} className={(darkMode ? 'dark' : '') + ' footer'}>
              <Column
                expander={false}
                frozen
                style={{
                  backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                  minWidth: '50px',
                  maxWidth: '50px',
                  color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
                }}
              />
              <Column
                key={'KeyIterator'}
                field={'Total'}
                header={t('grid.label.total')}
                frozen
                body={hotelTemplate}
                className="font-bold"
                style={{
                  minWidth: '200px',
                  maxWidth: '150px',
                  backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                  color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              ></Column>
              {gridData?.Columns.map((e) => {
                return (
                  <Column
                    key={'KeyIterator'}
                    header={gridData.Total.find((t) => t.KeyIterator === e.KeyIterator)?.Value.toString()}
                    style={{
                      minWidth: '40px',
                      maxWidth: '40px',
                      alignContent: 'center',
                      textAlign: 'center',

                      overflow: 'hidden',
                    }}
                  ></Column>
                );
              })}
            </DataTable>
            <DataTable value={gridData?.To} className={(darkMode ? 'dark' : '') + ' footer'}>
              <Column
                expander={false}
                frozen
                style={{
                  backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                  minWidth: '50px',
                  maxWidth: '50px',
                  color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
                }}
              />
              <Column
                key={'KeyIterator'}
                field={'T.O.'}
                header={t('grid.label.TO')}
                frozen
                body={hotelTemplate}
                className="font-bold"
                style={{
                  minWidth: '200px',
                  maxWidth: '150px',
                  backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                  color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              ></Column>
              {gridData?.Columns.map((e) => {
                return (
                  <Column
                    key={'KeyIterator'}
                    header={gridData.To.find((t) => t.KeyIterator === e.KeyIterator)?.Value.toString() + '%'}
                    style={{
                      minWidth: '40px',
                      maxWidth: '40px',
                      alignContent: 'center',
                      textAlign: 'center',

                      overflow: 'hidden',
                    }}
                  ></Column>
                );
              })}
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
};

export default DataGridView;
