import React from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const userCTX = useUser();
  const isLoggedIn = userCTX.authenticationInfos.user;
  return isLoggedIn ? (
    <div id="root2">
      {' '}
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
