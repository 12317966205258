export type paramsType = {
  // Small names => smaller persisted JSON
  lang: string;
  username: string;
  isLoggedIn(): boolean;
};

export const storage = {
  getParam(key: string): string | null {
    const item = localStorage.getItem(key);

    return item;
  },
  setParam(key: string, value: string): void {
    return localStorage.setItem(key, value);
  },
  removeParam(key: string): void {
    return localStorage.removeItem(key);
  },
};
