import React, { useEffect, useState } from 'react';

import { useWindowSize } from '@app/hooks/useWindowSize';
import { setWindowSize } from '@app/store/reducers/ui';
import { calculateWindowSize } from '@app/utils/helpers';
import Login from '@modules/login/Login';
import Main from '@modules/main/Main';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Import Outlet for nested routes
import { ToastContainer } from 'react-toastify';

import Dashboard from '@pages/Dashboard';

import Loader from './components/Common/Loader';
import { useAnalytics } from './modules/Analytics/AnalyticsContext';
import { BookingProvider } from './modules/Booking/BookingsContext';
import { DashboardProvider } from './modules/dashboard/DashboardContext';
import { useDataFactory } from './modules/DataFactory/DataFactoryContext';
import { useFilters } from './modules/filter/filterContext';
import { HotelProvider } from './modules/hotel/HotelContext';
import { PlanningProvider } from './modules/planning/PlanningContext';
import { useUser } from './modules/user/UserContext';
import Admin from './pages/Admin';
import Performance from './pages/Analytics/Performance';
import Pickup from './pages/Analytics/Pickup';
import Repartition from './pages/Analytics/Repartition';
import Dev from './pages/dev/Dev';
import Hotel from './pages/hotel/Hotel';
import Installation from './pages/Installation/Installation';
import MainCourante from './pages/MainCourante/MainCourante';
import Planning from './pages/Planning/planning';
import Prevision from './pages/Prevision/Prevision';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import { getCallInProgress } from './services/apiState';
import { storage } from './store/localstorage/localStorage';
import { RootState } from './store/store';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
const App = () => {
  const customCrosshairPlugin = {
    id: 'customCrosshair',
    afterDraw: (chart: any) => {
      if (chart?.tooltip?._active?.length) {
        const ctx = chart?.ctx;
        ctx.save();

        const activePoint = chart.tooltip._active[0];
        const { x } = activePoint.element;
        const topY = chart?.scales?.y?.top;
        const bottomY = chart?.scales?.y?.bottom;

        // Draw vertical line
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#F66';
        ctx.stroke();

        ctx.restore();
      }
    },
  };

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LinearScale,
    annotationPlugin,
    LineElement,
    Title,
    Tooltip,
    Legend,
    customCrosshairPlugin,
  );

  const windowSize = useWindowSize();
  const screenSize = useSelector((state: RootState) => state.ui.screenSize);
  const dispatch = useDispatch();
  const userCTX = useUser();
  const forceReload = userCTX.forceLoading;
  const isLoggedIn = userCTX.authenticationInfos.user;
  const userWebPubSubUrl = userCTX.authenticationInfos.userWebPubSubUrl;
  const [callInProgress, setCallInProgress] = useState(false);
  const dataFactoryCTX = useDataFactory();
  const filtersCTX = useFilters();
  const analyticsCTX = useAnalytics();
  useEffect(() => {
    const checkCallInProgress = () => {
      if (getCallInProgress() > 0) {
        setCallInProgress(true);
      } else {
        setCallInProgress(false);
      }
    };

    checkCallInProgress();

    const interval = setInterval(checkCallInProgress, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userCTX.authenticationInfos.user === null) {
      dataFactoryCTX.setDataDictionnary(null);
      dataFactoryCTX.setPMSSettings(null);
      filtersCTX.setFilters({
        planningFilters: {
          DateStart: new Date().getTime(),
          DatePointStart: new Date().getTime(),
          DatePointEnd: new Date().getTime(),
          DateEnd: moment(Date.now()).add(18, 'd').toDate().getTime(),
          IdHotel: 0,
          SelectedHotels: null,
          TypeToDisplay: null,
          CurrentSort: 1,
          ForceOneDay: false,
          IsCollapse: false,
          needReload: false,
          dayToDisplay: 21,
          hasBeenModified: false,
          showDeparture: false,
        },
        dashboardFilters: {
          TypeFilters: ['TO', 'PM', 'RevPar', 'Bookings', 'IF', 'ALOS', 'RevPor', 'Occupied'],
          DateStart: new Date().getTime(),
          DateEnd: moment(Date.now()).add(20, 'd').toDate().getTime(),
          IdHotel: null,
          IncludeAllotments: true,
          PickupDate: new Date().getTime(),
          PickupDifferences: true,
          DisplayMode: 1,
          needReload: false,
          hasBeenModified: false,
        },
        mainCouranteFilters: {
          DateStart: moment(Date.now()).subtract(1, 'month').subtract(1, 'day').toDate().getTime(),
          DateEnd: moment(new Date()).subtract(1, 'day').toDate().getTime(),
          ListHotels: [],
          IdHotel: [0],
          CategoryType: 0,
          IncludingTaxes: false,
          needReload: false,
          hasBeenModified: false,
        },
        previsionFilters: {
          DateStart: new Date().getTime(),
          DateEnd: moment(Date.now()).add(20, 'd').toDate().getTime(),
          ListHotels: [],
          IdHotel: [],
          ForecastType: 0,
          IncludingOptions: true,
          IncludingTaxes: true,
          needReload: false,
          hasBeenModified: false,
        },
        pickupFilters: {
          Date: new Date().getTime(),
          IdHotel: [],
          ListHotels: [],
          needReload: false,
          hasBeenModified: false,
        },
        performanceFilters: {
          DateStart: new Date(),
          DateEnd: new Date(),
          IdHotel: [],
          ListHotels: [],
          Categories: [],
          GroupBy: -1,
          YearToCompare: 0,
          hasBeenModified: false,
          needReload: false,
        },
        repartitionFilters: {
          DateStart: new Date(),
          DateEnd: new Date(),
          IdHotel: [],
          Category: null,
          AggregationType: null,
          Max: 0,
          KpiAreProrata: false,
          hasBeenModified: false,
          ListHotels: [],
          needReload: false,
          GroupBy: 0,
        },
      });
      analyticsCTX.setAnalyticsBookingPickupData(null);
      analyticsCTX.setAnalyticsPerformance(null);
      analyticsCTX.setAnalyticsRepartition(null);
    }
  }, [userCTX.authenticationInfos.user]);

  const connect = () => {
    if (!userWebPubSubUrl) return;
    const ws = new WebSocket(userWebPubSubUrl);

    ws.onopen = () => {
      userCTX.setUser((prevState) => ({
        ...prevState,
        wsState: ws,
      }));
    };
    ws.onmessage = (event) => {
      const message: WsToTreat = JSON.parse(event.data);
      if (message.sender !== userWebPubSubUrl) {
        userCTX.setUser((prevState) => ({
          ...prevState,
          wsToTreat: message,
        }));
      }
    };
    ws.onerror = () => {
      userCTX.setUser((prevState) => ({
        ...prevState,
        wsState: null,
      }));
    };

    ws.onclose = () => {
      userCTX.setUser((prevState) => ({
        ...prevState,
        wsState: null,
      }));
    };
  };
  useEffect(() => {
    if (isLoggedIn !== null && userCTX.authenticationInfos.userWebPubSubUrl === null) {
      if (userCTX.authenticationInfos.wsState === null) {
        userCTX.getTokenWebPubSub().finally(() => connect());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userCTX]);

  //      userCTX.getTokenWebPubSub().finally(() => checkSession());

  useEffect(() => {
    if (isLoggedIn) {
      if (userCTX.authenticationInfos.user?.IsAdmin === true) {
        userCTX.getAllHotel();
      } else {
        userCTX.getHotelVisibility(
          userCTX.authenticationInfos.user?.OwningHotel as number,
          userCTX.authenticationInfos.user?.Id as string,
          false,
        );

        if (
          storage &&
          storage.getParam('hotelList') !== null &&
          storage.getParam('hotelList') !== 'null' &&
          storage.getParam('hotelList') !== 'undefined'
        ) {
          userCTX.setUser((prevState) => ({
            ...prevState,
            listHotel: JSON.parse(storage.getParam('hotelList') ?? ''),
          }));
        }
        if (storage && storage.getParam('selectedHotel') !== null && storage.getParam('selectedHotel') !== 'null') {
          userCTX.setUser((prevState) => ({
            ...prevState,
            selectedHotel: JSON.parse(storage.getParam('selectedHotel') ?? ''),
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (storage.getParam('needToRelog') === 'true') {
        userCTX.logout();
        clearInterval(interval);
      }
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  return (
    <>
      {(callInProgress || forceReload) && <Loader />}
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/"
              element={
                <DashboardProvider>
                  <Main />
                </DashboardProvider>
              }
            >
              <Route path="/prevision" element={<Prevision />} />
              <Route path="/dev" element={<Dev />} />

              <Route
                path="/hotel/:name"
                element={
                  <HotelProvider>
                    <Hotel />
                  </HotelProvider>
                }
              />
              <Route path="/installation/:PermissionId/:CanAccess/:type" element={<Installation />} />
              <Route path="/installation/:PermissionId/:CanAccess/:type/:level" element={<Installation />} />

              <Route
                path="/planning"
                element={
                  <PlanningProvider>
                    <BookingProvider>
                      <Planning />
                    </BookingProvider>
                  </PlanningProvider>
                }
              />
              <Route path="/maincourante" element={<MainCourante />} />
              <Route path="/pickup" element={<Pickup />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/repartition" element={<Repartition />} />

              <Route path="/" element={userCTX.authenticationInfos.user?.IsAdmin ? <Admin /> : <Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
        <ToastContainer
          autoClose={3000}
          draggable={false}
          position="top-right"
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
};

export default App;
