export const API_URL = 'https://matchilling-chuck-norris-jokes-v1.p.rapidapi.com/';
export const REQUEST_TIMEOUT = 30000;
import visa from '@assets/cc/1.png';
import ax from '@assets/cc/2.png';
import mc from '@assets/cc/3.png';
import dc from '@assets/cc/4.png';
import jsb from '@assets/cc/5.png';
import cb from '@assets/cc/6.png';
import dis from '@assets/cc/7.png';
import mae from '@assets/cc/8.png';
import up from '@assets/cc/9.png';
import mir from '@assets/cc/10.png';
import ali from '@assets/cc/11.png';
import gp from '@assets/cc/12.png';
import pl from '@assets/cc/13.png';
import pp from '@assets/cc/14.png';
import wc from '@assets/cc/15.png';

export const status = [
  {
    code: 'R',
    label: 'Réservé confirmé',
  },
  {
    code: 'O',
    label: 'Réservé en option',
  },
  {
    code: 'P',
    label: 'Présent',
  },
  {
    code: 'D',
    label: 'Départ',
  },
  {
    code: 'S',
    label: 'Annulé',
  },
];

export const creditCard = [
  { code: 1, name: 'VISA', icon: visa },

  { code: 2, name: 'AMERICAN EXPRESS', icon: ax },
  { code: 3, name: 'MASTER CARD', icon: mc },

  { code: 4, name: 'DINERS CLUB', icon: dc },
  { code: 5, name: 'JCB', icon: jsb },
  { code: 6, name: 'CARTE BLEUE', icon: cb },
  { code: 7, name: 'DISCOVER', icon: dis },
  { code: 8, name: 'MAESTRO', icon: mae },
  { code: 9, name: 'UNION PAY', icon: up },
  { code: 10, name: 'MIR', icon: mir },
  { code: 11, name: 'ALIPAY', icon: ali },
  { code: 12, name: 'GOOGLEPAY', icon: gp },
  { code: 13, name: 'PAYLIB', icon: pl },
  { code: 14, name: 'PAYPAL', icon: pp },
  { code: 15, name: 'WECHAT', icon: wc },
];

export const MemoType = [
  /// </summary>
  { name: ' FrontOffice ', code: 1 },
  /// <summary>
  /// Housekeeping (2)
  /// </summary>
  { name: ' HouseKeeping ', code: 2 },
  /// <summary>
  /// Catering and POS (4)
  /// </summary>
  { name: ' Catering ', code: 4 },
  /// <summary>
  /// Concierge (8)
  /// </summary>
  { name: ' Concierge ', code: 8 },
  /// <summary>
  /// Reservation (16)
  /// </summary>
  { name: '  Reservation ', code: 16 },
  /// <summary>
  /// Maintenance (32)
  /// </summary>
  { name: '  Maintenance ', code: 32 },
  /// <summary>
  /// Custom Memo 1 (64)
  /// </summary>
  { name: ' Free1 ', code: 64 },
  /// <summary>
  /// Custom Memo 2 (64)
  /// </summary>
  { name: '  Free2 ', code: 128 },
  /// <summary>
  /// Custom Memo 3 (64)
  /// </summary>
  { name: 'Free3 ', code: 256 },
  /// <summary>
  /// Pre check-in
  /// </summary>
  { name: ' PreCheckInCustomerMemo ', code: 512 },
];
