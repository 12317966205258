import React from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { Room } from '@app/types/Hotel/room';
import { ContextMenu } from 'primereact/contextmenu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Tooltip } from '@mui/material';

const CustomResource = ({ resource }: { resource: Room }) => {
  const [t] = useTranslation();
  const subFormCTX = useSubForm();
  const planningCTX = usePlanning();
  const filtersCTX = useFilters();
  const cm = subFormCTX.cmResourceRef;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const items = [
    { label: "Changer le statut de cette chambre en 'sale'", icon: 'fa-solid fa-broom' },
    { label: "Changer le statut des chambres en 'sale'", icon: 'fa-solid fa-broom' },
    { label: "Changer le statut de cette chambre en 'à vérifier'", icon: 'fa-solid fa-eye' },
    { label: "Changer le statut des chambre en 'à vérifier'", icon: 'fa-solid fa-eye' },
  ];

  const linkedRoomIndex = planningCTX.resource.find((e: Room) => e.Id === resource.IdLinkedRoom)?.PositionRoom;
  const currentRoomIndex = planningCTX.resource.find((e: Room) => e.Id === resource.Id)?.PositionRoom;

  return (
    <div key={resource.Id}>
      <ContextMenu style={{ width: '350px', fontSize: '10pt' }} model={items} ref={cm} />

      <div
        onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Tooltip
          title={
            <>
              {' '}
              <i className="fas fa-bed" style={{ margin: '10px' }}></i>
              {resource.RoomDescription}
              <hr style={{ margin: 0, padding: 0 }} />
              <i style={{ margin: '10px' }} className="fa-solid fa-stairs"></i>
              {resource.Floor}
              <hr style={{ margin: 0, padding: 0 }} />
              <i style={{ margin: '10px' }} className="fa-solid fa-broom"></i>
              {resource.IsDirty === 0
                ? t('labels.Chambrepropre')
                : resource.IsDirty === 1
                  ? t('labels.Chambresale')
                  : resource.IsDirty === 2
                    ? t('labels.Chambreverifier')
                    : resource.IsDirty === 8
                      ? t('labels.Litsverifier')
                      : ''}
              {resource.Size && (
                <>
                  <hr style={{ margin: 0, padding: 0 }} />
                  <i style={{ margin: '5px' }} className="fa-regular fa-square"></i> {resource.Size} m²
                </>
              )}
            </>
          }
        >
          <div
            className={'A' + resource.Id}
            style={{
              width: '40%',
              color:
                resource.IsDirty === 0
                  ? ''
                  : resource.IsDirty === 1
                    ? colors.colorErrorMain
                    : resource.IsDirty === 2
                      ? 'yellow'
                      : 'darkorange',
              fontWeight: 'normal',
              alignContent: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ float: 'left', margin: '4px' }}>
              {resource.IsDirty === 0 ? (
                <div style={{ width: '16px' }}></div>
              ) : resource.IsDirty === 1 ? (
                <i className="fa-solid fa-broom"></i>
              ) : resource.IsDirty === 2 ? (
                <i className="fa-solid fa-eye"></i>
              ) : resource.IsDirty === 8 ? (
                <i className="fa-solid fa-bed"></i>
              ) : (
                ''
              )}
            </div>
            <div
              style={{
                fontSize: '10pt',
                lineHeight: '29px',
                fontWeight: 'bold',
                backgroundColor: resource.RoomColor,
                color: darkMode ? 'white' : colors.colorGreyAdminLTE,
                width: '100%',
              }}
            >
              {resource.RoomNumber.replace('[DEG]', '°')}
            </div>

            {resource.IdLinkedRoom !== undefined && (
              <i
                style={{
                  transform: 'rotate(135deg)',
                  position: 'absolute',

                  top:
                    filtersCTX.filters.planningFilters.CurrentSort === 0
                      ? currentRoomIndex > linkedRoomIndex
                        ? '-5px'
                        : '20px'
                      : currentRoomIndex % 2 === 0
                        ? '20px'
                        : '-5px',
                  left: '0px',
                  color: 'darkorange',
                }}
                className="fas fa-link"
              ></i>
            )}
          </div>
        </Tooltip>
        <Tooltip
          title={
            <>
              {' '}
              <i className="fas fa-bed" style={{ margin: '5px' }}></i>
              {resource.RoomTypeDescription?.replace('[VIRTUAL]', t('labels.sr'))}
              <hr style={{ margin: 0, padding: 0 }} />
              <i className="fa-solid fa-people-line" style={{ margin: '5px' }}></i> {resource.PAX} {t('labels.paxmax')}
            </>
          }
        >
          <div style={{ width: '60%' }}>
            <div
              className={'B' + resource.Id}
              style={{
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5px',
                fontSize: '10pt',
                whiteSpace: 'nowrap',
                color: 'black',
                backgroundColor: resource.TypeColor,
              }}
            >
              {resource.RoomType.replace('[VIRTUAL]', t('labels.sr'))}
              <i
                style={{ color: resource.TypeColor, marginLeft: '5px', marginRight: '5px' }}
                className="fas fa-circle"
              ></i>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default CustomResource;
