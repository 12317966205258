/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useUser } from '@app/modules/user/UserContext';
import { shortDateToLocaleString } from '@app/utils/dates';
import moment from 'moment-timezone';

const KardexPicture: React.FC = () => {
  const kardexCTX = useKardex();
  const userCTX = useUser();
  useEffect(() => {
    if (kardexCTX.CurrentKardex?.HasPictureID) {
      kardexCTX.getKardexPicture(kardexCTX.CurrentKardex?.Id);
    }
  }, [kardexCTX.CurrentKardex?.HasPictureID]);

  return (
    <div
      style={{
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'space-between',
        display: 'flex',
        width: '100%',
      }}
    >
      <img src={'data:image/png;base64,' + kardexCTX.KardexPicture?.ImageBase64} alt="Image Base64" />
      <div>
        {shortDateToLocaleString(
          moment(kardexCTX.KardexPicture?.DateCreation).toDate(),
          userCTX.authenticationInfos?.user?.Culture ?? 'fr',
        )}
      </div>
    </div>
  );
};

export default KardexPicture;
