import loglevel from 'loglevel';

import { consoleFactory } from './console.factory';
import { levelNameToLogLevelNumber } from './logger.utils';
import { sentryFactory } from './sentry.factory';
import { LoggerOptions } from './types';

const allLoggerFactories = [consoleFactory, sentryFactory];

export function createLogging(options: LoggerOptions): loglevel.MethodFactory {
  return (methodName: loglevel.LogLevelNames, level: loglevel.LogLevelNumbers, loggerName: string | symbol) => {
    const loggers = allLoggerFactories.map((loggerFactory) => {
      const methodLevel = levelNameToLogLevelNumber(methodName) ?? 'silent';
      return loggerFactory({ options, methodName, methodLevel, level, loggerName });
    });
    return (...messages) => {
      loggers.forEach((logger) => {
        logger(...messages);
      });
    };
  };
}
