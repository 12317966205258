import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import useConstant from 'use-constant';

import { config } from '@utils/config';

import { createApiLogger } from './apiLogger';
import type { Logger, LoggerContext as InternalLoggerContext, Loggers } from './types';
import { createWebLogger } from './webLogger';

if (config) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: config.VERSION,
    environment: config.NODE_ENV,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
  });
}
type Context = {
  loggers: Loggers;
  configuration: LoggerConfiguration;
};

type LoggerConfiguration = {
  onLogin: (userId: string) => void;
  onLogout: () => void;
};

const LoggerContext = createContext<Context | undefined>(undefined);

let allLoggers = buildLoggers({});

type LoggerProviderProps = {
  children: ReactNode;
};

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children }) => {
  // Ensure loggers are correctly set when configuration change
  const [loggers, setLoggers] = useState(buildLoggers({}));

  useEffect(() => {
    allLoggers = loggers;
  }, [loggers]);

  const configuration = useConstant(() => ({
    onLogin: (userId: string) => setLoggers(buildLoggers({ userId })),
    onLogout: () => setLoggers(buildLoggers({})),
  }));

  return <LoggerContext.Provider value={{ loggers: allLoggers, configuration }}>{children}</LoggerContext.Provider>;
};

function buildLoggers(options: InternalLoggerContext): Loggers {
  return {
    web: createWebLogger(options),
    api: createApiLogger(options),
  };
}

function useLoggerContext(): Context {
  const context = useContext(LoggerContext);

  if (context === undefined) {
    throw new Error('useLoggerContext must be used within a LoggerProvider');
  }

  return context;
}

export function useLoggerConfiguration(): LoggerConfiguration {
  return useLoggerContext().configuration;
}

export function useLogger(name: keyof Loggers = 'web'): Logger {
  const { loggers } = useLoggerContext();

  return loggers[name];
}

export function getLogger(name: keyof Loggers = 'web'): Logger {
  return allLoggers[name];
}
