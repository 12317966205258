/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useUser } from '@app/modules/user/UserContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const KardexHistory: React.FC = () => {
  const kardexCTX = useKardex();

  const userCTX = useUser();
  const authData = userCTX.authenticationInfos; // userCTX.authenticationInfos;
  useEffect(() => {
    if (authData.selectedHotel?.IdHotel && kardexCTX.CurrentKardex?.Id) {
      kardexCTX.getKardexHistory(authData.selectedHotel?.IdHotel, kardexCTX.CurrentKardex?.Id);
    }
  }, [authData.selectedHotel?.IdHotel]);

  return (
    <div
      style={{
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'space-between',

        width: '100%',
      }}
    >
      <DataTable value={kardexCTX.KardexHistory ?? []}>
        <Column field="Date"></Column>
        <Column field="UserInitiales"></Column>
        <Column field="Motif"></Column>
        <Column field="FieldValue"></Column>
        <Column body={(e) => <div dangerouslySetInnerHTML={{ __html: e.Description }}></div>}></Column>
      </DataTable>
    </div>
  );
};

export default KardexHistory;
