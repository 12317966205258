import React from 'react';

import colors from '@app/styles/colors';
import { GridDetailledData } from '@app/types/types';
import moment from 'moment-timezone';

import { Tooltip } from '@mui/material';

interface PlanningFooterProps {
  date: Date;
  planningDetail: GridDetailledData | null;
  hideSR: boolean;
  hideNA: boolean;
}

const PlanningFooter: React.FC<PlanningFooterProps> = ({ date, planningDetail, hideSR, hideNA }) => {
  const DayValue = planningDetail?.Data.find((t) => t?.DataType === 16)?.Data.find((x) => {
    const keyIterator = planningDetail.Columns.find(
      (e) => moment(e.Value).format('YYYYMMDD') === moment(date).format('YYYYMMDD'),
    )?.KeyIterator;

    return x.KeyIterator === keyIterator;
  })?.Value;

  const Description = planningDetail?.Data.find((t) => t?.DataType === 16)?.Data.find((x) => {
    const keyIterator = planningDetail.Columns.find(
      (e) => moment(e.Value).format('YYYYMMDD') === moment(date).format('YYYYMMDD'),
    )?.KeyIterator;

    return x.KeyIterator === keyIterator;
  })?.Description;
  const SRValue = planningDetail?.Data.find((t) => t?.DataType === 17)?.Data.find((x) => {
    const keyIterator = planningDetail.Columns.find(
      (e) => moment(e.Value).format('YYYYMMDD') === moment(date).format('YYYYMMDD'),
    )?.KeyIterator;

    return x.KeyIterator === keyIterator;
  })?.Value;
  const Disponible = planningDetail?.Data.find((t) => t?.DataType === 2)?.Data.find((x) => {
    const keyIterator = planningDetail.Columns.find(
      (e) => moment(e.Value).format('YYYYMMDD') === moment(date).format('YYYYMMDD'),
    )?.KeyIterator;

    return x.KeyIterator === keyIterator;
  })?.Value;
  const SRDescription = planningDetail?.Data.find((t) => t?.DataType === 17)?.Data.find((x) => {
    const keyIterator = planningDetail.Columns.find(
      (e) => moment(e.Value).format('YYYYMMDD') === moment(date).format('YYYYMMDD'),
    )?.KeyIterator;

    return x.KeyIterator === keyIterator;
  })?.Description;
  return (
    <>
      {!hideSR && (
        <>
          <Tooltip
            title={
              SRDescription && SRDescription?.split('<br/>').length > 0
                ? SRDescription?.split('<br/>').map((e) => {
                    return (
                      <div key={'.ATYPESOVERBOOKED' + moment(date).format('YYYYMMDD').toString() + 'NA' + e}>
                        {e}
                        <br />
                      </div>
                    );
                  })
                : SRDescription
            }
          >
            <div
              style={{
                width: `100% `,
                minWidth: '74px',
                height: '25px',
                textAlign: 'center',
                borderRight: '1px solid #ccc',
                backgroundColor: colors.colorErrorMain,
              }}
              className={'ATYPESOVERBOOKED' + moment(date).format('YYYYMMDD').toString() + 'NA'}
            >
              {SRValue === 0 ? '' : SRValue}
            </div>
          </Tooltip>
        </>
      )}
      {!hideNA && (
        <>
          <Tooltip
            title={
              Description && Description?.split('<br/>').length > 0
                ? Description?.split('<br/>').map((e) => {
                    return (
                      <div key={'.ANOTALLOCATED' + moment(date).format('YYYYMMDD').toString() + 'NA' + e}>
                        {e}
                        <br />
                      </div>
                    );
                  })
                : Description
            }
          >
            <div
              style={{
                width: `100% `,
                minWidth: '74px',
                height: '25px',
                textAlign: 'center',
                borderRight: '1px solid #ccc',
                backgroundColor: '#808080',
                color: 'white',
              }}
              className={'ANOTALLOCATED' + moment(date).format('YYYYMMDD').toString() + 'NA'}
            >
              {' '}
              {DayValue === 0 ? '' : DayValue}
            </div>
          </Tooltip>
        </>
      )}

      <div
        style={{
          width: `100% `,
          minWidth: '74px',
          height: '25px',
          textAlign: 'center',
          borderRight: '1px solid #ccc',
          backgroundColor: '#000',
          color: 'white',
        }}
        className={'ATYPESOVERBOOKED' + moment(date).format('YYYYMMDD').toString() + 'NA'}
      >
        {Disponible === 0 ? '' : Disponible}
      </div>
    </>
  );
};

export default PlanningFooter;
