import React from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRoute = () => {
  const userCTX = useUser();
  const isLoggedIn = userCTX.authenticationInfos.user; // useSelector((state: RootState) => state.auth.user);

  return isLoggedIn ? <Navigate to={'/'} /> : <Outlet />;
};

export default PublicRoute;
