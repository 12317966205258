/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { chartOptions } from '@app/components/Common/charts/chartOption';
import DoughnutChart from '@app/components/Common/charts/DoughnutChart';
import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const Admin = () => {
  const [t] = useTranslation();
  const userCTX = useUser();
  const dashboardCTX = useDashboard();
  const [isFirstCall, setIsFirstCall] = useState(true);

  const filtersCTX = useFilters();
  const kpiParams = filtersCTX.filters.dashboardFilters;

  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const adminStat = dashboardCTX.AdminStat;
  const [hotelsQty, setHotelsQty] = useState<DougnutChartData | null>(null);
  const [roomsQty, setRoomsQty] = useState<DougnutChartData | null>(null);
  const { orientation } = useMobileOrientation();
  const [usersQty, setUsersQty] = useState<DougnutChartData | null>(null);

  useEffect(() => {
    if (isFirstCall === true && dashboardCTX && kpiParams && isTech != undefined && isTech !== null) {
      dashboardCTX.getAdminStat({ dashboardFilters: kpiParams, params: { isTech } });

      setIsFirstCall(false);
    }
  }, [isFirstCall, dashboardCTX, kpiParams, isTech, userCTX.authenticationInfos.selectedHotel]);

  useEffect(() => {
    if (adminStat !== null) {
      const hotelsRep: DougnutChartData = {
        labels: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.Label),
        datasets: [
          {
            data: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.HotelsQty),
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4', '#8bc34a', '#ffc107'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      };
      const roomsRep: DougnutChartData = {
        labels: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.Label),
        datasets: [
          {
            data: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.RoomsQty),
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4', '#8bc34a', '#ffc107'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      };
      const usersRep: DougnutChartData = {
        labels: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.Label),
        datasets: [
          {
            data: adminStat.filter((e) => e.Label !== 'Tous').map((e) => e.UsersQty),
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4', '#8bc34a', '#ffc107'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      };

      setHotelsQty(hotelsRep);
      setRoomsQty(roomsRep);
      setUsersQty(usersRep);
    }
  }, [adminStat]);

  return (
    <div>
      <ContentHeader title={t('main.label.homePage')} />
      <section className="content">
        <div className="container-fluid">
          {isMobileOnly && (
            <div
              className={`small-box bg-gradient-gray-dark`}
              style={{ width: orientation === 'portrait' ? '100%' : '50%' }}
            ></div>
          )}
          {isTech && adminStat !== null && (
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className={`small-box bg-gradient-light`}>
                  {hotelsQty !== null && (
                    <DoughnutChart
                      title={t('chart.doughnut.title.hotelsQTY')}
                      data={hotelsQty as DougnutChartData}
                      options={chartOptions}
                      showTitle={true}
                      total={adminStat.find((e) => e.Label === 'Tous')?.HotelsQty}
                    ></DoughnutChart>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className={`small-box bg-gradient-light`}>
                  {roomsQty !== null && (
                    <DoughnutChart
                      title={t('chart.doughnut.title.roomsQTY')}
                      data={roomsQty as DougnutChartData}
                      options={chartOptions}
                      showTitle={true}
                      total={adminStat.find((e) => e.Label === 'Tous')?.RoomsQty}
                    ></DoughnutChart>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className={`small-box bg-gradient-light`}>
                  {usersQty !== null && (
                    <DoughnutChart
                      title={t('chart.doughnut.title.usersQTY')}
                      data={usersQty as DougnutChartData}
                      options={chartOptions}
                      showTitle={true}
                      total={adminStat.find((e) => e.Label === 'Tous')?.UsersQty}
                    ></DoughnutChart>
                  )}
                </div>
              </div>
            </div>
          )}{' '}
        </div>
      </section>
    </div>
  );
};

export default Admin;
