/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, ReactNode, useContext, useState } from 'react';

import {
  KardexFilters,
  KardexHistory,
  KardexOperations,
  KardexPicture,
  KardexType,
  OperationsFilters,
  SingleKardexType,
  Stay,
  StayFilters,
  TotalStays,
} from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
interface KardexContextState {
  KardexList: KardexType[] | null;
  KardexFilters: KardexFilters | null;
  KardexOperations: KardexOperations[] | null;
  StayFilters: StayFilters | null;
  KardexPicture: KardexPicture | null;
  OperationsFilters: OperationsFilters | null;
  StaysList: Stay[] | null;
  TotalStays: TotalStays | null;
  CurrentKardex: SingleKardexType | null;
  KardexHistory: KardexHistory[] | null;
  setKardexFilters: (KardexFilters: KardexFilters | null) => void;
  setStayFilters: React.Dispatch<React.SetStateAction<StayFilters | null>>;
  setOperationsFilters: React.Dispatch<React.SetStateAction<OperationsFilters | null>>;

  getAllKardex: (KardexFilters: KardexFilters, IdHotel: number) => Promise<void | undefined>;
  setAllKardex: (KardexList: KardexType[] | null) => void;
  setCurrentKardex: (Kardex: SingleKardexType | null) => void;

  setKardexOperations: (KardexOperations: KardexOperations[] | null) => void;
  getStays: (StayFilters: StayFilters) => Promise<void | undefined>;
  getTotalStays: (IdKardex: string) => Promise<void | undefined>;
  getKardexHistory: (IdHotel: number, IdKardex: string) => Promise<KardexHistory[] | undefined>;
  setStays: (StaysList: Stay[] | null) => void;
  setTotalStays: (TotalStays: TotalStays | null) => void;
  setKardexHistory: (KardexHistory: KardexHistory[] | null) => void;
  getKardekById: (IdHotel: number, IdKardex: string) => Promise<SingleKardexType | undefined>;
  getLastTenOperations: (IdHotel: number, IdKardex: string) => Promise<KardexOperations[] | undefined>;
  getOperations: (OperationsFilters: OperationsFilters) => Promise<KardexOperations[] | undefined>;
  getKardexPicture: (IdKardex: string) => Promise<KardexPicture | undefined>;
  setKardexPicture: (KardexPicture: KardexPicture | null) => void;
}

// Create a context to hold your params with default values
export const KardexContext = createContext<KardexContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type KardexProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const KardexProvider: React.FC<KardexProviderProps> = (props) => {
  const [KardexList, setAllKardex] = useState<KardexType[] | null>(null);
  const [KardexOperations, setKardexOperations] = useState<KardexOperations[] | null>(null);
  const [KardexPicture, setKardexPicture] = useState<KardexPicture | null>(null);

  const [KardexFilters, setKardexFilters] = useState<KardexFilters | null>({ page: 1, pagesize: 15 });
  const [StayFilters, setStayFilters] = useState<StayFilters | null>({
    DateStart: moment(new Date()).subtract(6, 'month').toDate(),
    DateEnd: moment(new Date()).add(6, 'month').toDate(),
    IdHotel: 0,
    IncludeCancellations: true,
  });
  const [TotalStays, setTotalStays] = useState<TotalStays | null>(null);

  const [OperationsFilters, setOperationsFilters] = useState<StayFilters | null>({
    DateStart: moment(new Date()).subtract(1, 'day').toDate(),
    DateEnd: moment(new Date()).toDate(),
    IdHotel: 0,
  });
  const [CurrentKardex, setCurrentKardex] = useState<SingleKardexType | null>(null);
  const [StaysList, setStays] = useState<Stay[] | null>([]);
  const [KardexHistory, setKardexHistory] = useState<KardexHistory[] | null>(null);

  const getAllKardex = async (KardexFilters: KardexFilters, IdHotel: number) => {
    try {
      const { CategorySelected, ...filters } = KardexFilters;
      CategorySelected;
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex/Kardex/search`,
        { ...filters, idhotel: IdHotel },
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setAllKardex(data.Data as KardexType[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getLastTenOperations = async (IdHotel: number, IdKardex: string) => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex/Account/Operations/Last`,
        { IdHotel, IdKardex },
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setKardexOperations(data.Data as KardexOperations[]);
      return data.Data as KardexOperations[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getOperations = async (OperationsFilters: OperationsFilters) => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex/Account/Operations`,
        { ...OperationsFilters },
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setKardexOperations(data.Data as KardexOperations[]);
      return data.Data as KardexOperations[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getKardekById = async (IdHotel: number, IdKardex: string) => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex`,
        { IdHotel, Id: IdKardex },
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setCurrentKardex(data.Data as SingleKardexType);
      return data.Data as SingleKardexType;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getStays = async (StayFilters: StayFilters) => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex/Stays`,
        { ...StayFilters },
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setStays(data.Data as Stay[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getTotalStays = async (IdKardex: string) => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `Kardex/Stays/Total`,
        [IdKardex],
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTotalStays(data.Data as TotalStays);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getKardexHistory = async (IdHotel: number, IdKardex: string) => {
    try {
      const response = await axios.get(
        // eslint-disable-next-line max-len
        `Kardex/History/` + IdKardex,
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setKardexHistory(data.Data as KardexHistory[]);
      return data.Data as KardexHistory[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getKardexPicture = async (IdKardex: string) => {
    try {
      const response = await axios.get(
        // eslint-disable-next-line max-len
        `Kardex/Picture/` + IdKardex,
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setKardexPicture(data.Data as KardexPicture);
      return data.Data as KardexPicture;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <KardexContext.Provider
      value={{
        getAllKardex,
        KardexList,
        setAllKardex,
        KardexFilters,
        setKardexFilters,
        getStays,
        setStays,
        StaysList,
        setStayFilters,
        StayFilters,
        CurrentKardex,
        getKardekById,
        KardexOperations,
        setKardexOperations,
        setCurrentKardex,
        getLastTenOperations,
        OperationsFilters,
        setOperationsFilters,
        getOperations,
        getTotalStays,
        setTotalStays,
        TotalStays,
        getKardexHistory,
        KardexHistory,
        setKardexHistory,
        getKardexPicture,
        KardexPicture,
        setKardexPicture,
      }}
    >
      {props.children}
    </KardexContext.Provider>
  );
};

// Create a custom hook to access the params
export function useKardex(): KardexContextState {
  const kardexContext = useContext(KardexContext);
  if (kardexContext === undefined) {
    throw new Error('useKardex must be used within a KardexProvider');
  }
  return kardexContext;
}
