import PickupLineChart from '@app/components/Common/charts/PickupLineChart';
import { AnalytiqueProvider } from '@app/modules/Analytics/AnalyticsContext';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const Pickup: React.FC = () => {
  const { orientation } = useMobileOrientation();
  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        {(isMobile || isTablet) && (
          <div
            className={`small-box bg-gradient-gray-dark`}
            style={{ width: orientation === 'portrait' ? '100%' : '50%' }}
          >
            <div className="inner"></div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12 col-12">
            <AnalytiqueProvider>
              <PickupLineChart></PickupLineChart>
            </AnalytiqueProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pickup;
