/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import { useLogger } from '@app/modules/logger/LoggerContext';

interface props {
  data: any;

  title: ReactNode;
  showTitle: boolean;
}
const TypeDisponibility: React.FC<props> = ({ data, title, showTitle }) => {
  const logger = useLogger();

  logger.log(data, showTitle);
  return <div>{title}</div>;
};

export default TypeDisponibility;
