import * as Sentry from '@sentry/react';
import type { SeverityLevel as SentrySeverityLevel } from '@sentry/types';
import loglevel from 'loglevel';

import { isNonEmptyArray, tail } from '@utils/arrays';

import { isLevelDisabled } from './logger.utils';
import type { LoggerFactoryOptions, LoggerOptions } from './types';

export function sentryFactory({
  options,
  methodName,
  methodLevel,
  loggerName,
}: LoggerFactoryOptions): loglevel.LoggingMethod {
  if (isLevelDisabled(methodLevel, options.sentry.level)) {
    return () => {
      //do nothing
    };
  }

  const severityLevel = getSeveritylevel(methodName);
  if (severityLevel === 'error') {
    return buildErrorLogger(options, severityLevel, loggerName);
  } else if (severityLevel === 'warning') {
    return buildWarningLogger(options, severityLevel, loggerName);
  } else {
    return buildBreadcrumbLogger(options, severityLevel, loggerName);
  }
}

function getSeveritylevel(methodName: loglevel.LogLevelNames): SentrySeverityLevel {
  switch (methodName) {
    case 'trace':
    case 'debug':
      return 'debug';
    case 'info':
      return 'info';
    case 'warn':
      return 'warning';
    case 'error':
      return 'error';
  }
}

function buildErrorLogger(
  options: LoggerOptions,
  level: SentrySeverityLevel,
  loggerName: string | symbol,
): loglevel.LoggingMethod {
  const sendException = (exception: Error, additionalData: Record<string, unknown>) => {
    Sentry.captureException(exception, {
      user: options.context.userId !== undefined ? { id: options.context.userId } : undefined,
      level,
      extra: { category: loggerName, ...additionalData },
    });
  };

  return (...messages: unknown[]) => {
    if (!isNonEmptyArray(messages)) {
      return;
    }

    const exception = messages.find((message) => message instanceof Error) as Error | undefined;
    if (exception !== undefined) {
      sendException(exception, buildData(messages.filter((message) => message !== exception)));
    } else {
      sendException(new Error(prettyMessage(messages[0])), buildData(tail(messages)));
    }
  };
}

function buildWarningLogger(
  options: LoggerOptions,
  level: SentrySeverityLevel,
  loggerName: string | symbol,
): loglevel.LoggingMethod {
  return (...messages: unknown[]) => {
    if (!isNonEmptyArray(messages)) {
      return;
    }

    Sentry.captureMessage(prettyMessage(messages[0]), {
      user: options.context.userId !== undefined ? { id: options.context.userId } : undefined,
      level,
      extra: { category: loggerName, ...buildData(tail(messages)) },
    });
  };
}

function buildBreadcrumbLogger(
  options: LoggerOptions,
  level: SentrySeverityLevel,
  loggerName: string | symbol,
): loglevel.LoggingMethod {
  return (...messages: unknown[]) => {
    if (!isNonEmptyArray(messages)) {
      return;
    }

    Sentry.addBreadcrumb({
      level,
      message: prettyMessage(messages[0]),
      data: buildData(tail(messages)),
      type: options.sentry.breadcrumbType ?? 'default',
      category: loggerName.toString(),
    });
  };
}

function buildData(messages: Array<unknown>): Record<string, unknown> {
  let result: Record<string, unknown> = {};
  const additionalInformation: Array<string> = [];
  messages.forEach((message) => {
    if (typeof message === 'object' && !Array.isArray(message)) {
      result = {
        ...result,
        ...message,
      };
    } else {
      additionalInformation.push(prettyMessage(message));
    }
  });
  result = {
    ...result,
    additionalInformation: additionalInformation.length > 1 ? additionalInformation : undefined,
  };
  return result;
}

function prettyMessage(message: unknown): string {
  if (message instanceof Error) {
    return `[Error] ${message.message}`;
  } else {
    switch (typeof message) {
      case 'boolean':
      case 'bigint':
      case 'number':
      case 'string':
      case 'symbol':
        return message.toString();
      case 'undefined':
        return '';
      case 'function':
        return '<function>';
      case 'object':
        return JSON.stringify(message);
    }
  }
}
