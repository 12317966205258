/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const MainCourantteFilter: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const userCTX = useUser();
  const filtersCTX = useFilters();
  const [t] = useTranslation();
  const mainCouranteFilters = filtersCTX.filters.mainCouranteFilters;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const familyOptions = [t('labels.Familledevente'), t('labels.Analytique')];
  const priceOptions = [t('labels.HT'), t('labels.TTC')];
  const [firstCallDone, setFistCallDone] = useState(true);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const dateTemplate = (event: CalendarDateTemplateEvent) => {
    const currentDate: Date = new Date(event.year, event.month, event.day);
    if (currentDate >= moment(new Date()).subtract(1, 'day').toDate()) {
      return <strong style={{ textDecoration: 'line-through', opacity: '0.2', color: 'red' }}>{event.day}</strong>;
    }
    return event.day; // default behavior
  };
  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  useEffect(() => {
    if (firstCallDone === false && filtersCTX) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        mainCouranteFilters: {
          ...prevState.mainCouranteFilters,
          needReload: true,
        },
      }));
    }
    setFistCallDone(true);
  }, [firstCallDone]);
  useEffect(() => {
    if (hotelList?.length === 1 && mainCouranteFilters !== null) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        mainCouranteFilters: {
          ...prevState.mainCouranteFilters,
          ListHotels: hotelList,
          needReload: true,
        },
      }));
    } else {
      if (selectedHotel) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          mainCouranteFilters: {
            ...prevState.mainCouranteFilters,
            ListHotels: [selectedHotel as HotelRight],
            needReload: true,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList]);
  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '3px',
              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && mainCouranteFilters && isMobile && (
        <Accordion activeIndex={0}>
          <AccordionTab header={t('labels.filters')} tabIndex={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: colors.colorGreyAdminLTE,
                padding: '10px',
              }}
            >
              <div style={{ width: '50%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.from')}</label>
                  <Calendar
                    inputStyle={{ width: '70%', height: '40px' }}
                    style={{ width: '70%' }}
                    value={new Date(mainCouranteFilters.DateStart)}
                    touchUI={isMobile}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        mainCouranteFilters: {
                          ...prevState.mainCouranteFilters,
                          DateStart: moment(e.value?.getTime()).toDate(),
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    locale={authData?.language || 'fr'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.to')} </label>
                  <Calendar
                    inputStyle={{ width: '70%', height: '40px' }}
                    style={{ width: '70%' }}
                    value={new Date(mainCouranteFilters.DateEnd)}
                    maxDate={moment(new Date()).subtract(1, 'day').toDate()}
                    minDate={moment(new Date(mainCouranteFilters.DateStart)).add(1, 'day').toDate()}
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        mainCouranteFilters: {
                          ...prevState.mainCouranteFilters,
                          DateEnd: moment(e.value?.getTime()).toDate(),
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    locale={authData?.language || 'fr'}
                    dateTemplate={dateTemplate}
                  />{' '}
                </div>
              </div>
              <div style={{ width: '50%', flexDirection: 'column', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  {!isTech && (
                    <MultiSelect
                      panelHeaderTemplate={customHeader}
                      value={mainCouranteFilters.ListHotels.sort((a, b) => {
                        if (a.Name < b.Name) {
                          return -1;
                        }
                        if (a.Name > b.Name) {
                          return 1;
                        }
                        return 0;
                      })}
                      disabled={hotelList?.length === 1}
                      onChange={(e) => {
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          mainCouranteFilters: {
                            ...prevState.mainCouranteFilters,
                            ListHotels: e.value,
                            hasBeenModified: true,
                          },
                        }));
                      }}
                      options={
                        hotelList?.sort((a, b) => {
                          if (a.Name < b.Name) {
                            return -1;
                          }
                          if (a.Name > b.Name) {
                            return 1;
                          }
                          return 0;
                        }) ?? []
                      }
                      optionLabel="Name"
                      placeholder={t('label.placeholder.choice') ?? ''}
                      style={{ width: '180px', justifyContent: 'center' }}
                    />
                  )}
                  {isTech && (
                    <Dropdown
                      value={selectedHotel}
                      onChange={async (e) => {
                        userCTX.setUser((prevState) => ({
                          ...prevState,
                          selectedHotel: e.value ?? '',
                        }));
                        storage.setParam('selectedHotel', JSON.stringify(e.value));
                      }}
                      options={hotelList as HotelData[]}
                      optionLabel="Name"
                      filter
                      placeholder={t('label.placeholder.choice') ?? ''}
                      className="w-full md:w-14rem"
                      style={{ whiteSpace: 'nowrap', maxWidth: '180px', height: '40px' }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <SelectButton
                    value={mainCouranteFilters.IncludingTaxes ? t('labels.TTC') : t('labels.HT')}
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        mainCouranteFilters: {
                          ...prevState.mainCouranteFilters,
                          IncludingTaxes: e.value === t('labels.TTC') ? true : false,
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    options={priceOptions}
                    style={{ height: '40px' }}
                    height={'40px'}
                  />
                </div>
              </div>
              <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '80%',
                  }}
                >
                  <SelectButton
                    value={mainCouranteFilters.CategoryType ? t('labels.Analytique') : t('labels.Familledevente')}
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        mainCouranteFilters: {
                          ...prevState.mainCouranteFilters,
                          CategoryType: e.value === t('labels.Familledevente') ? 0 : 1,
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100vw' }}
                    options={familyOptions}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: '50px',
                    justifyContent: 'end',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'end',
                    flexDirection: 'row',
                    width: '20%',
                  }}
                >
                  <Button
                    style={{
                      borderRadius: '5px',
                      textAlign: 'center',
                      justifyContent: 'center',
                      backgroundColor: filtersCTX.filters.mainCouranteFilters.hasBeenModified
                        ? colors.colorSuccessMain
                        : '',
                    }}
                    onClick={() => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        mainCouranteFilters: {
                          ...prevState.mainCouranteFilters,
                          hasBeenModified: false,
                          needReload: true,
                        },
                      }));

                      /*  dispatch(
                  getMainCouranteData({ mainCouranteFilters: mainCouranteFilters, params: { isTech, selectedHotel } }),
                );*/
                    }}
                    icon="pi pi-refresh"
                  ></Button>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',

                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',

                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',

                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}></div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {isLocaleSet && mainCouranteFilters && !isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',

            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <label style={{ margin: 0 }}> {t('header.label.from')}</label>
            <Calendar
              inputStyle={{ width: '100px' }}
              value={new Date(mainCouranteFilters.DateStart)}
              touchUI={isMobile}
              style={{ marginLeft: '10px', marginRight: '10px' }}
              dateFormat="dd/mm/yy"
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  mainCouranteFilters: {
                    ...prevState.mainCouranteFilters,
                    DateStart: moment(e.value?.getTime()).toDate(),
                    hasBeenModified: true,
                    DateEnd:
                      moment(e.value?.getTime()) > moment(mainCouranteFilters.DateEnd)
                        ? moment(e.value?.getTime()).toDate()
                        : mainCouranteFilters.DateEnd,
                  },
                }));
              }}
              locale={authData?.language || 'fr'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <label style={{ margin: 0 }}> {t('header.label.to')} </label>
            <Calendar
              inputStyle={{ width: '100px' }}
              value={new Date(mainCouranteFilters.DateEnd)}
              dateFormat="dd/mm/yy"
              maxDate={moment(new Date()).subtract(1, 'day').toDate()}
              minDate={moment(new Date(mainCouranteFilters.DateStart)).add(1, 'day').toDate()}
              style={{ marginLeft: '10px', marginRight: '10px' }}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  mainCouranteFilters: {
                    ...prevState.mainCouranteFilters,
                    DateEnd: moment(e.value?.getTime()).toDate(),
                    hasBeenModified: true,
                  },
                }));
              }}
              locale={authData?.language || 'fr'}
              dateTemplate={dateTemplate}
            />{' '}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                disabled={hotelList?.length === 1}
                value={mainCouranteFilters.ListHotels.sort((a, b) => {
                  if (a.Name < b.Name) {
                    return -1;
                  }
                  if (a.Name > b.Name) {
                    return 1;
                  }
                  return 0;
                })}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    mainCouranteFilters: {
                      ...prevState.mainCouranteFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ marginRight: '10px', marginLeft: '10px', width: '150px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '200px', marginRight: '10px', marginLeft: '10px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}>
              <SelectButton
                value={mainCouranteFilters.CategoryType ? t('labels.Analytique') : t('labels.Familledevente')}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    mainCouranteFilters: {
                      ...prevState.mainCouranteFilters,
                      CategoryType: e.value === t('labels.Familledevente') ? 0 : 1,
                      hasBeenModified: true,
                    },
                  }));
                }}
                options={familyOptions}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}>
              <SelectButton
                value={mainCouranteFilters.IncludingTaxes ? t('labels.TTC') : t('labels.HT')}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    mainCouranteFilters: {
                      ...prevState.mainCouranteFilters,
                      IncludingTaxes: e.value === t('labels.TTC') ? true : false,
                      hasBeenModified: true,
                    },
                  }));
                }}
                options={priceOptions}
              />
            </div>
            <Button
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '5px',
                textAlign: 'center',
                justifyContent: 'center',
                backgroundColor: filtersCTX.filters.mainCouranteFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',
              }}
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  mainCouranteFilters: {
                    ...prevState.mainCouranteFilters,
                    hasBeenModified: false,
                    needReload: true,
                  },
                }));

                /*  dispatch(
                  getMainCouranteData({ mainCouranteFilters: mainCouranteFilters, params: { isTech, selectedHotel } }),
                );*/
              }}
              icon="pi pi-refresh"
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainCourantteFilter;
