let callInProgress = 0;

const incrementCallInProgress = () => {
  callInProgress++;
};

const decrementCallInProgress = () => {
  callInProgress--;
};

const getCallInProgress = () => {
  return callInProgress;
};

export { decrementCallInProgress, getCallInProgress, incrementCallInProgress };
