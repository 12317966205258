import loglevel from 'loglevel';

export function levelNameToLogLevelNumber(levelName: loglevel.LogLevelNames): loglevel.LogLevelNumbers {
  switch (levelName) {
    case 'trace':
      return loglevel.levels.TRACE;
    case 'debug':
      return loglevel.levels.DEBUG;
    case 'info':
      return loglevel.levels.INFO;
    case 'warn':
      return loglevel.levels.WARN;
    case 'error':
      return loglevel.levels.ERROR;
  }
}

export function isLevelEnabled(
  methodLevel: loglevel.LogLevelNumbers,
  minLevel: loglevel.LogLevelNames | 'silent',
): boolean {
  if (minLevel === 'silent') {
    return false;
  } else {
    return levelNameToLogLevelNumber(minLevel) <= methodLevel;
  }
}

export function isLevelDisabled(
  methodLevel: loglevel.LogLevelNumbers,
  minLevel: loglevel.LogLevelNames | 'silent',
): boolean {
  return !isLevelEnabled(methodLevel, minLevel);
}
