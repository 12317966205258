/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useUser } from '@app/modules/user/UserContext';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { Paginator } from 'primereact/paginator';
import { RadioButton } from 'primereact/radiobutton';

import KardexUserItem from './KardexUserItem';

interface templateObject {
  name: string;
  code: number;
  header: string;
}
const KardexGridMobile: React.FC = () => {
  const kardexCTX = useKardex();
  const kardexList = kardexCTX.KardexList;
  const [filterText, setFilterText] = useState('');
  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText);
  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;

  const displayMode = [
    { name: 'Indeterminé', code: 0 },
    { name: 'WS (Grossiste)', code: 10 },
    { name: 'AGY (Agence)', code: 20 },
    { name: 'CPY (Société)', code: 30 },
    { name: 'IND (Individuel)', code: 40 },
    { name: 'GRP (Groupe)', code: 50 },
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 500); // Délai de 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    if (kardexCTX && kardexCTX.KardexFilters && selectedHotel) {
      kardexCTX.setKardexFilters({ page: 1, pagesize: 8 });
      kardexCTX.getAllKardex({ page: 1, pagesize: 8 }, selectedHotel?.IdHotel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kardexCTX.KardexFilters?.page && kardexCTX.KardexFilters?.pagesize) {
      switch (kardexCTX.KardexFilters.SearchMode) {
        case 'ByName':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            LastName: debouncedFilterText,
            search: debouncedFilterText,
            Iata: undefined,
            PhoneNumber: undefined,
            Email: undefined,
          });
          break;
        case 'ByEmail':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            Email: debouncedFilterText,
            LastName: undefined,
            search: debouncedFilterText,
            PhoneNumber: undefined,
            Iata: undefined,
          });
          break;
        case 'ByTelephone':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            PhoneNumber: debouncedFilterText,
            LastName: undefined,
            Iata: undefined,
            search: debouncedFilterText,
            Email: undefined,
          });
          break;
        case 'ByIataCode':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            Iata: debouncedFilterText,
            LastName: undefined,
            PhoneNumber: undefined,
            search: debouncedFilterText,
            Email: undefined,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterText]);

  const handleSearchText = (e: any) => {
    setFilterText(e.target.value);
  };

  const onPageChange = (event: any) => {
    kardexCTX.setKardexFilters({
      ...kardexCTX.KardexFilters,
      page: event.first === 0 ? 1 : event.first ?? 1,
      pagesize: event.rows,
    });
  };

  useEffect(() => {
    if (kardexCTX.KardexFilters && selectedHotel?.IdHotel) {
      kardexCTX.getAllKardex(kardexCTX.KardexFilters, selectedHotel?.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kardexCTX?.KardexFilters, selectedHotel?.IdHotel]);

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '3px',
              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };

  return (
    <div style={{ padding: '5px' }}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          paddingBottom: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputText style={{ width: '50%' }} onChange={handleSearchText} placeholder="Rechercher"></InputText>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '50%' }}>
          <div
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
            title="Nom"
          >
            <RadioButton
              inputId="ingredient1"
              value="user"
              onChange={() => {
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByName',
                  LastName: debouncedFilterText,
                  Email: undefined,
                  PhoneNumber: undefined,
                  Iata: undefined,
                });
              }}
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByName'}
            />
            <i style={{ marginLeft: '5px' }} className="fa-solid fa-user"></i>
          </div>
          <div
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
            title="Email"
          >
            <RadioButton
              inputId="ingredient2"
              value="mail"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByEmail',
                  Email: debouncedFilterText,
                  LastName: undefined,
                  PhoneNumber: undefined,
                  Iata: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByEmail'}
            />
            <i style={{ marginLeft: '5px' }} className="fa-regular fa-envelope"></i>
          </div>
          <div
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
            title="Téléphone"
          >
            <RadioButton
              inputId="ingredient3"
              value="phone"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByTelephone',
                  PhoneNumber: debouncedFilterText,
                  LastName: undefined,
                  Iata: undefined,
                  Email: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByTelephone'}
            />
            <i style={{ marginLeft: '5px' }} className="fa-solid fa-phone"></i>
          </div>
          <div
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
            title="Code IATAN"
          >
            <RadioButton
              inputId="ingredient4"
              value="IATAN"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByIataCode',
                  Iata: debouncedFilterText,
                  LastName: undefined,
                  PhoneNumber: undefined,
                  Email: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByIataCode'}
            />
            <i style={{ marginLeft: '5px' }} className="fa-solid fa-plane"></i>
          </div>
        </div>
      </div>
      <div>
        <MultiSelect
          title="Catégorie"
          placeholder="Toutes catégories"
          value={kardexCTX.KardexFilters?.CategorySelected}
          panelHeaderTemplate={customHeader}
          onChange={(e) => {
            kardexCTX.setKardexFilters({
              ...kardexCTX.KardexFilters,
              CategorySelected: e.value,
              IdCategory: e.value.map((t: templateObject) => t.code),
            });
          }}
          options={displayMode}
          optionLabel="name"
          style={{ width: 'calc(100% - 5px)', marginBottom: '5px' }}
        />{' '}
      </div>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
        <div title="Afficher les kardex masqués">
          <Checkbox
            checked={kardexCTX.KardexFilters?.Hidden ?? false}
            onChange={(e) =>
              kardexCTX.setKardexFilters({
                ...kardexCTX.KardexFilters,
                Hidden: e.checked ?? false,
              })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-regular fa-file"></i>
        </div>
        <div title="N'afficher que les kardex ayant des tarifs préférentiels">
          <Checkbox
            checked={kardexCTX.KardexFilters?.OnlyWithPreferedRate ?? false}
            onChange={(e) =>
              kardexCTX.setKardexFilters({
                ...kardexCTX.KardexFilters,
                OnlyWithPreferedRate: e.checked ?? false,
              })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-solid fa-percent"></i>
        </div>{' '}
        <div title="Afficher les kardex sociétés">
          <Checkbox
            checked={
              kardexCTX.KardexFilters?.SearchType === 'Both' || kardexCTX.KardexFilters?.SearchType === 'Company'
                ? true
                : false
            }
            onChange={() =>
              kardexCTX.KardexFilters?.SearchType === 'Individual'
                ? kardexCTX.setKardexFilters({
                    ...kardexCTX.KardexFilters,
                    SearchType: 'Both',
                  })
                : kardexCTX.setKardexFilters({
                    ...kardexCTX.KardexFilters,
                    SearchType: 'Company',
                  })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-solid fa-industry"></i>
        </div>{' '}
        <div title="Afficher les kardex individuels">
          <Checkbox
            checked={
              kardexCTX.KardexFilters?.SearchType === 'Both' || kardexCTX.KardexFilters?.SearchType === 'Individual'
                ? true
                : false
            }
            onChange={() =>
              kardexCTX.KardexFilters?.SearchType === 'Company'
                ? kardexCTX.setKardexFilters({
                    ...kardexCTX.KardexFilters,
                    SearchType: 'Both',
                  })
                : kardexCTX.setKardexFilters({
                    ...kardexCTX.KardexFilters,
                    SearchType: 'Individual',
                  })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-solid fa-user"></i>
        </div>{' '}
        <div title="Afficher uniquement les comptes d'avoirs">
          <Checkbox
            checked={kardexCTX.KardexFilters?.OnlyWithAccountBalance ?? false}
            onChange={(e) =>
              kardexCTX.setKardexFilters({
                ...kardexCTX.KardexFilters,
                OnlyWithAccountBalance: e.checked ?? false,
              })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-solid fa-building-columns"></i>
        </div>
        <div title="Afficher uniquement les comptes non utilisés">
          <Checkbox
            checked={kardexCTX.KardexFilters?.OnlyUnused ?? false}
            onChange={(e) =>
              kardexCTX.setKardexFilters({
                ...kardexCTX.KardexFilters,
                OnlyUnused: e.checked ?? false,
              })
            }
          ></Checkbox>
          <i style={{ paddingLeft: '5px' }} className="fa-solid fa-ghost"></i>
        </div>{' '}
      </div>
      {kardexList && (
        <>
          {kardexList.map((e, index) => {
            return <KardexUserItem key={'user' + index} user={e}></KardexUserItem>;
          })}
          <Paginator
            first={kardexCTX?.KardexFilters?.page}
            rows={kardexCTX?.KardexFilters?.pagesize}
            totalRecords={120000}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};

export default KardexGridMobile;
