import React, { useEffect, useState } from 'react';

import BarChart from '@app/components/Common/charts/BarChart';
import { chartOptions } from '@app/components/Common/charts/chartOption';
import CheckInChart from '@app/components/Common/charts/CheckInChart';
import DoughnutChart from '@app/components/Common/charts/DoughnutChart';
import MemoReminder from '@app/components/Common/charts/MemoReminder';
import PieChart from '@app/components/Common/charts/PieChart';
import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import DataGridView from '@app/components/Desktop/Table/dataGridView/DataGridView';
import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useHotel } from '@app/modules/hotel/HotelContext';
import { useUser } from '@app/modules/user/UserContext';
import { dateTo_DD_MM } from '@app/utils/dates';
import moment from 'moment-timezone';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom

const Hotel = () => {
  const userCTX = useUser();
  const dashboardCTX = useDashboard();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const hotelCTX = useHotel();
  const ArrivalsData = hotelCTX.CheckInStats?.Arrivals;
  const DeparturesData = hotelCTX.CheckInStats?.Departures;
  const InHousePax = hotelCTX.CheckInStats?.InHousePax;
  const Housekeepings = hotelCTX.Housekeepings;
  const WeekOccupancyStats = hotelCTX.WeekOccupancyStats;
  const dataFactoryCTX = useDataFactory();
  const ArrivalsGrid = hotelCTX.Arrivals;
  const DeparturesGrid = hotelCTX.Departures;
  const filtersCTX = useFilters();
  const dashboardFilters = filtersCTX.filters.dashboardFilters;
  const InHouseGrid = hotelCTX.InHouse;

  const [t] = useTranslation();
  const { name } = useParams();
  const [hotelName, setHotelName] = useState('');
  const [arrivalsData, setArrivalData] = useState<DougnutChartData | null>(null);
  const [departuresData, setDeparturesData] = useState<DougnutChartData | null>(null);
  const [inHouse, setInHouse] = useState<DougnutChartData | null>(null);
  const [housekeeping, setHousekeeping] = useState<DougnutChartData | null>(null);
  const [occupancy, setOccupancy] = useState<DougnutChartData | null>(null);
  const [gridData, setGridData] = useState<string[][]>([[]]);

  useEffect(() => {
    dashboardFilters.DateStart = moment(selectedHotel?.CurrentPlanningDate).toDate() ?? 0;
    dashboardFilters.hasBeenModified = true;
    hotelCTX.getCheckInStats({ selectedHotel });
    setHotelName(selectedHotel?.Name ?? '');
    hotelCTX.getWeekOccupancyStat({ selectedHotel });
    hotelCTX.getHousekeepings({ selectedHotel });
    moment.locale(userCTX.authenticationInfos?.user?.Culture?.split('-')[1]);
    hotelCTX.getArrivals({ selectedHotel });
    hotelCTX.getDepartures({ selectedHotel });
    hotelCTX.getInHouse({ selectedHotel });
    hotelCTX.getMemoReminders({ selectedHotel });
    dashboardCTX.getGridData({
      dashboardFilters,
      params: { selectedHotel, isTech: userCTX.authenticationInfos.user?.IsAdmin },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]); // Get the 'name' parameter from the URL using useParams

  useEffect(() => {
    if (ArrivalsData) {
      setArrivalData({
        labels: [t('labels.finished'), t('labels.waiting')],
        datasets: [
          {
            data: [ArrivalsData?.Done ?? 0, ArrivalsData?.Pending ?? 0],
            backgroundColor: ['#f44336', '#9c27b0'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      });
    }
  }, [ArrivalsData, t]);

  useEffect(() => {
    if (DeparturesData) {
      setDeparturesData({
        labels: [t('labels.finished'), t('labels.waiting')],
        datasets: [
          {
            data: [DeparturesData?.Done ?? 0, DeparturesData?.Pending ?? 0],
            backgroundColor: ['#f44336', '#9c27b0'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      });
    }
  }, [DeparturesData, t]);

  useEffect(() => {
    if (InHousePax) {
      setInHouse({
        labels: [t('labels.Adultes'), t('labels.Enfants'), t('labels.Bébés')],
        datasets: [
          {
            data: [InHousePax.Adults, InHousePax.Children, InHousePax.Infants],
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      });
    }
  }, [InHousePax, t]);

  useEffect(() => {
    if (Housekeepings) {
      setHousekeeping({
        labels: Housekeepings.map((e) => t('labels.' + e.Data)),
        datasets: [
          {
            data: Housekeepings.map((e) => e.Quantity),
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4', '#8bc34a', '#ffc107'],
            borderColor: '#fff',
            borderWidth: 2,
          },
        ],
      });
    }
  }, [Housekeepings, t]);
  useEffect(() => {
    if (WeekOccupancyStats) {
      setOccupancy({
        labels: WeekOccupancyStats.map((e) => dateTo_DD_MM(new Date(e.Date))),
        datasets: [
          {
            data: WeekOccupancyStats.map((e) => e.OccupancyRate),
            backgroundColor: ['#f44336', '#9c27b0', '#03a9f4', '#8bc34a', '#ffc107'],
            borderColor: '#fff',
            borderWidth: 2,
            label: t('labels.occupancy') ?? '',
          },
        ],
      });
      const data = [
        [t('labels.occupancy'), ...WeekOccupancyStats.map((e) => e.OccupancyRate.toString() + '%')],
        [t('labels.arrivals'), ...WeekOccupancyStats.map((e) => Math.round(e.Arrivals).toString())],
        [t('labels.departures'), ...WeekOccupancyStats.map((e) => Math.round(e.Departures).toString())],
        [t('labels.dayuse'), ...WeekOccupancyStats.map((e) => Math.round(e.DayUse).toString())],
        [t('labels.occupiedRoom'), ...WeekOccupancyStats.map((e) => Math.round(e.OccupiedRooms).toString())],
        [t('labels.outoforder'), ...WeekOccupancyStats.map((e) => Math.round(e.OutOfOrder).toString())],
        [t('labels.stayover'), ...WeekOccupancyStats.map((e) => Math.round(e.StayOver).toString())],
        [t('labels.stayoverpax'), ...WeekOccupancyStats.map((e) => Math.round(e.StayOverPax).toString())],
      ];
      setGridData(data);
    }
  }, [WeekOccupancyStats, t]);
  return (
    <>
      <div>
        <ContentHeader title={hotelName} />
        <div className="row">
          {arrivalsData && (
            <div className="col-lg-3 col-12">
              <div className={`small-box bg-gradient-light`}>
                <DoughnutChart
                  title={
                    <span style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                      <span>{t('chart.doughnut.title.arrivals')}</span>
                      <span>{dateTo_DD_MM(new Date(ArrivalsData?.Date as string))}</span>
                    </span>
                  }
                  options={chartOptions}
                  showTitle={true}
                  total={ArrivalsData?.Total}
                  data={arrivalsData}
                ></DoughnutChart>
              </div>
            </div>
          )}
          {departuresData && (
            <div className="col-lg-3 col-12">
              {' '}
              <div className={`small-box bg-gradient-light`}>
                <DoughnutChart
                  title={
                    <span style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                      <span>{t('chart.doughnut.title.departures')}</span>
                      <span>{dateTo_DD_MM(new Date(DeparturesData?.Date as string))}</span>
                    </span>
                  }
                  options={chartOptions}
                  showTitle={true}
                  total={DeparturesData?.Total}
                  data={departuresData}
                ></DoughnutChart>
              </div>
            </div>
          )}
          {inHouse && InHousePax && (
            <div className="col-lg-3 col-12">
              {' '}
              <div className={`small-box bg-gradient-light`}>
                <DoughnutChart
                  title={t('chart.doughnut.title.pax')}
                  options={chartOptions}
                  showTitle={true}
                  total={InHousePax?.Adults + InHousePax?.Children + InHousePax?.Infants}
                  data={inHouse}
                ></DoughnutChart>
              </div>
            </div>
          )}
          {housekeeping && (
            <div className="col-lg-3 col-12">
              {' '}
              <div className={`small-box bg-gradient-light`}>
                <PieChart
                  options={chartOptions}
                  title={t('chart.doughnut.title.housekeeping')}
                  showTitle={true}
                  total={housekeeping.datasets.reduce((totalSum, currentDataset) => {
                    const sum = currentDataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                    return totalSum + sum;
                  }, 0)}
                  data={housekeeping}
                ></PieChart>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className={!isMobile && !isTablet ? 'col-4' : 'col-12'}>
            <div className={`small-box bg-gradient-light`}>
              {occupancy && (
                <BarChart
                  blueColor
                  columnName={
                    WeekOccupancyStats?.map((e) =>
                      new Date(e.Date).toLocaleDateString(userCTX.authenticationInfos.language, {
                        day: '2-digit',
                        month: '2-digit',
                      }),
                    ) ?? []
                  }
                  showTitle={true}
                  title={t('chart.bar.weekStat')}
                  data={occupancy}
                  displayDT
                  gridData={gridData}
                  options={chartOptions}
                ></BarChart>
              )}
            </div>
          </div>
          <div className={!isMobile && !isTablet ? 'col-5' : 'col-12'}>
            <div className={`small-box bg-gradient-light`}>
              {ArrivalsGrid && DeparturesGrid && InHouseGrid && (
                <CheckInChart
                  data={{ Arrivals: ArrivalsGrid, Departures: DeparturesGrid, InHouse: InHouseGrid }}
                  showTitle={true}
                  title={
                    <span style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                      <span>{t('labels.checkin')}</span>
                      <span>
                        {dateTo_DD_MM(new Date(dataFactoryCTX.PMSSettings?.DateFacturation as string)) +
                          (dataFactoryCTX.PMSSettings?.DateFacturation !== dataFactoryCTX.PMSSettings?.DatePlanning
                            ? t('labels.planning') +
                              ' ' +
                              dateTo_DD_MM(new Date(dataFactoryCTX.PMSSettings?.DatePlanning as string))
                            : ' ')}
                      </span>
                    </span>
                  }
                ></CheckInChart>
              )}
            </div>
          </div>
          <div className={!isMobile && !isTablet ? 'col-3' : 'col-12'}>
            {' '}
            <div className={`small-box bg-gradient-light`}>
              <MemoReminder showTitle title={t('labels.MemoReminders')}></MemoReminder>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <DataGridView openOnLoad fullComponentSize IdHotel={selectedHotel?.IdHotel} noTitle></DataGridView>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hotel;
