export function roundInteger(number: number): number {
  return Math.round(number);
}
export function roundOneDecimal(number: number): number {
  return Math.round(number * 10 + Number.EPSILON) / 10;
}

export function formatDistance(distance: number | undefined): string {
  if (distance === undefined || Number.isNaN(distance)) {
    return '';
  }

  if (distance > 1000) {
    return (distance / 1000).toFixed(1) + ' km';
  }

  return distance + ' m';
}

export function safeParseInt(value: string, fallback: number): number {
  try {
    const result = parseInt(value, 10);
    if (Number.isNaN(result)) {
      return fallback;
    } else {
      return result;
    }
  } catch {
    return fallback;
  }
}

export function toPercent(value: number | undefined): number | undefined;
export function toPercent(value: number): number;
export function toPercent(value: number | undefined): number | undefined {
  if (value === undefined) {
    return undefined;
  } else {
    return Math.round(value * 100);
  }
}

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}
