import colors from '@app/styles/colors';
import { ChartArea } from 'chart.js';

export function simpleGradient(color: string) {
  let colorStart = '';
  let colorEnd = '';
  const colors = {
    colorPrimaryMain: '#0000FF', // Bleu
    colorSecondaryMain: '#8A2BE2', // Violin
  };

  switch (color) {
    case 'pierre':
      colorStart = '#32ACF2';
      colorEnd = '#53CAF7';
      break;
    case 'red':
      colorStart = '#ff0000';
      colorEnd = '#693232';
      break;
    case 'green':
      colorStart = '#00ff0c';
      colorEnd = '#456946';
      break;
    case 'blue':
      colorStart = colors.colorPrimaryMain;
      colorEnd = colors.colorPrimaryMain;
      break;
    case 'violine':
      colorStart = colors.colorSecondaryMain;
      colorEnd = colors.colorSecondaryMain;
      break;
    case 'pink':
      colorStart = '#bc5090';
      colorEnd = '#b6719a';
      break;
    case 'coral':
      colorStart = '#ff6361';
      colorEnd = '#f8a2a0';
      break;
    case 'orange':
      colorStart = '#ffa600';
      colorEnd = '#fbca6f';
      break;
    default:
      colorStart = '#FFFFFF'; // Blanc par défaut si aucune correspondance
      colorEnd = '#FFFFFF';
      break;
  }

  return `linear-gradient(to bottom, ${colorStart}, ${colorEnd})`;
}
export function createGradient(color: string, ctx: CanvasRenderingContext2D, area: ChartArea) {
  let colorStart = '';

  let colorEnd = '';
  switch (color) {
    case 'pierre':
      colorStart = '#32ACF2';
      colorEnd = '#53CAF7';
      break;
    case 'red':
      colorStart = '#ff0000';
      colorEnd = '#693232';
      break;
    case 'green':
      colorStart = '#00ff0c';

      colorEnd = '#456946';
      break;
    case 'blue':
      colorStart = colors.colorPrimaryMain;
      colorEnd = colors.colorPrimaryMain;
      break;
    case 'violine':
      colorStart = colors.colorSecondaryMain;
      colorEnd = colors.colorSecondaryMain;
      break;
    case 'pink':
      colorStart = '#bc5090';
      colorEnd = '#b6719a';
      break;
    case 'coral':
      colorStart = '#ff6361';
      colorEnd = '#f8a2a0';
      break;
    case 'orange':
      colorStart = '#ffa600';
      colorEnd = '#fbca6f';
      break;
    default:
      break;
  }

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);

  gradient.addColorStop(1, colorEnd);

  return gradient;
}
export function getChartColors() {
  return [
    colors.colorPrimaryMain,
    colors.colorSecondaryMain,
    colors.colorSuccessMain,
    colors.colorWarningMain,
    colors.colorPurple,
    '#ff0000',
    '#456946',
  ];
}

export const chartOptions = {
  // Title configuration
};
