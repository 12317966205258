export function formatCurrency(price: number): string {
  return `${(price / 100).toFixed(2).replace('.', ',')} €`;
}

export const formatPrice = (price: number, currency: Currency | null) => {
  if (currency != null) {
    const { Symbol, CurrencyPositivePattern, NbDecimals } = currency;

    // Check if the number is negative and adjust the pattern accordingly
    const isNegative = price < 0;
    const positivePattern = CurrencyPositivePattern + (isNegative ? 4 : 0);

    // Format the price based on the pattern
    let formattedPrice;
    switch (positivePattern) {
      case 0:
        formattedPrice = `${Symbol}${Math.abs(price).toFixed(NbDecimals)}`;
        break;
      case 1:
        formattedPrice = `${Math.abs(price).toFixed(NbDecimals)}${Symbol}`;
        break;
      case 2:
        formattedPrice = `${Symbol} ${Math.abs(price).toFixed(NbDecimals)}`;
        break;
      case 3:
        formattedPrice = `${Math.abs(price).toFixed(NbDecimals)} ${Symbol}`;
        break;
      default:
        formattedPrice = `${Symbol}${Math.abs(price).toFixed(NbDecimals)}`;
    }

    return formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else {
    const formattedPrice = price.toFixed(2);

    return formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
};
