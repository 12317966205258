/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import { KardexType } from '@app/types/types';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';

interface props {
  data: KardexType;

  title: ReactNode;
  showTitle: boolean;
}

const MobileKardex: React.FC<props> = (props) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '100%', padding: '0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            <Button
              icon="fa-solid fa-clock-rotate-left"
              style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
            ></Button>
            <Button
              icon={props.data.BlackList ? 'fa-solid fa-shield-virus' : 'fa-solid fa-shield'}
              style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
            ></Button>
            <Button
              icon={!props.data.Protected ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock'}
              style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
            ></Button>
            <Button
              icon="fa-solid fa-circle-info"
              style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
            ></Button>
            <span>ID : </span>
            <span> {props.data.IdKardex}</span>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', padding: '5px' }}>
            <InputText
              placeholder="Nom"
              defaultValue={props.data.Nom}
              style={{ width: '50vw', marginRight: '5px' }}
            ></InputText>
            <Dropdown placeholder="Type de Kardex" style={{ width: '20vw', marginRight: '5px' }}></Dropdown>
            <Dropdown placeholder="Langue" style={{ width: '15vw', marginRight: '5px' }}></Dropdown>
            <Dropdown placeholder="Couleur" style={{ width: '15vw' }}></Dropdown>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <Button
              icon="fa-solid fa-circle-info"
              style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
            ></Button>
            <InputText
              defaultValue={props.data.Address?.Address}
              placeholder="Adresse"
              style={{ width: 'calc(100% - 30px)' }}
            ></InputText>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <InputText
              defaultValue={props.data.Address?.ZipCode}
              style={{ width: '15%', marginRight: '5px' }}
              placeholder="Code postal"
            ></InputText>
            <InputText
              defaultValue={props.data.Address?.City}
              style={{ width: '70%', marginRight: '5px' }}
              placeholder="Ville"
            ></InputText>
            <Dropdown style={{ width: '15%' }} placeholder="Pays"></Dropdown>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <span>
                <i className="fa-solid fa-mobile-screen-button"></i>
              </span>
              <Dropdown style={{ width: '40%' }} placeholder={props.data.MobilePhone?.CountryCode}></Dropdown>

              <InputText placeholder="Telephone" defaultValue={props.data.MobilePhone?.Number}></InputText>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                <i className="fa-solid fa-house"></i>
              </span>
              <Dropdown style={{ width: '40%' }} placeholder={props.data.HomePhone?.CountryCode}></Dropdown>
              <InputText placeholder="Telephone" defaultValue={props.data.HomePhone?.Number}></InputText>
            </div>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <span>
                <i className="fa-regular fa-building"></i>
              </span>
              <Dropdown style={{ width: '40%' }} placeholder={props.data.BusinessPhone?.CountryCode}></Dropdown>

              <InputText placeholder="Telephone" defaultValue={props.data.BusinessPhone?.Number}></InputText>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                <i className="fa-solid fa-fax"></i>
              </span>
              <Dropdown style={{ width: '40%' }} placeholder={props.data.Fax?.CountryCode}></Dropdown>

              <InputText placeholder="Telephone" defaultValue={props.data.Fax?.Number}></InputText>
            </div>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <span>
              <i className="fa-solid fa-at"></i>
            </span>

            <InputText
              placeholder="Email"
              defaultValue={props.data.Email}
              style={{ width: 'calc(100% - 32px)' }}
            ></InputText>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <Button style={{ marginRight: '5px', borderRadius: '7.5px' }}> CB</Button>
            {props?.data?.IsCompany === true && <InputText placeholder="Contact"></InputText>}
            {props?.data?.IsCompany === false && (
              <>
                <Button style={{ marginRight: '5px', borderRadius: '7.5px', whiteSpace: 'nowrap' }}>
                  {' '}
                  Documents d'identité
                </Button>{' '}
                <InputText style={{ marginRight: '5px', width: '20%' }} placeholder="Profession"></InputText>
                <InputText style={{ marginRight: '5px', width: '20%' }} placeholder="Date de Naissance"></InputText>
                <InputText style={{ marginRight: '5px', width: '20%' }} placeholder="Ville de naissance"></InputText>
                <Dropdown placeholder="Pays"></Dropdown>
              </>
            )}
          </div>
          {props?.data?.IsCompany === true && (
            <>
              <div>
                <InputText placeholder="RCS"></InputText>
                <InputText placeholder="Numéro Siret"></InputText>
                <InputText placeholder="TVA"></InputText>
              </div>
              <div>
                <InputText placeholder="Code IATAN"></InputText>
                <InputText placeholder="Site Internet"></InputText>
              </div>
            </>
          )}
          {props?.data?.IsCompany === false && (
            <>
              <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <i className="fa-solid fa-user" style={{ marginRight: '10px' }}></i>
                <span style={{ marginRight: '10px' }}>Informations compte débiteur</span>
                <Checkbox checked={false}></Checkbox>
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span>Solde maximum autorisé : </span>{' '}
                <InputText
                  style={{ width: '7%' }}
                  type="number"
                  placeholder="0"
                  defaultValue={props.data.DebtorMaxSold}
                ></InputText>
                <span>Echéance débiteur maximum (en jours) : </span>{' '}
                <InputText
                  style={{ width: '7%' }}
                  type="number"
                  placeholder="0"
                  value={props.data.DebtorRemind}
                ></InputText>
                <span>Compter à partir de la fin de mois </span>{' '}
                <Checkbox checked value={props.data.DebtorRemindEoM}></Checkbox>
              </div>
              <div></div>
              <div></div>
            </>
          )}
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <Dropdown style={{ width: '20%', marginRight: '5px' }} placeholder="Origine"></Dropdown>
            <Dropdown style={{ width: '20%', marginRight: '5px' }} placeholder="Type de compte"></Dropdown>
            <Dropdown style={{ width: '20%', marginRight: '5px' }} placeholder="Catégorie"></Dropdown>
            <Dropdown style={{ width: '20%' }} placeholder="But des séjours"></Dropdown>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <div style={{ width: '50%' }}>
              <Dropdown
                style={{ width: 'calc(100% - 50px)', marginRight: '5px' }}
                placeholder="Ajouter un code marketing"
              ></Dropdown>
              <Button style={{ borderRadius: '7.5px' }} icon="fa-solid fa-plus"></Button>
            </div>
            <div style={{ width: '50%' }}>
              <Dropdown style={{ width: '100%' }} placeholder="Garantie de reservation"></Dropdown>
            </div>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
            <span>Kardex payeur</span> <Dropdown placeholder="Définir un payeur"></Dropdown>
            <span>Parent</span>
            <Dropdown placeholder="Ajouter un kardex parent"></Dropdown> <span>Enfant</span>
            <Dropdown placeholder="Ajouter un kardex enfant"></Dropdown>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '100%', padding: '0px', height: 'calc(100vh - 250px)' }}>
          <Card style={{ margin: '10px', height: '100%' }}>
            <TabView style={{ height: '100%', border: 0 }}>
              <TabPanel header="Note" style={{ height: '100%', border: 0 }}>
                {props.data.Memo}
              </TabPanel>
              <TabPanel style={{ height: '100%', border: 0 }} header="Compte d'avoir"></TabPanel>

              <TabPanel header="Historique"></TabPanel>
            </TabView>
          </Card>
          <Card style={{ margin: '10px' }}>
            <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
              <Button
                title="Valeurs par défaut création de résa."
                icon="fa-solid fa-wrench"
                style={{ marginRight: '5px', borderRadius: '7.5px' }}
              ></Button>
              <Button
                title="Créer une réservation"
                icon="fa-solid fa-wand-sparkles"
                style={{ marginRight: '5px', borderRadius: '7.5px' }}
              ></Button>
              <Button style={{ marginRight: '5px', borderRadius: '7.5px' }}>RGPD</Button>
              <Button icon="fa-solid fa-print" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-file-pdf" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-envelope" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>

              <Button icon="fa-solid fa-percent" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-code-merge" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-trash" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default MobileKardex;
