import MainCourantteFilter from '@app/components/Common/filter/MainCouranteFilter';
import MainCouranteDataTable from '@app/components/Desktop/Table/mainCouranteDatatable/mainCouranteDatatable';
import { MainCouranteProvider } from '@app/modules/MainCourante/MainCouranteContext';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const MainCourante: React.FC = () => {
  const { orientation } = useMobileOrientation();
  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        {(isMobile || isTablet) && (
          <div
            className={`small-box bg-gradient-gray-dark`}
            style={{ width: orientation === 'portrait' ? '100%' : '50%' }}
          >
            <div className="inner">
              <MainCourantteFilter></MainCourantteFilter>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12 col-12">
            <MainCouranteProvider>
              <MainCouranteDataTable></MainCouranteDataTable>
            </MainCouranteProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCourante;
