import { createLogging } from '@modules/logger/createLogging';
import loglevel, { LogLevelNames } from 'loglevel';

import { config } from '@utils/config';

import { LoggerContext } from './types';

export function createApiLogger(context: LoggerContext): loglevel.Logger {
  const logger = loglevel.getLogger('api');
  logger.methodFactory = createLogging({
    context,
    sentry: { level: config.LOGGER_API as LogLevelNames },
    console: { level: config.LOGGER_API as LogLevelNames },
  });
  logger.setLevel((config.LOGGER_API as LogLevelNames) ?? 'info');

  return logger;
}
