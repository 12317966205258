export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmptyArray<T>(array: Array<T> | undefined | null): array is NonEmptyArray<T> {
  return array !== undefined && array !== null && array.length > 0;
}

export function tail<T>(array: NonEmptyArray<T>): Array<T> {
  return array.slice(1);
}

export function removeNullable<T>(array: Array<T | undefined | null>): Array<T> {
  return array.filter((value) => value !== null && value !== undefined) as Array<T>;
}

/**
 * Use Array.every to check if all sub items are inside the main array.
 * use Array.indexOf(value, offset) to check a sub item is inside the main array
 * the magic is to use the offset to ensure the order of the items
 *
 * @see https://stackoverflow.com/a/49706967
 */
export function hasSubArray<T>(mainArray: Array<T>, subArray: Array<T>): boolean {
  return subArray.every(
    (
      (offset) => (subItem: T) =>
        (offset = mainArray.indexOf(subItem, offset) + 1)
    )(0),
  );
}

export function hasDuplicates<Item>(array: Array<Item>): boolean {
  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[i] === array[j]) {
        return true;
      }
    }
  }
  return false;
}

export function hasDuplicatesBy<Item, Key>(array: Array<Item>, by: (item: Item) => Key): boolean {
  return hasDuplicates(array.map(by));
}
