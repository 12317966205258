import { ReactNode, useEffect, useRef, useState } from 'react';

import { createGradient, getChartColors } from '@app/components/Common/charts/chartOption';
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface props {
  data: ChartData<'doughnut', number[], unknown>;
  options: ChartOptions;
  title: ReactNode;
  showTitle: boolean;
  total?: number;
  height?: string;
}

const PieChart: React.FC<props> = ({ data, options, title, showTitle, total, height }) => {
  const chartRef = useRef<ChartJS<'doughnut'>>(null);
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({
    datasets: [],
  });

  const [legendItems, setLegendItems] = useState([<div key="eieie"></div>]);
  const [valueItems, setValueItems] = useState<number[] | string[] | undefined>([] || undefined || '');

  const chartColors = getChartColors();

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const tempData = {
      ...data,

      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: [
          createGradient('blue', chart.ctx, chart.chartArea),
          createGradient('violine', chart.ctx, chart.chartArea),
          createGradient('pink', chart.ctx, chart.chartArea),
          createGradient('coral', chart.ctx, chart.chartArea),
          createGradient('orange', chart.ctx, chart.chartArea),
          createGradient('red', chart.ctx, chart.chartArea),

          createGradient('green', chart.ctx, chart.chartArea),
        ],
        data: dataset.data.map((_, index) => dataset.data[index]), // Hide values initially
      })),
    };
    setValueItems(tempData?.datasets[0].data);
    setLegendItems(
      (
        tempData?.labels?.map((label, index) => (
          <div
            key={'legend' + index}
            className={`legend-item legend-item-visible`}
            style={{
              color: chartColors[index],
              position: 'relative',
              textTransform: 'uppercase',
            }}
          >
            <span
              style={{
                color: chartColors[index],
                position: 'relative',

                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {label as ReactNode}
            </span>
          </div>
        )) || [<></>]
      ).concat([
        <div
          key={'total'}
          style={{
            color: 'grey',
            position: 'relative',
            textTransform: 'uppercase',
          }}
        >
          <span
            style={{
              color: '#632252',
              position: 'relative',

              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {'Total :' as ReactNode}
          </span>
        </div>,
      ]),
    );
    setChartData(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      style={{
        padding: '10px',
        width: '100%',
      }}
    >
      {showTitle && (
        <>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              padding: 2,
              fontSize: 17,
              fontWeight: 'bolder',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>
          <Divider variant="middle" />
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '10pt',
        }}
      >
        <div className="custom-legend" key="legend">
          {legendItems}
        </div>
        <div className="custom-legend">
          {(
            valueItems?.map((e, index) => (
              <div key={'item' + index}>
                <span
                  style={{
                    color: chartColors[index],
                    position: 'relative',

                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {e}
                </span>
              </div>
            )) || [<>.</>]
          ).concat([
            <span
              key="total"
              style={{
                color: '#632252',
                position: 'relative',

                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {total}
            </span>,
          ])}
        </div>

        <div style={{ height: height ?? '116px', alignContent: 'center' }}>
          <Doughnut
            ref={chartRef}
            data={chartData}
            options={{
              ...options,
              cutout: 0,
              plugins: { legend: { display: false } },
              scales: {},
            }}
            style={{ padding: 5 }}
          />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
