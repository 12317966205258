import React, { useState } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { toggleDarkMode } from '@app/store/reducers/ui';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { PfDropdown, PfImage } from '@profabric/react-components';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSmallUserImage = styled(PfImage)`
  margin-top: 3px;
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const StyledBigUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
  --pf-border: 3px solid #fff3;
`;

const UserHeader = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 175px;
  padding: 10px;
  text-align: center;
  img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }
  p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
    small {
      display: block;
      font-size: 12px;
    }
  }
`;

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 280px;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

const UserDropdown = () => {
  const userCTX = useUser();
  const [t] = useTranslation();
  const [dropdownOpen] = useState(false);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const dispatch = useDispatch();

  const handleDarkModeChange = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <StyledDropdown isOpen={dropdownOpen} hideArrow>
      <StyledSmallUserImage
        slot="button"
        fallbackSrc="/img/default-profile.png"
        alt="User"
        width={25}
        height={25}
        rounded
      />
      <div slot="menu" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <UserHeader
          style={{
            width: '100%',
            backgroundColor: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain,
            color: colors.colorPrimaryContrastText,
          }}
        >
          <StyledBigUserImage
            fallbackSrc={userCTX.authenticationInfos?.user?.AvatarUrl}
            alt="User"
            width={90}
            height={90}
            rounded
          />
          {userCTX.authenticationInfos.user && (
            <p style={{ padding: 0, margin: 5 }}>{userCTX.authenticationInfos.user?.FirstnameLastname}</p>
          )}
          {userCTX.authenticationInfos.user && !userCTX.authenticationInfos?.user?.IsAdmin && (
            <Link
              style={{ margin: '5px', color: colors.colorPrimaryContrastText }}
              to={userCTX.authenticationInfos?.user?.UserProfileUrl ?? '/'}
            >
              {' '}
              {t('labels.showprofile')}
            </Link>
          )}
        </UserHeader>
        <div
          style={{
            width: '100%',
            backgroundColor: darkMode ? colors.colorGreyAdminLTE : '#f9f9f9',
            color: !darkMode ? colors.colorGreyAdminLTE : colors.colorPrimaryContrastText,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <Checkbox style={{}} checked={darkMode} onChange={handleDarkModeChange}></Checkbox>
          <span> Mode sombre</span>
        </div>
        <div
          style={{
            width: '100%',
            backgroundColor: darkMode ? colors.colorGreyAdminLTE : '#f9f9f9',
            color: !darkMode ? colors.colorGreyAdminLTE : colors.colorPrimaryContrastText,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          {false && (
            <Button
              icon="fa-regular fa-envelope fa-padding"
              onClick={() => console.log('messagerie')}
              style={{ margin: '5px', backgroundColor: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain }}
            >
              {t('login.button.mail')}
            </Button>
          )}
          <Button
            style={{ margin: '5px', backgroundColor: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain }}
            onClick={() => userCTX.logout()}
          >
            {t('login.button.signOut')}
          </Button>
        </div>
      </div>
    </StyledDropdown>
  );
};

export default UserDropdown;
