import { config } from '@app/utils/config';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getLogger } from 'loglevel';
import { toast } from 'react-toastify';

import { decrementCallInProgress, incrementCallInProgress } from '@services/apiState';

/* eslint-disable */
import { storage } from '@store/localstorage/localStorage';

const ApiConfig = {
  baseURL: config.API_URL,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
};

const instance = axios.create(ApiConfig);
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    //We save the last time call
    if (config.url !== '/WebPubSub/SendToGroup' && config.url !== '/User/RefreshToken') {
      incrementCallInProgress();
    }
    const lastCall = storage.getParam('lastCall');
    if (config.url !== '/auth/login') {
      if (lastCall !== undefined && lastCall !== null) {
        if (Date.now() - parseInt(lastCall) > 27000000) {
          storage.setParam('needToRelog', 'true');
          throw 'too long time';
        }
      }
    }
    storage.setParam('lastCall', Date.now().toString());
    const token = storage.getParam('access_token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['TimeZoneOffset'] = new Date().getTimezoneOffset();
    getLogger('api').debug(`[query][${config.url?.split('/')[config.url?.split('/').length - 1]}]`, {
      method: config.method,
      url: `${config.url}`,
      params: config.params,
      data: config.data,
    });

    return config;
  },
  (error: AxiosError) => {
    // Handle request error here
    getLogger('api').error(error);
    return Promise.reject(error);
  },
);

function onFulfilled<T>(response: AxiosResponse<T>) {
  if (response.config.url !== '/WebPubSub/SendToGroup' && response.config.url !== '/User/RefreshToken') {
    decrementCallInProgress();
  }
  try {
    getLogger('api').info(
      `[response][${response.request.responseURL?.split('/')[response.request.responseURL?.split('/').length - 1]}]`,
      'OK',
      response,
    );
  } catch {
    // safe fallback for tests
    getLogger('api').info(
      `[response][${response.request.responseURL?.split('/')[response.request.responseURL?.split('/').length - 1]}]`,
      'OK',
      response,
    );
  }
  return response; // Return the response object
}

const onRejected = (e: AxiosError) => {
  decrementCallInProgress();
  getLogger('api').error(e);
  if (e.response?.status === 401) {
    storage.setParam('needToRelog', 'true');
  } else if (e.code === 'ERR_NETWORK') {
    storage.setParam('needToRelog', 'true');
  } else {
    toast.error(e.message);
  }
};

instance.interceptors.response.use(onFulfilled, onRejected);

export default instance;
