import React from 'react';

import App from '@app/App';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from '@store/store';

import { AnalytiqueProvider } from './modules/Analytics/AnalyticsContext';
import { DataFactoryProvider } from './modules/DataFactory/DataFactoryContext';
import { FiltersProvider } from './modules/filter/filterContext';
import { KardexProvider } from './modules/kardex/KardexContext';
import { LoggerProvider } from './modules/logger/LoggerContext';
import { SubFormProvider } from './modules/SubFormContext';
import { UserProvider } from './modules/user/UserContext';
import * as serviceWorker from './serviceWorker';
import { config } from './utils/config';

import './index.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@styles/index.css';
import '@styles/colors.css';
import '@styles/grid.css';
import '@styles/scheduler.css';
import '@styles/modale.css';
import '@styles/flags.css';

import './utils/i18n';

interface CustomWindow extends Window {
  PF?: {
    config: {
      mode: string;
    };
  };
}
declare const window: CustomWindow;

window.PF = {
  config: {
    mode: 'bs4',
  },
};

const container: HTMLElement | null = document.getElementById('root');
const root = createRoot(container || document.body);
window.addEventListener('beforeunload', function () {
  if (!window.location.hostname.includes('localhost')) {
    // Vider le localStorage
    localStorage.clear();
    navigator.sendBeacon(config.API_URL + '/User/Logout');
    const url = `${config.API_URL}/User/Logout`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Votre corps de requête ici, si nécessaire
      }),
      keepalive: true, // Important pour s'assurer que la requête est envoyée même lorsque la page se décharge
    };
    fetch(url, options);
    axios.post(config.API_URL + '/User/Logout', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
});
root.render(
  <Provider store={store}>
    <LoggerProvider>
      <DataFactoryProvider>
        <UserProvider>
          <FiltersProvider>
            <AnalytiqueProvider>
              <section className="content">
                <SubFormProvider>
                  <KardexProvider>
                    <App />
                  </KardexProvider>
                </SubFormProvider>
              </section>
            </AnalytiqueProvider>
          </FiltersProvider>
        </UserProvider>
      </DataFactoryProvider>
    </LoggerProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
