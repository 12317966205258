export interface Option {
  value: string;
  label: string;
}

export const NAVBAR_LIGHT_VARIANTS: Array<Option> = [
  { value: 'navbar-light', label: 'Light' },
  { value: 'navbar-warning navbar-light', label: 'Warning' },
  { value: 'navbar-white navbar-light', label: 'White' },
  { value: 'navbar-orange navbar-light', label: 'Orange' },
];
export const NAVBAR_DARK_VARIANTS: Array<Option> = [
  { value: 'navbar-dark', label: 'Dark' },
  { value: 'navbar-primary navbar-dark', label: 'Primary' },
  { value: 'navbar-secondary navbar-dark', label: 'Secondary' },
  { value: 'navbar-info navbar-dark', label: 'Info' },
  { value: 'navbar-success navbar-dark', label: 'Success' },
  { value: 'navbar-danger navbar-dark', label: 'Danger' },
  { value: 'navbar-indigo navbar-dark', label: 'Indigo' },
  { value: 'navbar-purple navbar-dark', label: 'Purple' },
  { value: 'navbar-pink navbar-dark', label: 'Pink' },
  { value: 'navbar-navy navbar-dark', label: 'Navy' },
  { value: 'navbar-lightblue navbar-dark', label: 'Light Blue' },
  { value: 'navbar-teal navbar-dark', label: 'Teal' },
  { value: 'navbar-cyan navbar-dark', label: 'Cyan' },
  { value: 'navbar-gray-dark navbar-dark', label: 'Gray Dark' },
  { value: 'navbar-gray navbar-dark', label: 'Gray' },
];

export const SIDEBAR_DARK_SKINS: Array<Option> = [
  { value: 'sidebar-dark-primary', label: 'Primary' },
  { value: 'sidebar-dark-warning', label: 'Warning' },
  { value: 'sidebar-dark-info', label: 'Info' },
  { value: 'sidebar-dark-danger', label: 'Danger' },
  { value: 'sidebar-dark-success', label: 'Success' },
  { value: 'sidebar-dark-indigo', label: 'Indigo' },
  { value: 'sidebar-dark-lightblue', label: 'Lightblue' },
  { value: 'sidebar-dark-navy', label: 'Navy' },
  { value: 'sidebar-dark-purple', label: 'Purple' },
  { value: 'sidebar-dark-fuchsia', label: 'Fuchsia' },
  { value: 'sidebar-dark-pink', label: 'Pink' },
  { value: 'sidebar-dark-maroon', label: 'Maroon' },
  { value: 'sidebar-dark-orange', label: 'Orange' },
  { value: 'sidebar-dark-lime', label: 'Lime' },
  { value: 'sidebar-dark-teal', label: 'Teal' },
  { value: 'sidebar-dark-olive', label: 'Olive' },
];

export const SIDEBAR_LIGHT_SKINS: Array<Option> = [
  { value: 'sidebar-light-primary', label: 'Primary' },
  { value: 'sidebar-light-warning', label: 'Warning' },
  { value: 'sidebar-light-info', label: 'Info' },
  { value: 'sidebar-light-danger', label: 'Danger' },
  { value: 'sidebar-light-success', label: 'Success' },
  { value: 'sidebar-light-indigo', label: 'Indigo' },
  { value: 'sidebar-light-lightblue', label: 'Lightblue' },
  { value: 'sidebar-light-navy', label: 'Navy' },
  { value: 'sidebar-light-purple', label: 'Purple' },
  { value: 'sidebar-light-fuchsia', label: 'Fuchsia' },
  { value: 'sidebar-light-pink', label: 'Pink' },
  { value: 'sidebar-light-maroon', label: 'Maroon' },
  { value: 'sidebar-light-orange', label: 'Orange' },
  { value: 'sidebar-light-lime', label: 'Lime' },
  { value: 'sidebar-light-teal', label: 'Teal' },
  { value: 'sidebar-light-olive', label: 'Olive' },
];

export const hexToRgba = (hex: string, alpha: string) => {
  // Ensure hex is a string
  hex = String(hex);

  // Remove the hash at the beginning if it's there
  if (hex.charAt(0) === '#') {
    hex = hex.substring(1);
  }

  // If it's a shorthand hex color, expand it
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Convert the string to numbers
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Combine them with the alpha value into an rgba string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
