import React, { useState } from 'react';

import Kardex from '@app/components/Desktop/Kardex/Kardex';
import KardexGrid from '@app/components/Desktop/Kardex/KardexGrid';
import KardexGridMobile from '@app/components/Mobile/Kardex/KardexGridMobile';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useKardex } from '@app/modules/kardex/KardexContext';
import { useTabView } from '@app/modules/Tabview/TabViewContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { creditCard, MemoType, status } from '@app/utils';
import { dateTo_dd_MM_yyyy, dateToH_h_m } from '@app/utils/dates';
import { formatPrice } from '@app/utils/formatCurrency';
import { mdiAirplaneRemove } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment-timezone';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import CountryDropdown from '../flags/CountryDropdown';

interface LightBookingSummaryProps {
  data: BookingSummary;
}

const LightBookingSummary: React.FC<LightBookingSummaryProps> = ({ data }) => {
  const userCTX = useUser();
  const [dialogVisible, setDialogVisible] = useState(false);
  const dataFactoryCTX = useDataFactory();
  const tabViewCTX = useTabView();
  const kardexCTX = useKardex();
  const [productDetail, setProductDetail] = useState<Item>();
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const openKardex = async (IdKardex: string) => {
    const id = tabViewCTX.currentID;

    const newTabView = {
      id: id, // ou utilisez un ID unique
      component: isMobileOnly ? KardexGridMobile : KardexGrid, // Remplacez par le composant que vous souhaitez afficher
      data: null, // Les données que vous souhaitez transmettre au composant
      actions: { hideTabView: () => tabViewCTX.hideTabView(id) },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Liste des kardex',
      tabMode: true,
      componentType: 'Kardex',
    };

    if (tabViewCTX.tabView.listOfTabsDisplayed.length === 0) {
      tabViewCTX.setTabView((prevSubForm) => ({
        ...prevSubForm,
        listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
      }));
      tabViewCTX.minimizeTabView(false);
      tabViewCTX.setCurrentID(id + 1);
      tabViewCTX.setActiveTab(id);
    }

    kardexCTX.getKardekById(userCTX.authenticationInfos.selectedHotel?.IdHotel as number, IdKardex).then((t) => {
      const id = tabViewCTX.currentID;
      const newTabView = {
        id: id, // ou utilisez un ID unique
        component: Kardex, // Remplacez par le composant que vous souhaitez afficher
        data: t, // Les données que vous souhaitez transmettre au composant
        componentType: 'Kardex',
        actions: {
          hideTabView: () => {
            tabViewCTX.hideTabView(id);
            kardexCTX.setCurrentKardex(null);
            kardexCTX.setStayFilters(null);
          },
        },
        draggable: false, // Définissez-le en fonction de vos besoins
        fullWidth: true, // Définissez-le en fonction de vos besoins
        fullHeight: true, // Définissez-le en fonction de vos besoins
        translatedTitle: t?.Nom,
      };

      if (tabViewCTX.tabView.listOfTabsDisplayed.length === 0) {
        tabViewCTX.setTabView((prevSubForm) => ({
          ...prevSubForm,
          listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
        }));
        tabViewCTX.minimizeTabView(false);
        tabViewCTX.setCurrentID(id + 1);
        tabViewCTX.setActiveTab(id);
      } else {
        tabViewCTX.minimizeTabView(false);
        tabViewCTX.setActiveTab(0);
      }
      tabViewCTX.setCurrentID(id + 1);
      tabViewCTX.setActiveTab(id);
    });
  };

  return (
    <>
      <section className="content">
        <div
          className="bookingSummary"
          style={{
            color: darkMode ? '#f0f0f0 !important' : colors.colorGreyAdminLTE + ' !important',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <div style={{ flexDirection: 'column' }}>
                <Card className="detailCard" style={{ borderRadius: '5px', margin: '10px' }}>
                  <div
                    className="headerPanel"
                    style={{
                      backgroundColor: colors.colorPrimaryMain,
                    }}
                  >
                    Reservation
                  </div>
                  <div
                    style={{
                      backgroundColor: 'white',
                      margin: 0,
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}
                  >
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <Button
                        style={{
                          borderRadius: '5px',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}
                        icon="fa-solid fa-bed fa-lg"
                        className="marginIcon"
                        text
                        title={data?.OriginalRoomType}
                      >
                        <div>
                          {' '}
                          {data?.OriginalRoomType ? (
                            <i title={data?.OriginalRoomType}>
                              {' '}
                              {
                                <>
                                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{data?.RoomNumber}</span>{' '}
                                  {data?.RoomType}
                                </>
                              }
                            </i>
                          ) : (
                            <>
                              {' '}
                              <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{data?.RoomNumber}</span>{' '}
                              {data?.RoomType}
                            </>
                          )}
                        </div>
                      </Button>
                      <Dropdown
                        disabled
                        placeholder="Statut de réservation"
                        value={status.find((e) => e.code === data?.Statut)}
                        options={status}
                      ></Dropdown>
                    </div>
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <i className="fa-solid fa-user" style={{ color: 'black', width: '30px', marginRight: '5px' }}></i>
                      <h3
                        style={{ width: '80%', fontSize: '10pt', margin: 0, fontWeight: 'bolder' }}
                        onClick={() => openKardex(data?.IdKardex)}
                      >
                        {' '}
                        {data?.FullName}
                      </h3>
                      <CountryDropdown
                        disabled
                        searchMode="Libelle"
                        tooltip="Nationnalité"
                        style={{ width: '3.3rem' }}
                        data={dataFactoryCTX?.DataDictionnary?.Countries?.find((e) => e.Id === data?.Nationality)}
                      ></CountryDropdown>
                      <CountryDropdown
                        disabled
                        searchMode="Libelle"
                        tooltip="Culture"
                        style={{ width: '3.3rem' }}
                        data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                          (e) => e.Id === data?.CustomerCulture?.toUpperCase(),
                        )}
                      ></CountryDropdown>
                    </div>
                    {data?.Occupants && (
                      <div
                        style={{ justifyContent: 'flex-start', display: 'flex', padding: '5px', alignItems: 'center' }}
                      >
                        <i
                          className="fa-solid fa-users"
                          style={{ color: 'black', width: '24px', marginRight: '5px' }}
                        ></i>
                        <h3 style={{ width: '100%', fontSize: '10pt', margin: 0 }}>{data?.Occupants}</h3>
                      </div>
                    )}{' '}
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <span>
                        <i style={{ color: 'darkgreen', marginRight: '5px' }} className="fa-regular fa-calendar"></i>
                        <label>
                          {moment(data?.DateArrival)
                            .toDate()
                            .toLocaleDateString(userCTX.authenticationInfos.user?.Culture)}
                        </label>
                        {data?.ArrivalTime && (
                          <label
                            style={{
                              fontWeight: data?.EarlyCheckIn ? 'bold' : 'normal',
                              color: data?.EarlyCheckIn ? 'red' : 'black',
                            }}
                          >
                            {data?.ArrivalTime}
                          </label>
                        )}
                      </span>
                      <span>
                        <i style={{ color: 'darkgoldenrod', marginRight: '5px' }} className="fa-solid fa-moon"></i>
                        <label>{data?.Nights.toString()}</label>
                      </span>
                      <span>
                        <i style={{ color: 'darkred', marginRight: '5px' }} className="fa-regular fa-calendar"></i>
                        <label>
                          {moment(data?.DateDeparture)
                            .toDate()
                            .toLocaleDateString(userCTX.authenticationInfos.user?.Culture)}
                        </label>
                      </span>
                      <span>
                        <i className="fa-solid fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                        <label>{data?.Adults.toString()}</label>
                      </span>{' '}
                      {dataFactoryCTX?.PMSSettings?.ManageChildren && (
                        <span>
                          <i className="fa-solid fa-child" style={{ color: 'darksalmon', marginRight: '5px' }}></i>
                          <label>{data?.Children.toString()}</label>
                        </span>
                      )}
                      {dataFactoryCTX?.PMSSettings?.ManageBabies && (
                        <span>
                          <i className="fa-solid fa-baby" style={{ color: 'darkcyan', marginRight: '5px' }}></i>{' '}
                          <label>{data?.Babies?.toString() ?? 0}</label>
                        </span>
                      )}
                    </div>
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <span>
                        {data?.PreCheckInDone && data?.PreCheckInDoneTime && (
                          <> Pré check-in effectué le {moment(data?.PreCheckInDoneTime).format('DD/MM/YYYY à HH:mm')}</>
                        )}

                        {data?.PreCheckInDone && !data?.PreCheckInDoneTime && <> Pré check-in effectué </>}
                      </span>
                    </div>
                  </div>
                </Card>
                <Card style={{ margin: '10px', backgroundColor: '#ededed' }} className="detailCard">
                  <div
                    className="headerPanel"
                    style={{
                      backgroundColor: colors.colorSecondaryMain,
                    }}
                  >
                    Classification
                  </div>
                  <div
                    style={{
                      backgroundColor: 'white',
                      margin: 0,
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}
                  >
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <span title="Segmentation">
                        <i
                          className="fa-solid fa-chart-simple"
                          style={{ color: 'olive', width: '30px', marginRight: '10px' }}
                        ></i>
                        <Button tooltip="Segmentation" disabled text>
                          {data?.Segments}
                        </Button>
                      </span>
                    </div>
                    {dataFactoryCTX?.PMSSettings?.HasOrigines && (
                      <div
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                          padding: '5px',
                          alignItems: 'center',
                        }}
                      >
                        {data?.Origins && (
                          <span title="Origine">
                            <i
                              className="fa-solid fa-chart-pie"
                              style={{ color: 'darkkhaki', marginRight: '10px', width: '30px' }}
                            ></i>
                            <Button tooltip="Origine" disabled text>
                              {data?.Origins}
                            </Button>
                          </span>
                        )}
                      </div>
                    )}
                    <div
                      style={{ justifyContent: 'space-between', display: 'flex', padding: '5px', alignItems: 'center' }}
                    >
                      <span title="But du séjour">
                        <i className="fa-solid fa-champagne-glasses" style={{ color: 'gold', marginRight: '5px' }}></i>
                        <span style={{ marginLeft: '20px' }}>
                          {
                            dataFactoryCTX?.DataDictionnary?.BookingStayGoal?.find(
                              (e) => (e.Id as number) === (data?.StayGoal as unknown as number),
                            )?.Libelle
                          }
                        </span>
                      </span>
                    </div>
                    <div
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        padding: '5px',
                        flexDirection: 'column',
                      }}
                    >
                      {data?.BookingSources.map((e) => {
                        return (
                          <>
                            <span style={{ width: '100%' }}>{e}</span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </Card>
                {(data?.PayingDifferentThanCustomer ||
                  data?.Deposits ||
                  data?.Voucher ||
                  data.HasPayPerLinkInProgress ||
                  data.HasPreAuthorizationInProgress ||
                  data.HasPreAuthorizationDone ||
                  data.HasCreditCard ||
                  data.SaleCondition) && (
                  <Card style={{ margin: '10px', backgroundColor: '#ededed' }} className="detailCard">
                    <div
                      className="headerPanel"
                      style={{
                        backgroundColor: colors.colorSuccessMain,
                      }}
                    >
                      Paiements
                    </div>
                    <div
                      style={{
                        backgroundColor: 'white',
                        margin: 0,
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}
                    >
                      {data?.PayingDifferentThanCustomer && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <span>
                            <i className="fa-solid fa-user" style={{ color: 'black' }}></i>
                            <i
                              className="fa-solid fa-money-bill fa-rotate-90"
                              style={{ color: 'black', marginRight: '5px' }}
                            ></i>{' '}
                            {data?.PayingCustomer}
                          </span>
                        </div>
                      )}

                      {data?.Deposits > 0 && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <span>
                            <i style={{ color: 'black', marginRight: '5px' }} className="fa-solid fa-coins"></i>
                            {data?.Deposits}
                          </span>
                        </div>
                      )}
                      {data?.Voucher && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <span>
                            {' '}
                            <i style={{ color: 'black', marginRight: '5px' }} className="fa-solid fa-ticket"></i>{' '}
                            {data?.Voucher}
                          </span>
                        </div>
                      )}

                      {data.HasPayPerLinkInProgress && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <span>PayPerLink en cours : {formatPrice(data.PayPerLinkInProgressAmount, null)}</span>
                        </div>
                      )}

                      {data.HasPreAuthorizationInProgress && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <span>Demande de pré-autorisation en cours : {data.PreAuthorizationAmount}</span>
                        </div>
                      )}
                      {data.HasPreAuthorizationDone && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <span>Pré-autorisation faite : {data.PreAuthorizationAmount} </span>
                        </div>
                      )}

                      {(data.HasCreditCard || data.SaleCondition) && (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                          }}
                        >
                          {data.HasCreditCard && (
                            <Button
                              text
                              title={data.CreditCardExpiry}
                              style={{
                                marginRight: '5px',
                                fontSize: data?.CreditCard ? '12px' : '16px',
                                width: '45%',
                                textAlign: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                style={{ height: '14px', marginRight: '10px' }}
                                src={creditCard.find((e) => e.code === data.CreditCardType)?.icon}
                              ></img>
                              {data.IsVirtualCreditCard && <i className="fa-solid fa-shield-halved"></i>}
                              {!data?.CreditCard && 'Carte Bancaire'}
                            </Button>
                          )}
                          {data.SaleCondition && (
                            <span title={data.SaleConditionDetails}>
                              {' '}
                              <Icon path={mdiAirplaneRemove} size={1} />
                              {data.SaleCondition}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </Card>
                )}

                {(data.Products.length > 0 || data.RateCode) && (
                  <Card style={{ margin: '10px', backgroundColor: '#ededed' }} className="detailCard">
                    <div
                      className="headerPanel"
                      style={{
                        backgroundColor: colors.colorWarningMain,
                      }}
                    >
                      Produits
                    </div>
                    <div
                      style={{
                        backgroundColor: 'white',
                        margin: 0,
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}
                    >
                      {data.TotalStay && (
                        <div
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            padding: '5px',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <i style={{ marginRight: '5px' }} className="fas fa-money-bill"></i>{' '}
                          {formatPrice(data.TotalStay, null)}
                          {data.RateCode && (
                            <span style={{ marginLeft: '10px' }}>
                              (Tarifs <b>{data.RateCode}</b>)
                            </span>
                          )}
                        </div>
                      )}

                      <Accordion multiple style={{ width: '100%' }} activeIndex={0}>
                        {data.Products.map((e, i) => (
                          <AccordionTab tabIndex={i} key={'acordion' + i} header={dateTo_dd_MM_yyyy(new Date(e.Date))}>
                            <DataTable
                              stripedRows
                              scrollable
                              rowHover
                              className={(darkMode ? 'dark' : '') + ' previsionDT'}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'center',
                              }}
                              value={e.Items}
                            >
                              <Column
                                bodyStyle={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '10px' }}
                                header={'Libelle'}
                                field="Label"
                              ></Column>
                              <Column header={'Qt'} field="Quantite"></Column>
                              <Column
                                header={'Prix'}
                                style={{
                                  fontWeight: 'bolder',
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                bodyStyle={{ paddingRight: '5px !important' }}
                                body={(e) => (
                                  <div style={{ textAlign: 'right', marginRight: '10px', padding: 0 }}>
                                    {formatPrice(e.MontantNet, null)}
                                  </div>
                                )}
                              ></Column>
                              <Column
                                header={''}
                                body={(body) => {
                                  if (
                                    body.MontantRemises !== 0 ||
                                    body.MontantCommissions !== 0 ||
                                    body.MontantOfferts !== 0
                                  ) {
                                    return (
                                      <Button
                                        text
                                        icon="fas fa-magnifying-glass"
                                        onClick={() => {
                                          setDialogVisible(true);
                                          setProductDetail(body);
                                        }}
                                      ></Button>
                                    );
                                  }
                                }}
                              ></Column>
                            </DataTable>
                          </AccordionTab>
                        ))}
                      </Accordion>
                      {productDetail && (
                        <Dialog
                          visible={dialogVisible}
                          headerClassName="p-1"
                          headerStyle={{ backgroundColor: colors.colorGreyAdminLTE }}
                          style={{ width: '75vw', backgroundColor: 'red' }}
                          contentClassName="p-4"
                          onHide={() => {
                            if (!dialogVisible) return;
                            setDialogVisible(false);
                          }}
                        >
                          <p className="m-0">
                            {' '}
                            <DataTable
                              stripedRows
                              scrollable
                              rowHover
                              className={(darkMode ? 'dark' : '') + ' previsionDT justifyLeft'}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'center',
                              }}
                              value={[productDetail]}
                            >
                              <Column header={'Libelle'} field="Label"></Column>
                              <Column header={'Qt'} field="Quantite"></Column>
                              <Column
                                header={'Prix'}
                                style={{
                                  fontWeight: 'bolder',
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                body={(e) => formatPrice(e.MontantNet, null)}
                              ></Column>
                              <Column
                                style={{
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                header={'Montant Commissions'}
                                body={(e) => formatPrice(e.MontantCommissions, null)}
                              ></Column>
                              <Column
                                style={{
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                header={'Montant Offerts'}
                                body={(e) => formatPrice(e.MontantOfferts, null)}
                              ></Column>
                              <Column
                                style={{
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                header={'Montant Remises'}
                                body={(e) => formatPrice(e.MontantRemises, null)}
                              ></Column>
                              <Column
                                style={{
                                  justifyContent: 'right',
                                  textAlign: 'right',
                                  paddingRight: '5px !important',
                                }}
                                header={'Quantite Offerts'}
                                body={(e) => formatPrice(e.QuantiteOfferts, null)}
                              ></Column>
                            </DataTable>
                          </p>
                        </Dialog>
                      )}
                    </div>
                  </Card>
                )}
                {data.Memos.length > 0 && (
                  <Card style={{ margin: '10px', backgroundColor: '#ededed' }} className="detailCard">
                    <div
                      className="headerPanel"
                      style={{
                        backgroundColor: colors.colorPurple,
                      }}
                    >
                      Mémos
                    </div>
                    <div
                      style={{
                        backgroundColor: 'white',
                        margin: 0,
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}
                    >
                      <div
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                          padding: '5px',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Accordion multiple style={{ width: '100%' }} activeIndex={0}>
                          {data.Memos.map((e, i) => (
                            <AccordionTab
                              tabIndex={i}
                              key={'acordion' + i}
                              header={MemoType.find((t) => t.code === e.Type)?.name}
                            >
                              <p className="m-0" dangerouslySetInnerHTML={{ __html: e.Memo }}></p>
                            </AccordionTab>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </Card>
                )}
              </div>
              <Card style={{ margin: '10px', backgroundColor: '#ededed' }} className="detailCard">
                <div
                  style={{
                    backgroundColor: 'white',
                    margin: 0,
                    borderRadius: '10px',
                  }}
                >
                  <div
                    className="headerPanel"
                    style={{
                      borderRadius: '5px',
                      backgroundColor: colors.colorGreyAdminLTE,
                    }}
                  >
                    Créé par {data.Creator} le {dateTo_dd_MM_yyyy(new Date(data.Creation))} à{' '}
                    {dateToH_h_m(new Date(data.Creation))}
                  </div>
                </div>
              </Card>
            </div>
          </div>{' '}
        </div>
      </section>
    </>
  );
};

export default LightBookingSummary;
