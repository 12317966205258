import React, { useState } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Impersonate = (props: any) => {
  const { hideSubForm } = props.actions;
  const userCTX = useUser();
  const [t] = useTranslation();
  const [invalidPassword, setInvalidPassword] = useState(false);

  const impersonate = async (email: string, password: string) => {
    try {
      const loginParam: AuthParams = {
        IsTech: false,
        Login: email,
        Password: password,
        IdHotel: userCTX.authenticationInfos.selectedHotel?.IdHotel,
        PermissionId: props.data.PermissionId,
      };
      userCTX
        .Impersonate(loginParam)
        .then(() => {
          hideSubForm();
        })
        .catch((error) => {
          // Gérer les erreurs de connexion ici
          setInvalidPassword(true);
          toast.error(error?.message?.replace('1000', ' Identifiant ou mot de passe incorrect') ?? error);
        });
    } catch (e) {
      toast.error('Failed');
    }
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    impersonate(data.get('email')?.toString() || '', data.get('password')?.toString() || '');
  };
  return (
    <Grid container component="main" sx={{ height: 'calc(100vh)' }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.label.username')}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={() => setInvalidPassword(false)}
              name="password"
              label={t('login.label.password')}
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Grid container>
              <Grid item xs>
                {invalidPassword ? (
                  <Typography
                    component="p"
                    variant="body2"
                    style={{
                      color: 'red',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    {t('login.label.invalid.password')}
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('login.label.validate')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Impersonate;
