import { ReactNode } from 'react';

import { useHotel } from '@app/modules/hotel/HotelContext';
import { useUser } from '@app/modules/user/UserContext';
import colors from '@app/styles/colors';
import { Button } from 'primereact/button';

import { Divider, Typography } from '@mui/material';

interface props {
  title: ReactNode;
  showTitle: boolean;
}
const MemoReminder: React.FC<props> = ({ title, showTitle }) => {
  const hotelCTX = useHotel();
  const userCTX = useUser();
  const handleCloseMemo = (e: MemoReminders) => {
    hotelCTX.closeMemoReminders({
      params: {
        selectedHotel: userCTX.authenticationInfos.selectedHotel,
      },
      memo: e,
    });
  };
  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
      }}
    >
      {showTitle && (
        <>
          <Typography
            component="h1"
            variant="h6"
            sx={{ padding: 2, fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            {title}
          </Typography>

          <Divider variant="middle" />

          <div
            style={{
              flexDirection: 'row',
              maxHeight: '475px',
              overflow: 'auto',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {hotelCTX?.MemoReminders?.map((e, i) => {
              return (
                <div
                  style={{
                    backgroundColor: i % 3 === 0 ? '#efefef' : 'white',
                    borderRadius: '4px',
                    padding: '5px',
                    margin: '5px',
                    width: '45%',
                  }}
                  key={i}
                >
                  <b> {e.RoomNumber}</b>
                  <span> {e.DisplayName}</span>
                  <i
                    style={{ float: 'right', right: '0' }}
                    className={
                      e.MemoType === 'FrontOffice'
                        ? 'fa-regular fa-building'
                        : e.MemoType === 'HouseKeeping'
                          ? 'fa-solid fa-broom'
                          : e.MemoType === 'Catering'
                            ? 'fa-solid fa-bowl-food'
                            : e.MemoType === 'Concierge'
                              ? 'fa-solid fa-bell-concierge'
                              : e.MemoType === 'Reservation'
                                ? 'fa-solid fa-book'
                                : e.MemoType === 'Maintenance'
                                  ? 'fa-solid fa-screwdriver-wrench'
                                  : e.MemoType === 'Free1'
                                    ? 'fa-solid fa-comment'
                                    : e.MemoType === 'Free2'
                                      ? 'fa-regular fa-comments'
                                      : e.MemoType === 'Free3'
                                        ? 'fa-solid fa-comment-dots'
                                        : e.MemoType === 'PreCheckInCustomerMemo'
                                          ? 'fa-solid fa-plane-departure'
                                          : ''
                    }
                  />
                  <Divider variant="fullWidth" />

                  <span style={{ color: !e.Done ? colors.colorErrorMain : colors.colorGrey800 }}>{e.Memo}</span>
                  <Divider variant="fullWidth" />
                  {!e.Done && (
                    <Button
                      style={{
                        float: 'right',
                        borderRadius: '4px',
                        width: '24px',
                        height: '24px',
                        backgroundColor: 'green',
                      }}
                      icon="pi pi-check"
                      onClick={() => handleCloseMemo(e)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default MemoReminder;
