import React, { useEffect, useState } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { PfDropdown } from '@profabric/react-components';
import { addLocale } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 10rem;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

export interface Language {
  key: string;
  icon: string;
  label: string;
}

const languages: Language[] = [
  {
    key: 'en',
    icon: 'flag-icon-us',
    label: 'header.language.english',
  },

  {
    key: 'de',
    icon: 'flag-icon-de',
    label: 'header.language.german',
  },
  {
    key: 'fr',
    icon: 'flag-icon-fr',
    label: 'header.language.french',
  },
  {
    key: 'es',
    icon: 'flag-icon-es',
    label: 'header.language.spanish',
  } /*,
  {
    key: 'cn',
    icon: 'flag-icon-cn',
    label: 'header.language.chinese',
  },
  {
    key: 'ma',
    icon: 'flag-icon-ma',
    label: 'header.language.moroccan',
  },*/,
];

const LanguagesDropdown = () => {
  const userCTX = useUser();

  const userLanguage = userCTX.authenticationInfos.language ?? '';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    changeLanguage(userLanguage === '' ? 'fr' : userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    addLocale(lng, {
      firstDayOfWeek: 1,
      dayNames: [
        t('calendar.dayNames.0'),
        t('calendar.dayNames.1'),
        t('calendar.dayNames.2'),
        t('calendar.dayNames.3'),
        t('calendar.dayNames.4'),
        t('calendar.dayNames.5'),
        t('calendar.dayNames.6'),
      ],
      dayNamesShort: [
        t('calendar.dayNamesShort.0'),
        t('calendar.dayNamesShort.1'),
        t('calendar.dayNamesShort.2'),

        t('calendar.dayNamesShort.3'),

        t('calendar.dayNamesShort.4'),

        t('calendar.dayNamesShort.5'),

        t('calendar.dayNamesShort.6'),
      ],
      dayNamesMin: [
        t('calendar.dayNamesMin.0'),
        t('calendar.dayNamesMin.1'),
        t('calendar.dayNamesMin.2'),
        t('calendar.dayNamesMin.3'),
        t('calendar.dayNamesMin.4'),
        t('calendar.dayNamesMin.5'),
        t('calendar.dayNamesMin.6'),
      ],
      monthNames: [
        t('calendar.monthNames.0'),
        t('calendar.monthNames.1'),
        t('calendar.monthNames.2'),
        t('calendar.monthNames.3'),
        t('calendar.monthNames.4'),
        t('calendar.monthNames.5'),
        t('calendar.monthNames.6'),
        t('calendar.monthNames.7'),
        t('calendar.monthNames.8'),
        t('calendar.monthNames.9'),
        t('calendar.monthNames.10'),
        t('calendar.monthNames.11'),
      ],
      monthNamesShort: [
        t('calendar.monthNamesShort.0'),
        t('calendar.monthNamesShort.1'),
        t('calendar.monthNamesShort.2'),
        t('calendar.monthNamesShort.3'),
        t('calendar.monthNamesShort.4'),
        t('calendar.monthNamesShort.5'),
        t('calendar.monthNamesShort.6'),
        t('calendar.monthNamesShort.7'),
        t('calendar.monthNamesShort.8'),
        t('calendar.monthNamesShort.9'),
        t('calendar.monthNamesShort.10'),
        t('calendar.monthNamesShort.11'),
      ],
      // eslint-disable-next-line quotes
      today: t('calendar.today'),
      weekHeader: t('calendar.weekHeader'),

      clear: t('calendar.clear'),
    });

    // dispatch(setLanguage(lng));
    userCTX.setUser((prevState) => ({
      ...prevState,
      language: lng,
    }));
  };

  const getCurrentLanguage = (): Language => {
    const currentLanguage = userLanguage; //i18n.language;
    if (currentLanguage) {
      const language = languages.find((language: Language) => language.key === currentLanguage);
      return language || languages[0];
    }
    return languages[0];
  };

  const isActiveLanguage = (language: Language) => {
    if (language) {
      return getCurrentLanguage().key === language.key ? 'active' : '';
    }
    return '';
  };

  return (
    <StyledDropdown isOpen={dropdownOpen} hideArrow>
      <div className="nav-link" slot="button">
        <i className={`flag-icon ${getCurrentLanguage().icon}`} />
      </div>
      <div slot="menu">
        {languages.map((language) => (
          <button
            type="button"
            key={language.key}
            className={`dropdown-item ${isActiveLanguage(language)}`}
            onClick={() => {
              changeLanguage(language.key);
              setDropdownOpen(false);
            }}
          >
            <i className={`flag-icon ${language.icon} mr-2`} />
            <span>{t(language.label)}</span>
          </button>
        ))}
      </div>
    </StyledDropdown>
  );
};

export default LanguagesDropdown;
