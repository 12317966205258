import React, { memo } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { PlanningLine } from '@app/types/types';
import { mdiCashMultiple, mdiCreditCardClock } from '@mdi/js';
import Icon from '@mdi/react';
import { MbscCalendarEventData } from '@mobiscroll/react';
import moment from 'moment-timezone';
import { ContextMenu } from 'primereact/contextmenu';
import { useSelector } from 'react-redux';

import handClick from '@assets/handClick.svg';

const Reservation = memo(
  ({
    data,
    PlanningDate,
  }: {
    data: MbscCalendarEventData;
    PlanningDate: string | undefined; // Replace 'Date | string' with your actual type
  }) => {
    const filtersCTX = useFilters();
    const ev = data.original as PlanningLine; // Replace 'PlanningLine' with your actual type
    const subFormCTX = useSubForm();
    const cm = subFormCTX.cmReservaRef;
    const darkMode = useSelector((state: RootState) => state.ui.darkMode);

    const items = [
      { label: 'Modifier', icon: 'fa-regular fa-pen-to-square' },
      {
        separator: true,
      },
      { label: 'Option', icon: 'fa-regular fa-circle-question' },
      { label: 'Supprimer', icon: 'fa-solid fa-trash-can custom-icon-red', style: { color: 'red' } },
      {
        separator: true,
      },
      { label: 'Copier', icon: 'fa-solid fa-copy' },
      { label: 'Fragmenter', icon: 'fa-solid fa-object-ungroup' },
      { label: 'Bloquer', icon: 'fa-solid fa-lock custom-icon-red' },
      {
        separator: true,
      },
      { label: 'Facture Proforma', icon: 'fa-solid fa-file-invoice' },
      { label: 'Annuler le Pré Check-in', icon: 'fa-solid fa-eject' },
      { label: 'Groupe', icon: 'fa-solid fa-people-group' },
      {
        separator: true,
      },
      { label: 'Historique', icon: 'fa-solid fa-clock-rotate-left' },
      { label: 'Confirmation', icon: 'fa-regular fa-envelope' },
    ];
    if (ev !== undefined) {
      //Dessin des totaux avec valeurs
      if (ev?.totalType) {
        return (
          <div
            style={{
              cursor: 'default',
              width: 'calc(100% - 1px)',
              height: '28px',
              backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey300,
              color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
              fontSize: '10pt',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #ccc',
            }}
          >
            {ev.DisplayName !== '0' && ev.DisplayName !== '' && (
              <div
                style={{
                  cursor: 'default',
                  textAlign: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  backgroundColor: ev.foreground,
                  border: '1px solid black',
                  width: '22px',
                  height: '22px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {ev.DisplayName}
              </div>
            )}
          </div>
        );
      }
      //dessin des evenements reservations
      return (
        <>
          {false && <ContextMenu style={{ width: '250px', fontSize: '12pt' }} model={items} ref={cm} />}

          <div
            //  onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
            style={{
              borderRadius: '4px',
              border: '2px solid #333',
              height: '22px',
              margin: '1px',
              marginTop: '3px',
              background: ev.BackgroundEvent,
              color: ev.ForegroundEvent,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                borderTop:
                  '3px solid' + moment(ev.End).format('YYYYMMDD') ===
                  moment(new Date(PlanningDate as string))
                    .add(1, 'day')
                    .format('YYYYMMDD')
                    ? ev.ForegroundEvent
                    : ev.BackgroundEvent,
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {ev.Status === 'D' && (
                  <span>
                    <i className="fa-solid fa-plane-departure" style={{ color: 'darkgreen' }}></i>
                  </span>
                )}
                {ev.IsOutOfOrder === true && (
                  <i
                    style={{ color: 'red', marginLeft: '5px', marginRight: '5px', width: '16px' }}
                    className="fas fa-exclamation-triangle"
                  ></i>
                )}

                {ev.GuaranteeStatus === 1 && ev.PastilleDisplayed && (
                  <Icon
                    style={{ color: ev.CouleurEvent, marginLeft: '2px', marginRight: '2px', marginTop: '-7px' }}
                    path={mdiCashMultiple}
                    size={0.7}
                  />
                )}
                {ev.GuaranteeStatus === 2 && ev.PastilleDisplayed && (
                  <Icon
                    style={{ color: ev.CouleurEvent, marginLeft: '2px', marginRight: '2px', marginTop: '-7px' }}
                    path={mdiCreditCardClock}
                    size={0.7}
                  />
                )}
                {ev.GuaranteeStatus === 3 && ev.PastilleDisplayed && (
                  <i
                    style={{ color: ev.CouleurEvent, marginLeft: '5px', marginRight: '5px', width: '16px' }}
                    className="fas fa-credit-card"
                  ></i>
                )}
                {ev.GuaranteeStatus === 4 && ev.PastilleDisplayed && (
                  <i
                    style={{ color: ev.CouleurEvent, marginLeft: '5px', marginRight: '5px', width: '16px' }}
                    className="fas fa-circle"
                  ></i>
                )}
                {ev.GuaranteeStatus === 6 && ev.PastilleDisplayed && (
                  <img
                    src={handClick}
                    style={{
                      color: ev.CouleurEvent,
                      marginLeft: '5px',
                      marginRight: '5px',
                      width: '16px',
                      marginTop: '-5px',
                    }}
                  />
                )}
                {!filtersCTX.filters.planningFilters.ForceOneDay && (
                  <span
                    style={{
                      zIndex: 10,
                      marginLeft: ev.PastilleDisplayed === false ? '10px' : '',
                      position: 'relative',
                      color: ev.ForegroundEvent,
                      fontWeight: ev.IsTextBold ? 'bolder' : 'normal',
                      fontSize: '15px',
                    }}
                  >
                    {ev.DisplayName}
                  </span>
                )}
                {filtersCTX.filters.planningFilters.ForceOneDay && (
                  <>
                    <span
                      style={{
                        zIndex: 10,

                        position: 'relative',
                        fontWeight: 'bolder',
                        width: '300px',
                        borderRight: '1px solid black',
                        display: 'inline-block',
                        lineHeight: '18px',
                      }}
                    >
                      {ev.DisplayName}
                    </span>
                    <span
                      style={{
                        fontWeight: 'bolder',
                        borderRight: '1px solid black',
                        textAlign: 'center',
                        width: 'auto',
                        display: 'inline-block',

                        padding: '10px',
                      }}
                    >
                      {ev?.Memo?.replace('<br/>', '')}
                    </span>
                  </>
                )}
                {ev.IsGroup === true && (
                  <i
                    style={{ color: 'red', position: 'absolute', right: '10px', zIndex: 0, top: '8px' }}
                    className="fas fa-users"
                  ></i>
                )}
                {ev.Bloque === true && (
                  <i
                    style={{
                      color: 'darkorange',
                      position: 'absolute',
                      right: ev.IsGroup === true ? '35px' : '10px',
                      zIndex: 10,
                      top: '8px',
                    }}
                    className="fas fa-lock"
                  ></i>
                )}
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  },
  (prevProps, nextProps) => {
    return prevProps.PlanningDate === nextProps.PlanningDate && prevProps.data === nextProps.data;
  },
);

Reservation.displayName = 'Reservation';

export default Reservation;
