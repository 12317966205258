import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Divider, Typography } from '@mui/material';

import colors from '@styles/colors';

const TodayNewBookingDataTable: React.FC = () => {
  const dashboardCTX = useDashboard();
  const [t] = useTranslation();
  const gridData = dashboardCTX.TodayNewBooking;
  const userCTX = useUser();
  const formatHeader = (dateToFormat: string) => {
    if (dateToFormat === '2000-01-01T00:00:00') return 'TOTAL';
    if (dateToFormat === '2200-01-01T00:00:00') return 'Futur';

    return new Date(dateToFormat).toLocaleDateString(userCTX.authenticationInfos.language, {
      year: 'numeric',
      month: 'short',
    });
  };
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  return (
    <div className={`small-box bg-gradient-light`}>
      <div className="small-box" style={{ borderRadius: '8px' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{
            padding: 2,
            fontSize: 17,
            fontWeight: 'bolder',
            textTransform: 'uppercase',
          }}
        >
          {t('smallbox.label.TodaysReservations')}
        </Typography>

        <Divider variant="middle" />
        <DataTable
          className="normalDT"
          value={gridData?.Data}
          paginator={true}
          stripedRows
          reorderableRows
          rows={15}
          rowsPerPageOptions={[5, 10, 15, 50]}
          scrollable
          rowHover
        >
          <Column
            key={'KeyIterator'}
            field={'Name'}
            header=""
            body={(e) => <div style={{ paddingLeft: '10px' }}> {e.Name}</div>}
            headerStyle={{ color: colors.colorGreyAdminLTE }}
            frozen
            className="font-bold"
            style={{
              width: '100%',
              backgroundColor: darkMode ? '' : colors.colorGrey100,
              color: darkMode ? colors.colorGrey100 : colors.colorGreyAdminLTE,
              whiteSpace: 'nowrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              justifyContent: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'flex',
              lineHeight: '25px',
            }}
          ></Column>
          {gridData?.Columns.map((e) => {
            return (
              <Column
                key={'KeyIterator'}
                field={`Data.${e.KeyIterator}.Value`}
                header={formatHeader(gridData.Columns.find((t) => t.KeyIterator === e.KeyIterator)?.Value || '')}
                className=""
                style={{
                  alignContent: 'center',
                  textAlign: 'center',
                  width: '110px',
                  overflow: 'hidden',
                }}
              ></Column>
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default TodayNewBookingDataTable;
