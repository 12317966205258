import PrevisionFilters from '@app/components/Common/filter/PrevisionFilters';
import PrevisionDatatable from '@app/components/Desktop/Table/previsionDatatable/PrevisionDatatable';
import { PrevisionProvider } from '@app/modules/prevision/PrevisionContext';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const Prevision: React.FC = () => {
  const { orientation } = useMobileOrientation();

  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        {(isMobile || isTablet) && (
          <div
            className={`small-box bg-gradient-gray-dark`}
            style={{ width: orientation === 'portrait' ? '100%' : '50%' }}
          >
            <div className="inner">
              <PrevisionFilters></PrevisionFilters>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12 col-12">
            <PrevisionProvider>
              <PrevisionDatatable></PrevisionDatatable>
            </PrevisionProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prevision;
