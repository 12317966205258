/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Draggable from 'react-draggable'; // The default
import { useSelector } from 'react-redux';

interface BaseFormProps {
  id: number;
  component: React.ComponentType<any>;
  data: any;
  actions?: any;
  translatedTitle: JSX.Element | string;
  draggable: boolean;
  fullWidth: boolean;
  fullHeight: boolean;
  maxHeight?: string;
  x?: number | string;
  y?: number | string;
  width?: string | number;
  height?: string | number;
  tabMode?: boolean;
  border?: string;
  zIndex?: number;
  opacity?: number;
  classToAdd?: string;
  dataIndex?: number;
  // You might want to specify a more specific type here
  // ... (any other props)
}

const BaseForm: React.FC<BaseFormProps> = ({
  id,
  component,
  data,
  actions,
  translatedTitle,
  draggable,
  fullWidth,
  fullHeight,
  width,
  height,
  border,
  x,
  y,
  tabMode,
  zIndex,
  opacity,
  classToAdd,
}) => {
  // Component to display can come from Redux for Main form or from Props in Route for subforms
  // console.log("component from baseform", ComponentToDisplay);
  const ComponentToDisplay = component;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const menuSidebarCollapsed = useSelector((state: RootState) => state.ui.menuSidebarCollapsed);
  return (
    <Draggable handle={draggable ? '.handleTop' : 'null'}>
      <div
        // eslint-disable-next-line max-len
        className={`${fullWidth ? ' fullWidth ' : ' modalPopup '} ${isMobileOnly ? ' mobile ' : ''} ${menuSidebarCollapsed ? '' : 'openedMenu'} ${tabMode ? ' tabMode ' : ''}  ${fullHeight ? ' fullHeight ' : ''}${
          draggable ? 'DraggableDiv' : 'NotDraggableDiv'
        } ${classToAdd ?? ''}`}
        key="draggable"
        style={{
          marginLeft: isMobileOnly ? undefined : x,
          marginTop: y,
          width: isMobileOnly ? undefined : width,
          height: isMobileOnly ? undefined : height,
          border: border,
          zIndex: zIndex,
          opacity: opacity,
        }}
      >
        <div style={{ zIndex: 5, height: '100%' }}>
          {!tabMode && (
            <>
              <div className="handleTop">
                <div
                  className="BorderTop"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: isMobile ? 0 : 'inherit',
                    backgroundColor: darkMode ? colors.colorPrimaryLight : 'rgb(52, 58, 64)',
                    color: '#f0f1f0',
                  }}
                >
                  <span style={{ marginLeft: '20px' }}>{translatedTitle}</span>
                </div>
              </div>
              <div
                className="closingButton"
                onClick={() => {
                  actions.hideSubForm(id);
                }}
              >
                <a id="aa" href="#" className="close"></a>
              </div>
            </>
          )}
          <div
            className={`${tabMode ? 'tabModeForm ' : ''}` + ' gen_insideForm '}
            style={{ height: isMobile ? 'calc(100% )' : 'inherit' }}
          >
            <ComponentToDisplay data={data} actions={actions} tabMode={tabMode} title={translatedTitle} />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default BaseForm;
