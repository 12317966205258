/**
 * Package import
 */
import React from 'react';

import Logo from '@assets/logo.png';

// import "./style.css";
import './style.css';

/**
 * Local import
 */

/**
 * Component
 */
const Loader = () => {
  return (
    <div>
      <div className="Error" id="ErrorZone" />
      <div className="loader loader--style3 load_loadingContainer">
        <div className="preloader flex-column justify-content-center align-items-center">
          <img className="animation__shake" src={Logo} alt="Webdispo" height={55} width={55} />
        </div>
      </div>
      {/* {displayLoadingMessage && generalLoadingMessage} */}
    </div>
  );
};

export default Loader;
