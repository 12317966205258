import { ReactNode } from 'react';

import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { Stay } from '@app/types/Hotel/stay';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Divider, Typography } from '@mui/material';

interface props {
  data: { Arrivals: Stay[]; Departures: Stay[]; InHouse: Stay[] };

  title: ReactNode;
  showTitle: boolean;
}
const CheckInChart: React.FC<props> = ({ data, title, showTitle }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
      }}
    >
      {showTitle && (
        <>
          <Typography component="h1" variant="h6" sx={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>
            {title}
          </Typography>

          <Divider variant="middle" />
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              <Typography component="h5" variant="h6" sx={{ padding: 2, fontSize: '10pt', fontWeight: 'bold' }}>
                <i className="fa-solid fa-plane-arrival" style={{ color: '#1f512e' }}></i> {t('labels.arrivals')}
              </Typography>
            </div>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              <Typography component="h5" variant="h6" sx={{ padding: 2, fontSize: '10pt', fontWeight: 'bold' }}>
                <i className="fa-solid fa-plane-departure" style={{ color: 'darkred' }}></i> {t('labels.departures')}
              </Typography>
            </div>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              <Typography component="h5" variant="h6" sx={{ padding: 2, fontSize: '10pt', fontWeight: 'bold' }}>
                <i className="fa-solid fa-person-shelter" style={{ color: colors.colorPrimaryDark }}></i>{' '}
                {t('labels.inhouse')}
              </Typography>
            </div>
          </div>
          <div style={{ flexDirection: 'row', maxHeight: '475px', overflow: 'auto', width: '100%', display: 'flex' }}>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              {data.Arrivals.map((e, i) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        i % 2 === 0 ? (darkMode ? '#454d55' : '#efefef') : darkMode ? '#202020' : 'white',
                      borderRadius: '4px',
                      padding: '5px',
                      margin: '5px',
                    }}
                    key={i}
                  >
                    <div style={{ flexDirection: 'row', textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                      <Button
                        style={{
                          bottom: '0',
                          marginRight: '0px',
                          marginLeft: '0px',
                          borderRadius: '4px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          height: '32px',
                          width: '32px',
                          backgroundColor: '#1f512e',
                          borderColor: '#666',
                        }}
                        onClick={() => {
                          //do nothing
                        }}
                        icon="fa-solid fa-right-to-bracket"
                      ></Button>

                      <div
                        style={{
                          color: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain,
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          marginLeft: '10px',
                        }}
                      >
                        {e.DisplayName}
                      </div>
                    </div>
                    <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                      #{e.RoomNumber} {e.RoomType}
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              {data.Departures.map((e, i) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        i % 2 === 0 ? (darkMode ? '#454d55' : '#efefef') : darkMode ? '#202020' : 'white',

                      borderRadius: '4px',
                      padding: '5px',
                      margin: '5px',
                    }}
                    key={i}
                  >
                    <div style={{ flexDirection: 'row', textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                      <Button
                        style={{
                          bottom: '0',
                          marginRight: '0px',
                          marginLeft: '0px',
                          borderRadius: '4px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          height: '32px',
                          width: '32px',
                          backgroundColor: 'darkred',
                          borderColor: '#666',
                        }}
                        onClick={() => {
                          //do nothing
                        }}
                        icon="fa-solid fa-right-from-bracket"
                      ></Button>

                      <div
                        style={{
                          color: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain,
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          marginLeft: '10px',
                        }}
                      >
                        {e.DisplayName}
                      </div>
                    </div>
                    <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                      #{e.RoomNumber} {e.RoomType}
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: 'calc(100% / 3)', padding: '5px' }}>
              {data.InHouse.map((e, i) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        i % 2 === 0 ? (darkMode ? '#454d55' : '#efefef') : darkMode ? '#202020' : 'white',

                      borderRadius: '4',
                      padding: '5px',
                      margin: '5px',
                    }}
                    key={i}
                  >
                    <div style={{ flexDirection: 'row', textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                      <div
                        style={{
                          color: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain,
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {e.DisplayName}
                      </div>
                    </div>
                    <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                      #{e.RoomNumber} {e.RoomType}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckInChart;
