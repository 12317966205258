import loglevel from 'loglevel';

import { isLevelDisabled } from './logger.utils';
import { LoggerFactoryOptions } from './types';

const rawMethodFactory = loglevel.methodFactory;

export function consoleFactory({
  options,
  methodName,
  methodLevel,
  level,
  loggerName,
}: LoggerFactoryOptions): loglevel.LoggingMethod {
  if (isLevelDisabled(methodLevel, options.console.level)) {
    return () => {
      //do nothing
    };
  }
  const rawLoggingMethod = rawMethodFactory(methodName, level, loggerName);

  return (...messages) => {
    let style = '';
    switch (methodName) {
      case 'trace':
        style = 'color: grey;font-weight: bold;';
        break;
      case 'debug':
        style = 'color: lightgreen;font-weight: bold;';
        break;
      case 'info':
        style = 'color: orange;font-weight: bold;';
        break;
      case 'warn':
        style = 'color: darkred;font-weight: bold;';
        break;
      case 'error':
        style = 'color: red; font-weight: bold;';
        break;
      default:
        style = '';
    }

    rawLoggingMethod(`%c[${loggerName.toString()}]`, style, ...messages);
  };
}
