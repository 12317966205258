/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import BarChart from '@app/components/Common/charts/BarChart';
import LineChart from '@app/components/Common/charts/LineChart';
import { AnalytiqueProvider, useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import colors from '@app/styles/colors';
import { analyticsKey, stackedComponent } from '@app/types/types';
import { shortDateToLocaleStringWithoutYear, shortOnlyMonthDateToLocaleString } from '@app/utils/dates';
import { ChartOptions } from 'chart.js';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import PerformanceDetail from './PerformanceDetail';

import 'chart.js/auto';

const Performance: React.FC = () => {
  const [t] = useTranslation();
  const [stackedComponent, setStackedComponent] = useState<stackedComponent | null>(null);

  const AnalyticsData: analyticsKey[] = [
    { name: t('smallbox.label.TO'), code: 0, value: 'OccupancyRate' },

    { name: t('smallbox.label.PM'), code: 1, value: 'Adr' },

    { name: t('smallbox.label.RevPar'), code: 2, value: 'RevPar' },

    { name: t('smallbox.label.RoomRevenue'), code: 3, value: 'AccomodationTurnOver' },

    { name: t('smallbox.label.GlobalRevenue'), code: 4, value: 'TurnOver' },

    { name: t('smallbox.label.ALOS'), code: 5, value: 'Alos' },
    { name: t('prevision.label.PMH'), code: 6, value: 'RevPor' },
    { name: t('header.label.occupancy'), code: 7, value: 'RoomsSoldQty' },
    { name: t('labels.arrivals'), code: 8, value: 'ArrivalsQty' },
    { name: t('labels.departures'), code: 9, value: 'DeparturesQty' },

    { name: t('labels.outoforder'), code: 10, value: 'OutOfOrder' },
    { name: t('labels.TrevPar'), code: 11, value: 'TrevPar' },
    { name: t('labels.RoomsSetQty'), code: 12, value: 'RoomsSetQty' },
    { name: t('labels.Alt'), code: 13, value: 'Alt' },
    { name: t('labels.Arg'), code: 14, value: 'Arg' },
    { name: t('labels.TRevPe'), code: 15, value: 'TRevPe' },
    { name: t('labels.CancellationRate'), code: 16, value: 'CancellationRate' },
  ];
  const { orientation } = useMobileOrientation();
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const filtersCTX = useFilters();
  const analyticsCtx = useAnalytics();
  const subFormCTX = useSubForm();
  const userCTX = useUser();
  const performanceData = analyticsCtx.AnalyticsPerformance;
  const mergeOptions: ChartOptions<'line' | 'bar'> = {};
  const [mergeOptions2, setMergeOptions2] = useState<ChartOptions<'line' | 'bar'>>({});

  useEffect(() => {
    return () => {
      subFormCTX.hideSubForm(15000);
    };
  }, []);

  useEffect(() => {
    if (filtersCTX.filters.performanceFilters.needReload === true) {
      analyticsCtx.getAnalyticsPerformance(filtersCTX.filters.performanceFilters);
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        performanceFilters: {
          ...prevState.performanceFilters,
          needReload: false,
        },
      }));
    }
  }, [filtersCTX.filters.performanceFilters]);

  useEffect(() => {
    if (stackedComponent?.from?.code !== undefined && stackedComponent?.to?.code !== undefined) {
      const newOptions: ChartOptions<'line' | 'bar'> = {
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: AnalyticsData.find((e) => e.code === stackedComponent.from?.code)?.name,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: AnalyticsData.find((e) => e.code === stackedComponent.to?.code)?.name,
            },
            grid: {
              drawOnChartArea: false, // Cela empêche l'affichage de la grille de cet axe sur l'aire du graphique
            },
          },
        },
      };

      setMergeOptions2(newOptions);
    }
  }, [stackedComponent]);

  useEffect(() => {
    if (performanceData) {
      setMergeOptions2({
        plugins: {
          tooltip: {
            callbacks: {
              // Modifier le label du tooltip pour inclure des informations sur les ticks
              title: (tooltipItems) => {
                const tooltipItem = tooltipItems[0];
                const label = tooltipItem.label;
                let extraInfo = '';
                performanceData?.Events.map((t) =>
                  t.Data.map(
                    (date) =>
                      (extraInfo = moment(tooltipItem.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? `\n${t.ShortName + ' : ' + date.Description}`
                        : ''),
                  ),
                );

                return `${label} ${extraInfo}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: (context) => {
                // Colorer les labels après '3 Jan'
                let color = '';
                performanceData?.Events.map((t) =>
                  t.Data.map((date) => {
                    color = moment(context.tick.label).isBetween(
                      date.Dates.DateStart,
                      date.Dates.DateEnd,
                      undefined,
                      '[]',
                    )
                      ? (color = date.Background)
                      : '';
                  }),
                );
                return color;
              },
              textStrokeColor: (context) => {
                // Colorer les labels après '3 Jan'
                let color = '';
                performanceData?.Events.map((t) =>
                  t.Data.map((date) => {
                    color = moment(context.tick.label).isBetween(
                      date.Dates.DateStart,
                      date.Dates.DateEnd,
                      undefined,
                      '[]',
                    )
                      ? (color = date.Background)
                      : '';
                  }),
                );
                return color;
              },
            },
          },
        },
      });
    }
  }, [performanceData]);

  const handleDragStart = (e: any, data: any) => {
    // You can store any relevant data using the dataTransfer object
    e.dataTransfer.setData('text', JSON.stringify(data));
  };

  const openModale = (id: number) => {
    const idModal = 15000;
    const newSubForm = {
      id: idModal, // ou utilisez un ID unique
      component: PerformanceDetail, // Remplacez par le composant que vous souhaitez afficher

      data: {
        dataIndex: id,
        originalData: performanceData,
        mergeOptions: {
          plugins: {
            tooltip: {
              callbacks: {
                // Modifier le label du tooltip pour inclure des informations sur les ticks
                title: (tooltipItems: any) => {
                  const tooltipItem = tooltipItems[0];
                  const label = tooltipItem.label;
                  let extraInfo = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map(
                      (date) =>
                        (extraInfo = moment(tooltipItem.label).isBetween(
                          date.Dates.DateStart,
                          date.Dates.DateEnd,
                          undefined,
                          '[]',
                        )
                          ? `\n${t.ShortName + ' : ' + date.Description}`
                          : ''),
                    ),
                  );

                  return `${label} ${extraInfo}`;
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: (context: any) => {
                  // Colorer les labels après '3 Jan'
                  let color = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map((date) => {
                      color = moment(context.tick.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? (color = date.Background)
                        : '';
                    }),
                  );
                  return color;
                },
                textStrokeColor: (context: any) => {
                  // Colorer les labels après '3 Jan'
                  let color = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map((date) => {
                      color = moment(context.tick.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? (color = date.Background)
                        : '';
                    }),
                  );
                  return color;
                },
              },
            },
          },
        },
        stackedComponent: stackedComponent,
        data: {
          datasets: [
            {
              label: AnalyticsData.find((e) => e.code === id)?.name,
              data: performanceData?.Data.map(
                (t) => t.Current[AnalyticsData?.find((e) => e.code === id)?.value as keyof typeof t.Current],
              ),
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
            },
            {
              label:
                AnalyticsData.find((e) => e.code === id)?.name +
                ' en ' +
                filtersCTX.filters.performanceFilters.YearToCompare,
              data: performanceData?.Data.map(
                (t) => t.Previous[AnalyticsData?.find((e) => e.code === id)?.value as keyof typeof t.Previous],
              ),
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
            },
          ],
          labels: performanceData?.Data.map((t) =>
            filtersCTX.filters.performanceFilters.GroupBy === 0 || filtersCTX.filters.performanceFilters.GroupBy === 3
              ? shortDateToLocaleStringWithoutYear(
                  moment(t.Label).toDate(),
                  userCTX.authenticationInfos.language ?? 'fr',
                )
              : filtersCTX.filters.performanceFilters.GroupBy === 2
                ? shortOnlyMonthDateToLocaleString(
                    moment(t.Label).toDate(),
                    userCTX.authenticationInfos.language ?? 'fr',
                  )
                : t.Label,
          ),
        },
      }, // Les données que vous souhaitez transmettre au composant
      actions: {
        hideSubForm: () => {
          subFormCTX.hideSubForm(idModal);
        },
      },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: '',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text');
    const chartData = JSON.parse(data);
    console.log('Data dropped:', chartData, hoveredId);
    if (
      performanceData &&
      chartData !== hoveredId &&
      AnalyticsData &&
      hoveredId !== null &&
      hoveredId >= 0 === true &&
      AnalyticsData?.find((e) => e.code === chartData)?.value !== undefined
    ) {
      setStackedComponent({
        ...stackedComponent,
        from: {
          datasets: [
            {
              label: AnalyticsData.find((e) => e.code === chartData)?.name ?? '',
              type: chartData === 3 ? 'bar' : chartData === 4 ? 'bar' : 'line',
              backgroundColor: 'red',
              data: performanceData?.Data.map((t) => {
                const entry = AnalyticsData?.find((e) => e.code === chartData);
                if (entry && entry.value !== undefined) {
                  return t.Current[entry.value as keyof typeof t.Current];
                } else {
                  return 0;
                }
              }),
            },
            {
              type: chartData === 3 ? 'bar' : chartData === 4 ? 'bar' : 'line',
              label:
                AnalyticsData.find((e) => e.code === chartData)?.name +
                ' en ' +
                filtersCTX.filters.performanceFilters.YearToCompare,
              data: performanceData.Data.map((t) => {
                const entry = AnalyticsData?.find((e) => e.code === chartData);
                if (entry && entry.value !== undefined) {
                  return t.Previous[entry.value as keyof typeof t.Previous];
                } else {
                  return 0;
                }
              }),
            },
          ],
          labels: performanceData.Data.map((t) => t.Label),
          code: chartData,
        },
        to: {
          datasets: [
            {
              label: AnalyticsData.find((e) => e.code === hoveredId)?.name ?? '',
              type: hoveredId === 3 ? 'bar' : hoveredId === 4 ? 'bar' : 'line',

              data: performanceData.Data.map((t) => {
                const entry = AnalyticsData?.find((e) => e.code === hoveredId);
                if (entry && entry.value !== undefined) {
                  return t.Current[entry.value as keyof typeof t.Current];
                } else {
                  return 0;
                }
              }),
            },
            {
              label:
                AnalyticsData.find((e) => e.code === hoveredId)?.name +
                ' en ' +
                filtersCTX.filters.performanceFilters.YearToCompare,
              type: hoveredId === 3 ? 'bar' : hoveredId === 4 ? 'bar' : 'line',

              data: performanceData.Data.map((t) => {
                const entry = AnalyticsData?.find((e) => e.code === hoveredId);
                if (entry && entry.value !== undefined) {
                  return t.Previous[entry.value as keyof typeof t.Previous];
                } else {
                  return 0;
                }
              }),
            },
          ],
          labels: performanceData.Data.map((t) =>
            filtersCTX.filters.performanceFilters.GroupBy === 0 || filtersCTX.filters.performanceFilters.GroupBy === 3
              ? shortDateToLocaleStringWithoutYear(
                  moment(t.Label).toDate(),
                  userCTX.authenticationInfos.language ?? 'fr',
                )
              : filtersCTX.filters.performanceFilters.GroupBy === 2
                ? shortOnlyMonthDateToLocaleString(
                    moment(t.Label).toDate(),
                    userCTX.authenticationInfos.language ?? 'fr',
                  )
                : t.Label,
          ),
          code: hoveredId,
        },
      });
    }

    setHoveredId(null); // Réinitialise après un dépôt

    // You can set this data into state to re-render this component with the new chart
  };
  const handleDragOver = (e: any) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDragEnter = (id: number) => {
    setHoveredId(id);
  };

  const backgroundColor = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'darkorange'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Foreground;
        }
      });
    });
    return color;
  };
  const backgroundColor2 = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'pink'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Foreground;
        }
      });
    });
    return color;
  };

  const foreGroundColor2 = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'pink'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Background;
        }
      });
    });
    return color;
  };

  const foreGroundColor = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'darkorange'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Background;
        }
      });
    });
    return color;
  };
  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        {(isMobile || isTablet) && (
          <div
            className={`small-box bg-gradient-gray-dark`}
            style={{ width: orientation === 'portrait' ? '100%' : '50%' }}
          >
            <div className="inner"></div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12 col-12">
            <AnalytiqueProvider>
              <>
                {performanceData && (
                  <div>
                    <div className="row">
                      {stackedComponent?.from && mergeOptions !== undefined && stackedComponent.to && (
                        <div className="col-lg-6 col-12">
                          <div
                            style={{
                              border: hoveredId === 0 ? '2px dashed #333' : '1px solid #e0e0e0',
                              background: hoveredId === 0 ? '#ccc' : 'inherit',
                            }}
                            className={`small-box bg-gradient-light  drop-zone`}
                          >
                            <Button
                              style={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                backgroundColor: colors.colorErrorMain,
                                borderColor: colors.colorErrorDark,

                                height: '24px',
                                width: '24px',
                                fontSize: '16px',
                              }}
                              icon="fa-solid fa-xmark"
                              onClick={() => setStackedComponent(null)}
                            ></Button>
                            <LineChart
                              mergeOptions={mergeOptions2}
                              stackedComponent={stackedComponent}
                              data={{
                                datasets: [
                                  ...stackedComponent.from.datasets.map((dataset, i) => ({
                                    ...dataset, // Copie toutes les propriétés du dataset original
                                    label: dataset.label, // Ajoute une année ou une autre distinction au label
                                    borderColor: i % 2 ? '#666' : 'darkorange',
                                    backgroundColor: i % 2 ? 'darkorange' : '#666', // Idem pour l'arrière-plan
                                    yAxisID: 'y',
                                    beginAtZero: true,
                                    min: 0,
                                    data: dataset.data,
                                    type: dataset.type as 'line',
                                  })),
                                  ...stackedComponent.to.datasets.map((dataset, i) => ({
                                    ...dataset, // Copie toutes les propriétés du dataset original
                                    label: dataset.label, // Ajoute une année ou une autre distinction au label
                                    borderColor: i % 2 ? '#ddd' : 'pink',
                                    backgroundColor: i % 2 ? 'pink' : '#ddd', // Idem pour l'arrière-plan
                                    yAxisID: 'y1',
                                    beginAtZero: true,
                                    min: 0,
                                    data: dataset.data,
                                    type: dataset.type as 'line',
                                  })),
                                ],
                                labels: performanceData.Data.map((t) =>
                                  filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                  filtersCTX.filters.performanceFilters.GroupBy === 3
                                    ? shortDateToLocaleStringWithoutYear(
                                        moment(t.Label).toDate(),
                                        userCTX.authenticationInfos.language ?? 'fr',
                                      )
                                    : filtersCTX.filters.performanceFilters.GroupBy === 2
                                      ? shortOnlyMonthDateToLocaleString(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : t.Label,
                                ),
                              }}
                            ></LineChart>
                          </div>
                        </div>
                      )}

                      {performanceData.Data.length > 0 && (
                        <>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 0 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 0 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(0)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 0)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(0)}
                              ></i>
                              <LineChart
                                code={0}
                                mergeOptions={mergeOptions}
                                stackedComponent={stackedComponent}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 0)?.name,
                                      data: performanceData.Data.map((t) => t.Current.OccupancyRate),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 0)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.OccupancyRate),
                                      borderColor: foreGroundColor2,
                                      backgroundColor: backgroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 1 ? '2px dashed #333' : '1px solid #e0e0e0',
                                backgroundColor: hoveredId === 1 ? '#f0f0f0' : 'transparent',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(1)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 1)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(1)}
                              ></i>
                              <LineChart
                                code={1}
                                mergeOptions={mergeOptions}
                                stackedComponent={stackedComponent}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 1)?.name,
                                      data: performanceData.Data.map((t) => t.Current.Adr),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 1)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.Adr),
                                      borderColor: foreGroundColor2,
                                      backgroundColor: backgroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 2 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 2 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(2)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 2)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>{' '}
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(2)}
                              ></i>
                              <LineChart
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 2)?.name,
                                      data: performanceData.Data.map((t) => t.Current.RevPar),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 2)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.RevPar),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                                mergeOptions={mergeOptions}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 3 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 3 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(3)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 3)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>{' '}
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(3)}
                              ></i>
                              <BarChart
                                blueColor={false}
                                options={{
                                  ...mergeOptions,
                                  bar: { datasets: { barPercentage: 1, categoryPercentage: 1 } },

                                  layout: { autoPadding: false },
                                  datasets: { bar: { grouped: true, barPercentage: 1, categoryPercentage: 1 } },
                                }}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 3)?.name,
                                      data: performanceData.Data.map((t) => t.Current.AccomodationTurnOver),
                                      backgroundColor: colors.colorPrimaryMain,
                                      borderColor: '#333',
                                      borderWidth: 1,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 3)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.AccomodationTurnOver),
                                      backgroundColor: colors.colorSecondaryMain,
                                      borderColor: '#333',
                                      borderWidth: 1,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></BarChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 4 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 4 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(4)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 4)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(4)}
                              ></i>
                              <BarChart
                                options={{
                                  bar: { datasets: { barPercentage: 1, categoryPercentage: 1 } },

                                  layout: { autoPadding: false },
                                  datasets: { bar: { grouped: true, barPercentage: 1, categoryPercentage: 1 } },
                                }}
                                minHeight="250px"
                                blueColor={false}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 4)?.name,
                                      data: performanceData.Data.map((t) => t.Current.TurnOver),
                                      backgroundColor: colors.colorPrimaryMain,
                                      borderColor: '#333',
                                      borderWidth: 1,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 4)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.TurnOver),
                                      backgroundColor: colors.colorSecondaryMain,
                                      borderColor: '#333',
                                      borderWidth: 1,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></BarChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 5 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 5 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(5)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 5)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(5)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 5)?.name,
                                      data: performanceData.Data.map((t) => t.Current.Alos),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 5)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.Alos),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 6 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 6 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(6)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 6)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(6)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 6)?.name,
                                      data: performanceData.Data.map((t) => t.Current.RevPor),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 6)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.RevPor),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 7 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 7 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(7)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 7)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(7)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 7)?.name,
                                      data: performanceData.Data.map((t) => t.Current.RoomsSoldQty),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 7)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.RoomsSoldQty),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 8 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 8 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(8)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 8)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(8)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 8)?.name,
                                      data: performanceData.Data.map((t) => t.Current.ArrivalsQty),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 8)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.ArrivalsQty),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 9 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 9 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(9)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 9)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(9)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 9)?.name,
                                      data: performanceData.Data.map((t) => t.Current.DeparturesQty),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 9)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.DeparturesQty),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 10 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 10 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(10)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 10)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(10)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 10)?.name,
                                      data: performanceData.Data.map((t) => t.Current.OutOfOrder),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 10)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.OutOfOrder),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 11 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 11 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(11)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 11)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(11)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 11)?.name,
                                      data: performanceData.Data.map((t) => t.Current.TrevPar),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 11)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.TrevPar),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 12 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 12 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(12)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 12)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(12)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 12)?.name,
                                      data: performanceData.Data.map((t) => t.Current.RoomsSetQty),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 12)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.RoomsSetQty),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 13 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 13 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(13)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 13)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(13)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 13)?.name,
                                      data: performanceData.Data.map((t) => t.Current.Alt),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 13)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.Alt),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 14 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 14 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(14)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 14)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(14)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 14)?.name,
                                      data: performanceData.Data.map((t) => t.Current.Arg),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 14)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.Arg),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 15 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 15 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(15)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 15)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(15)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 15)?.name,
                                      data: performanceData.Data.map((t) => t.Current.TRevPec),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 15)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.TRevPec),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12">
                            <div
                              style={{
                                border: hoveredId === 16 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 16 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                              onDragOver={handleDragOver}
                              onDragEnter={() => handleDragEnter(16)}
                              onDrop={handleDrop}
                              onDragStart={(e) => handleDragStart(e, 16)} // chartData is your data for the chart
                            >
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  left: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'grab',
                                }}
                                className="fa-solid fa-bars"
                              ></i>
                              <i
                                draggable
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                  color: colors.colorGreyAdminLTE,
                                  cursor: 'pointer',
                                }}
                                className="fa-solid fa-eye"
                                onClick={() => openModale(16)}
                              ></i>
                              <LineChart
                                mergeOptions={mergeOptions}
                                data={{
                                  datasets: [
                                    {
                                      label: AnalyticsData.find((e) => e.code === 16)?.name,
                                      data: performanceData.Data.map((t) => t.Current.CancellationRate),
                                      backgroundColor: backgroundColor,
                                      borderColor: foreGroundColor,
                                    },
                                    {
                                      label:
                                        AnalyticsData.find((e) => e.code === 16)?.name +
                                        ' en ' +
                                        filtersCTX.filters.performanceFilters.YearToCompare,
                                      data: performanceData.Data.map((t) => t.Previous.CancellationRate),
                                      backgroundColor: backgroundColor2,
                                      borderColor: foreGroundColor2,
                                    },
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            </AnalytiqueProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Performance;
