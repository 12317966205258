import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment-timezone';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { storage } from '@store/localstorage/localStorage';
const Filters: React.FC = () => {
  const userCTX = useUser();
  const filtersCTX = useFilters();
  const dashboardFilters = filtersCTX.filters.dashboardFilters;
  const authData = userCTX.authenticationInfos; // userCTX.authenticationInfos;
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const [t] = useTranslation();
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const hotelList = userCTX.authenticationInfos.listHotel;

  const selectedHotel = userCTX.authenticationInfos.selectedHotel; //userCTX.authenticationInfos.selectedHotel
  const DisplayMode = [
    { name: t('header.label.availability'), code: 1 },
    { name: t('header.label.occupancy'), code: 2 },
    { name: t('header.label.pm'), code: 3 },
    { name: t('header.label.RevPar'), code: 4 },
  ];
  const { orientation } = useMobileOrientation();

  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = authData?.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };

  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && isMobile && dashboardFilters && (
        <Accordion activeIndex={0}>
          <AccordionTab className="mobile" header={t('labels.filters')} tabIndex={0} headerStyle={{ color: '#f0f0f0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: colors.colorGreyAdminLTE,
                padding: '10px',
              }}
            >
              <div style={{ width: '50%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.from')}</label>
                  <Calendar
                    value={new Date(dashboardFilters.DateStart)}
                    inputStyle={{ width: '70%', height: '40px' }}
                    style={{ width: '70%' }}
                    dateFormat={getDateFormat(dashboardFilters.DateStart)}
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          DateStart: moment(e.value?.getTime()).toDate() ?? 0,
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    locale={authData?.language || 'fr'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: '50px',
                  }}
                >
                  <label style={{ margin: 0 }}>{t('header.label.to')} </label>

                  <Calendar
                    value={new Date(dashboardFilters.DateEnd)}
                    inputStyle={{ width: '70%', height: '40px' }}
                    dateFormat={getDateFormat(dashboardFilters.DateEnd)}
                    minDate={moment(new Date(dashboardFilters.DateStart)).toDate()}
                    style={{ width: '70%' }}
                    onChange={(e) =>
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          DateEnd: moment(e.value).format('YYYYMMDD') ?? 0,
                          hasBeenModified: true,
                        },
                      }))
                    }
                    locale={authData?.language || 'fr'}
                  />
                </div>
                <div
                  style={{
                    margin: 0,
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.dateSituation')} </label>
                </div>
                <div style={{ margin: 0 }}>
                  <div style={{ display: 'flex', height: '50px', alignItems: 'center' }}>
                    <Checkbox
                      inputId="allowAllotment"
                      onChange={() =>
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          dashboardFilters: {
                            ...prevState.dashboardFilters,
                            IncludeAllotments: !dashboardFilters.IncludeAllotments,
                            hasBeenModified: true,
                          },
                        }))
                      }
                      checked={dashboardFilters.IncludeAllotments}
                    />

                    <label htmlFor="allowAllotment" style={{ margin: 0, marginLeft: '10px', marginRight: '0' }}>
                      {t('header.label.includeAllotment')}
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Dropdown
                    style={{
                      width: '160px',
                    }}
                    value={DisplayMode.find((e) => e.code === dashboardFilters.DisplayMode)}
                    onChange={(e) =>
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          DisplayMode: e.value.code,
                          hasBeenModified: true,
                        },
                      }))
                    }
                    options={DisplayMode}
                    optionLabel="name"
                    placeholder="Mode d'affichage"
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Dropdown
                    value={selectedHotel ?? ''}
                    onChange={async (e) => {
                      userCTX.setUser((prevState) => ({
                        ...prevState,
                        selectedHotel: e.value ?? '',
                      }));
                      storage.setParam('selectedHotel', JSON.stringify(e.value));

                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    options={hotelList as HotelData[]}
                    optionLabel="Name"
                    filter
                    placeholder={t('label.placeholder.choice') ?? ''}
                    className="w-full md:w-14rem"
                    style={{ whiteSpace: 'nowrap', maxWidth: '160px', width: '160px' }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Calendar
                    value={new Date(dashboardFilters.PickupDate)}
                    dateFormat={getDateFormat(dashboardFilters.PickupDate)}
                    inputStyle={{ width: '160px' }}
                    style={{ marginRight: '0px' }}
                    onChange={(e) =>
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          PickupDate: moment(e.value).format('YYYYMMDD'),
                          hasBeenModified: true,
                        },
                      }))
                    }
                    locale={authData?.language || 'fr'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Button
                    style={{
                      bottom: '0',
                      backgroundColor: filtersCTX.filters.mainCouranteFilters.hasBeenModified
                        ? colors.colorSuccessMain
                        : darkMode
                          ? 'var(--color-primary-light)'
                          : 'var(--color-primary-main)',

                      width: '35px',
                      height: '35px',
                      borderRadius: '4px',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        dashboardFilters: {
                          ...prevState.dashboardFilters,
                          needReload: true,
                          hasBeenModified: false,
                        },
                      }));
                    }}
                    icon="pi pi-refresh"
                  ></Button>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}
      {isLocaleSet && !isMobile && dashboardFilters && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <label style={{ margin: 0 }}> {t('header.label.from')}</label>
              <Calendar
                value={new Date(dashboardFilters.DateStart)}
                inputStyle={{ width: '100px' }}
                dateFormat={getDateFormat(dashboardFilters.DateStart)}
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    dashboardFilters: {
                      ...prevState.dashboardFilters,
                      DateStart: moment(e.value?.getTime()).toDate() ?? 0,
                      hasBeenModified: true,
                      DateEnd:
                        moment(e.value?.getTime()) > moment(dashboardFilters.DateEnd)
                          ? moment(e.value?.getTime()).toDate()
                          : dashboardFilters.DateEnd,
                    },
                  }));
                }}
                // maxDate={moment(new Date(dashboardFilters.DateEnd)).toDate()}
                locale={authData?.language || 'fr'}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <label style={{ margin: 0 }}> {t('header.label.to')} </label>

              <Calendar
                value={new Date(dashboardFilters.DateEnd)}
                inputStyle={{ width: '100px' }}
                minDate={moment(new Date(dashboardFilters.DateStart)).toDate()}
                dateFormat={getDateFormat(dashboardFilters.DateEnd)}
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onChange={(e) =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    dashboardFilters: {
                      ...prevState.dashboardFilters,
                      DateEnd: moment(e.value?.getTime()).toDate() ?? 0,
                      hasBeenModified: true,
                    },
                  }))
                }
                locale={authData?.language || 'fr'}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Dropdown
                style={{ marginRight: '10px', marginLeft: '10px', width: '150px' }}
                value={DisplayMode.find((e) => e.code === dashboardFilters.DisplayMode)}
                onChange={(e) =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    dashboardFilters: {
                      ...prevState.dashboardFilters,
                      DisplayMode: e.value.code,
                      hasBeenModified: true,
                    },
                  }))
                }
                options={DisplayMode}
                optionLabel="name"
                placeholder="Mode d'affichage"
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <label style={{ margin: 0 }}> {t('header.label.dateSituation')} </label>
              <Calendar
                value={new Date(dashboardFilters.PickupDate)}
                dateFormat={getDateFormat(dashboardFilters.PickupDate)}
                inputStyle={{ width: '100px' }}
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onChange={(e) =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    dashboardFilters: {
                      ...prevState.dashboardFilters,
                      PickupDate: moment(e.value?.getTime()).toDate() ?? 0,
                      hasBeenModified: true,
                    },
                  }))
                }
                maxDate={new Date()}
                locale={authData?.language || 'fr'}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Checkbox
                inputId="allowAllotment"
                onChange={() =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    dashboardFilters: {
                      ...prevState.dashboardFilters,
                      IncludeAllotments: !dashboardFilters.IncludeAllotments,
                      hasBeenModified: true,
                    },
                  }))
                }
                checked={dashboardFilters.IncludeAllotments}
              />

              <label htmlFor="allowAllotment" style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}>
                {t('header.label.includeAllotment')}
              </label>
            </div>
            <Button
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '4px',
                textAlign: 'center',
                justifyContent: 'center',
                backgroundColor: filtersCTX.filters.dashboardFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',
              }}
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  dashboardFilters: {
                    ...prevState.dashboardFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
              icon="pi pi-refresh"
            ></Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Filters;
