import React from 'react';

import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const OneRectangleLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader speed={2} width={'100%'} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
      <rect x="6" y="5" rx="7.5" ry="7.5" width="100%" height={props.height} style={{ borderRadius: '4px' }} />
    </ContentLoader>
  );
};

export default OneRectangleLoader;
