/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { Stay } from '@app/types/types';
import { getDeltaInDays, shortDateToLocaleString } from '@app/utils/dates';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const KardexStay: React.FC = () => {
  const kardexCTX = useKardex();

  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const userCTX = useUser();
  const authData = userCTX.authenticationInfos; // userCTX.authenticationInfos;
  const [t] = useTranslation();
  useEffect(() => {
    if (kardexCTX.StayFilters) {
      kardexCTX.setStayFilters({
        ...kardexCTX.StayFilters,
        IdHotel: userCTX.authenticationInfos.selectedHotel?.IdHotel ?? 0,
        IdKardex: kardexCTX?.CurrentKardex?.Id,
      });
    }
  }, []);

  useEffect(() => {
    if (kardexCTX.StayFilters?.IdKardex && kardexCTX.StayFilters?.IdHotel !== 0) {
      kardexCTX.getStays(kardexCTX.StayFilters);
      kardexCTX.getTotalStays(kardexCTX.StayFilters.IdKardex);
    }
  }, [kardexCTX.StayFilters]);
  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = authData?.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };
  return (
    <div
      style={{
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'space-between',

        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <label style={{ margin: 0 }}> {t('header.label.from')}</label>
          <Calendar
            value={new Date(kardexCTX?.StayFilters?.DateStart ?? 0)}
            inputStyle={{ width: '70%', height: '40px' }}
            style={{ width: '70%' }}
            dateFormat={getDateFormat(kardexCTX?.StayFilters?.DateStart ?? 0)}
            todayButtonClassName={'hidden'}
            onChange={(e) => {
              kardexCTX.setStayFilters({
                ...kardexCTX.StayFilters,
                DateStart: moment(e.value?.getTime()).toDate() ?? '',
              });
            }}
            locale={authData?.language || 'fr'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            height: '50px',
          }}
        >
          <label style={{ margin: 0 }}>{t('header.label.to')} </label>
          <Calendar
            value={new Date(kardexCTX?.StayFilters?.DateEnd ?? 0)}
            inputStyle={{ width: '70%', height: '40px' }}
            dateFormat={getDateFormat(kardexCTX?.StayFilters?.DateEnd ?? 0)}
            style={{ width: '70%' }}
            todayButtonClassName={'hidden'}
            onChange={(e) =>
              kardexCTX.setStayFilters({
                ...kardexCTX.StayFilters,

                DateEnd: moment(e.value?.getTime()).toDate() ?? 0,
              })
            }
            locale={authData?.language || 'fr'}
          />
        </div>
        <span>Afficher les annulations</span>{' '}
        <Checkbox
          checked={kardexCTX.StayFilters?.IncludeCancellations as boolean}
          onChange={() =>
            kardexCTX.setStayFilters((prevState) => ({
              ...prevState,
              IncludeCancellations: !kardexCTX.StayFilters?.IncludeCancellations,
            }))
          }
        ></Checkbox>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip target={'.sdiv'} position="bottom">
          Nombre de séjours depuis le{' '}
          {shortDateToLocaleString(
            moment(kardexCTX.TotalStays?.FromDate).toDate(),
            userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
          )}
        </Tooltip>
        <div className="sdiv" style={{ width: 'auto' }}>
          <i style={{ marginRight: '10px' }} className="fa-solid fa-calendar"></i>

          {kardexCTX.TotalStays?.NbSejours}
        </div>
        <Tooltip target={'.rdiv'} position="bottom">
          Nombre de nuitées depuis le{' '}
          {shortDateToLocaleString(
            moment(kardexCTX.TotalStays?.FromDate).toDate(),
            userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
          )}
        </Tooltip>
        <div className="rdiv" style={{ width: 'auto' }}>
          <i style={{ marginRight: '10px', marginLeft: '10px' }} className="fa-solid fa-moon"></i>{' '}
          {kardexCTX.TotalStays?.NbNuitees}
        </div>
        <Tooltip target={'.tdiv'} position="bottom">
          Chiffre d'affaire NET depuis le{' '}
          {shortDateToLocaleString(
            moment(kardexCTX.TotalStays?.FromDate).toDate(),
            userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
          )}
        </Tooltip>
        <div className="tdiv" style={{ width: 'auto' }}>
          <i style={{ marginRight: '10px', marginLeft: '10px' }} className="fa-solid fa-money-bill"></i>
          {Math.round(kardexCTX?.TotalStays?.MontantCa ?? 0)}
        </div>
        <Tooltip target={'.udiv'} position="bottom">
          Commissions depuis le{' '}
          {shortDateToLocaleString(
            moment(kardexCTX.TotalStays?.FromDate).toDate(),
            userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
          )}
        </Tooltip>
        <div className="udiv" style={{ width: 'auto' }}>
          <i style={{ marginRight: '10px', marginLeft: '10px' }} className="fa-solid fa-money-bill"></i>
          {Math.round(kardexCTX?.TotalStays?.MontantCommissions ?? 0)}
        </div>
      </div>
      <br />
      <label style={{ margin: 0 }}> Passé</label>
      <DataTable
        value={kardexCTX.StaysList?.filter((e) => e.IsFuture === false) ?? []}
        stripedRows
        scrollable
        rowHover
        className={(darkMode ? 'dark' : '') + ' normalDT'}
        style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}
        rowClassName={(rowData) => {
          return rowData.NoShow ? 'noshow-row' : rowData.IsCanceled ? 'canceled-row' : 'normal-row';
        }}
      >
        <Column
          body={(e: Stay) =>
            shortDateToLocaleString(moment(e.DateArrival).toDate(), userCTX?.authenticationInfos?.user?.Culture ?? 'fr')
          }
          header={<i className="fa-solid fa-plane-arrival"></i>}
        ></Column>
        <Column
          body={(e: Stay) => getDeltaInDays(new Date(e.DateArrival), new Date(e.DateDeparture))}
          header={<i className="fa-solid fa-moon"></i>}
        ></Column>

        <Column
          body={(e: Stay) =>
            shortDateToLocaleString(
              moment(e.DateDeparture).toDate(),
              userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
            )
          }
          header={<i className="fa-solid fa-plane-departure"></i>}
        ></Column>
        <Column field="RoomNumber" header={<i className="fa-solid fa-bed"></i>}></Column>
        <Column field="Adults" header={<i className="fa-solid fa-user"></i>}></Column>
        <Column
          body={(e: Stay) => {
            return e.Invoices.map((t, i) => {
              return (
                <Button
                  style={{ width: '24px', height: '24px', fontSize: '9px' }}
                  key={'button' + i}
                  icon={t.IsExtra ? 'fa-solid fa-martini-glass-citrus' : 'fa-solid fa-house'}
                  tooltip={t.Number.toString()}
                ></Button>
              );
            });
          }}
          header={<i className="fa-regular fa-file-lines"></i>}
        ></Column>
        <Column field="Totaux.PrixPremiereNuit" header={<i className="fa-solid fa-money-bill"></i>}></Column>

        <Column field="Totaux.MontantCa" header={<i className="fa-regular fa-calendar"></i>}></Column>
        <Column
          body={() => (
            <Button style={{ width: '24px', height: '24px', fontSize: '9px' }} icon="fa-solid fa-book"></Button>
          )}
          header={<i className="fa-regular fa-file-image"></i>}
        ></Column>
        <Column field="" header={<i className="fa-regular fa-calendar"></i>}></Column>

        <Column field="Segment" header={<i className="fa-solid fa-chart-pie"></i>}></Column>

        <Column field="" header={<i className="fa-regular fa-face-smile"></i>}></Column>
      </DataTable>
      <br />
      <label style={{ margin: 0 }}> Prévisionnel</label>
      <DataTable
        value={kardexCTX.StaysList?.filter((e) => e.IsFuture === true) ?? []}
        stripedRows
        scrollable
        rowHover
        className={(darkMode ? 'dark' : '') + ' normalDT'}
        style={{ justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}
        rowClassName={(rowData) => {
          return rowData.NoShow ? 'noshow-row' : rowData.IsCanceled ? 'canceled-row' : 'normal-row';
        }}
      >
        <Column
          body={(e: Stay) =>
            shortDateToLocaleString(moment(e.DateArrival).toDate(), userCTX?.authenticationInfos?.user?.Culture ?? 'fr')
          }
          header={<i className="fa-solid fa-plane-arrival"></i>}
        ></Column>
        <Column
          body={(e: Stay) => getDeltaInDays(new Date(e.DateArrival), new Date(e.DateDeparture))}
          header={<i className="fa-solid fa-moon"></i>}
        ></Column>

        <Column
          body={(e: Stay) =>
            shortDateToLocaleString(
              moment(e.DateDeparture).toDate(),
              userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
            )
          }
          header={<i className="fa-solid fa-plane-departure"></i>}
        ></Column>
        <Column field="RoomNumber" header={<i className="fa-solid fa-bed"></i>}></Column>
        <Column field="Adults" header={<i className="fa-solid fa-user"></i>}></Column>
        <Column
          body={(e: Stay) => {
            return e.Invoices.map((t, i) => {
              return (
                <Button
                  style={{ width: '24px', height: '24px', fontSize: '9px' }}
                  key={'button' + i}
                  icon={t.IsExtra ? 'fa-solid fa-martini-glass-citrus' : 'fa-solid fa-house'}
                  tooltip={t.Number.toString()}
                ></Button>
              );
            });
          }}
          header={<i className="fa-regular fa-file-lines"></i>}
        ></Column>
        <Column field="Totaux.PrixPremiereNuit" header={<i className="fa-solid fa-money-bill"></i>}></Column>

        <Column field="Totaux.MontantCa" header={<i className="fa-regular fa-calendar"></i>}></Column>
        <Column
          body={() => (
            <Button style={{ width: '24px', height: '24px', fontSize: '9px' }} icon="fa-solid fa-book"></Button>
          )}
          header={<i className="fa-regular fa-file-image"></i>}
        ></Column>
        <Column field="" header={<i className="fa-regular fa-calendar"></i>}></Column>

        <Column field="Segment" header={<i className="fa-solid fa-chart-pie"></i>}></Column>

        <Column field="" header={<i className="fa-regular fa-face-smile"></i>}></Column>
      </DataTable>
    </div>
  );
};

export default KardexStay;
