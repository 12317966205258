/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { CheckInStats } from '@app/types/Hotel/checkinStat';
import { HouseKeeping } from '@app/types/Hotel/housKeeping';
import { Stay } from '@app/types/Hotel/stay';
import { WeekOccupancyStat } from '@app/types/Hotel/weekOccupancyStat';
import { getLogger } from 'loglevel';
import moment from 'moment';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
interface HotelContextState {
  CheckInStats: CheckInStats | null;
  Housekeepings: HouseKeeping[] | null;
  WeekOccupancyStats: WeekOccupancyStat[] | null;
  Arrivals: Stay[] | null;
  Departures: Stay[] | null;
  InHouse: Stay[] | null;
  MemoReminders: MemoReminders[] | null;
  getArrivals: (params: TechAndHotelParams) => Promise<void | undefined>;
  setArrivals: (Arrivals: Stay[] | null) => void;
  getDepartures: (params: TechAndHotelParams) => Promise<void | undefined>;
  setDepartures: (Departures: Stay[] | null) => void;
  getInHouse: (params: TechAndHotelParams) => Promise<void | undefined>;
  setInHouse: (InHouse: Stay[] | null) => void;
  getCheckInStats: (params: TechAndHotelParams) => Promise<void | undefined>;
  setCheckInStats: (CheckInStats: CheckInStats | null) => void;
  getHousekeepings: (params: TechAndHotelParams) => Promise<void | undefined>;
  setHousekeepings: (Housekeepings: HouseKeeping[] | null) => void;
  getWeekOccupancyStat: (params: TechAndHotelParams) => Promise<void | undefined>;
  setWeekOccupancyStat: (WeekOccupancyStat: WeekOccupancyStat[] | null) => void;
  getMemoReminders: (params: TechAndHotelParams) => Promise<void | undefined>;
  setMemoReminders: (MemoReminders: MemoReminders[] | null) => void;
  closeMemoReminders: (params: closeMemoReminders) => void;
}

interface closeMemoReminders {
  params: TechAndHotelParams;
  memo: MemoReminders;
}

// Create a context to hold your params with default values
export const HotelContext = createContext<HotelContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type HotelProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const HotelProvider: React.FC<HotelProviderProps> = (props) => {
  const [Arrivals, setArrivals] = useState<Stay[] | null>(null);
  const [MemoReminders, setMemoReminders] = useState<MemoReminders[] | null>(null);

  const [Departures, setDepartures] = useState<Stay[] | null>(null);
  const [InHouse, setInHouse] = useState<Stay[] | null>(null);
  const [WeekOccupancyStats, setWeekOccupancyStat] = useState<WeekOccupancyStat[] | null>(null);
  const [Housekeepings, setHousekeepings] = useState<HouseKeeping[] | null>(null);
  const [CheckInStats, setCheckInStats] = useState<CheckInStats | null>(null);
  const getArrivals = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('PmsDashboard/Operational/Arrivals', {
        IdHotel: params.selectedHotel?.IdHotel,
        Date: new Date(),
      });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setArrivals(data.Data as Stay[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getMemoReminders = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        'Bookings/MemoReminders/' + params?.selectedHotel?.IdHotel + '/' + moment(Date.now()).format('YYYY-MM-DD'),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setMemoReminders(data.Data as MemoReminders[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const closeMemoReminders = async (params: closeMemoReminders) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('Bookings/MemoReminders/' + params?.params.selectedHotel?.IdHotel + '/close', [
        params.memo,
      ]);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      } else {
        getMemoReminders(params.params);
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getDepartures = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      const response = await axios.post('PmsDashboard/Operational/Departures', {
        IdHotel: params?.selectedHotel?.IdHotel,
        Date: new Date(),
      });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setDepartures(data.Data as Stay[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getInHouse = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :

      const response = await axios.post('PmsDashboard/Operational/InHouse', {
        IdHotel: params?.selectedHotel?.IdHotel,
        Date: new Date(),
      });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setInHouse(data.Data as Stay[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getCheckInStats = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('PmsDashboard/Operational/CheckInStats', params.selectedHotel?.IdHotel);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setCheckInStats(data.Data as CheckInStats);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getHousekeepings = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('PmsDashboard/Operational/Housekeeping', params.selectedHotel?.IdHotel);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setHousekeepings(data.Data as HouseKeeping[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getWeekOccupancyStat = async (params: TechAndHotelParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('PmsDashboard/Operational/WeekOccupancyStats', params.selectedHotel?.IdHotel);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setWeekOccupancyStat(data.Data as WeekOccupancyStat[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <HotelContext.Provider
      value={{
        getArrivals,
        Arrivals,
        CheckInStats,
        Departures,
        MemoReminders,
        getCheckInStats,
        getDepartures,
        getHousekeepings,
        getInHouse,
        getWeekOccupancyStat,
        Housekeepings,
        InHouse,
        setArrivals,
        setCheckInStats,
        getMemoReminders,
        setMemoReminders,
        setDepartures,
        closeMemoReminders,
        setHousekeepings,
        setInHouse,
        setWeekOccupancyStat,
        WeekOccupancyStats,
      }}
    >
      {props.children}
    </HotelContext.Provider>
  );
};

// Create a custom hook to access the params
export function useHotel(): HotelContextState {
  const hotelContext = useContext(HotelContext);
  if (hotelContext === undefined) {
    throw new Error('useHotel must be used within a HotelProvider');
  }
  return hotelContext;
}
