import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const ApiConfig = {
  baseURL: config.API_URL,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
};

const instance: AxiosInstance = axios.create(ApiConfig);
/* eslint-disable */
import { storage } from '@store/localstorage/localStorage';
import { toast } from 'react-toastify';
import { config } from '@app/utils/config';

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    //We save the last time call
    const lastCall = storage.getParam('lastCall');
    if (lastCall !== undefined && lastCall !== null) {
      if (Date.now() - parseInt(lastCall) > 27000000) {
        storage.setParam('needToRelog', 'true');
        throw 'too long time';
      }
    }
    storage.setParam('lastCall', Date.now().toString());

    const token = storage.getParam('access_token');
    return new Promise((resolve) => {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/pdf';
      config.responseType = 'blob';
      resolve(config);
    });
  },
  function (error) {
    // Do something with error of the request
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(null, (e: AxiosError) => {
  if (e.response?.status === 401) {
    storage.setParam('needToRelog', 'true');
  } else {
    toast.error(e.message);
  }
});

export default instance;
