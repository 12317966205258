/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';

import LineChart from '@app/components/Common/charts/LineChart';
import { useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { analyticsKey } from '@app/types/types';
import {
  shortDateToLocaleString,
  shortDateToLocaleStringWithoutYear,
  shortOnlyMonthDateToLocaleString,
} from '@app/utils/dates';
import { formatDate } from '@mobiscroll/react';
import moment from 'moment-timezone';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

interface props {
  data: any;
}
const PerformanceDetail: React.FC<props> = ({ data }) => {
  const filtersCTX = useFilters();
  const userCTX = useUser();
  const [tr] = useTranslation();
  const analyticsCTX = useAnalytics();

  useEffect(() => {
    if (filtersCTX.filters.performanceFilters.needReload === true) {
      analyticsCTX.setAnalyticsPerformance(null);
    }
  }, [filtersCTX.filters.performanceFilters.needReload]);

  const [dataToDisplay, setDataToDisplay] = useState<any | null>(null);
  const AnalyticsData: analyticsKey[] = [
    { name: tr('smallbox.label.TO'), code: 0, value: 'OccupancyRate' },

    { name: tr('smallbox.label.PM'), code: 1, value: 'Adr' },

    { name: tr('smallbox.label.RevPar'), code: 2, value: 'RevPar' },

    { name: tr('smallbox.label.RoomRevenue'), code: 3, value: 'AccomodationTurnOver' },

    { name: tr('smallbox.label.GlobalRevenue'), code: 4, value: 'TurnOver' },

    { name: tr('smallbox.label.ALOS'), code: 5, value: 'Alos' },
    { name: tr('prevision.label.PMH'), code: 6, value: 'RevPor' },
    { name: tr('header.label.occupancy'), code: 7, value: 'RoomsSoldQty' },
    { name: tr('labels.arrivals'), code: 8, value: 'ArrivalsQty' },
    { name: tr('labels.departures'), code: 9, value: 'DeparturesQty' },

    { name: tr('labels.outoforder'), code: 10, value: 'OutOfOrder' },
    { name: tr('labels.TrevPar'), code: 11, value: 'TrevPar' },
    { name: tr('labels.RoomsSetQty'), code: 12, value: 'RoomsSetQty' },
    { name: tr('labels.Alt'), code: 13, value: 'Alt' },
    { name: tr('labels.Arg'), code: 14, value: 'Arg' },
    { name: tr('labels.TRevPe'), code: 15, value: 'TRevPe' },
    { name: tr('labels.CancellationRate'), code: 16, value: 'CancellationRate' },
  ];
  useEffect(() => {
    if (analyticsCTX.AnalyticsPerformance) {
      setDataToDisplay({
        data: {
          datasets: [
            {
              label: AnalyticsData.find((e) => e.code === data.dataIndex)?.name,
              data: analyticsCTX.AnalyticsPerformance?.Data.map(
                (t) =>
                  t.Current[AnalyticsData?.find((e) => e.code === data.dataIndex)?.value as keyof typeof t.Current],
              ),
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                analyticsCTX.AnalyticsPerformance?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                analyticsCTX.AnalyticsPerformance?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
            },
            {
              label:
                AnalyticsData.find((e) => e.code === data.dataIndex)?.name +
                ' en ' +
                filtersCTX.filters.performanceFilters.YearToCompare,
              data: analyticsCTX.AnalyticsPerformance?.Data.map(
                (t) =>
                  t.Previous[AnalyticsData?.find((e) => e.code === data.dataIndex)?.value as keyof typeof t.Previous],
              ),
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                analyticsCTX.AnalyticsPerformance?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                analyticsCTX.AnalyticsPerformance?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
            },
          ],
          labels: analyticsCTX.AnalyticsPerformance?.Data.map((t) =>
            filtersCTX.filters.performanceFilters.GroupBy === 0 || filtersCTX.filters.performanceFilters.GroupBy === 3
              ? shortDateToLocaleStringWithoutYear(
                  moment(t.Label).toDate(),
                  userCTX.authenticationInfos.language ?? 'fr',
                )
              : filtersCTX.filters.performanceFilters.GroupBy === 2
                ? shortOnlyMonthDateToLocaleString(
                    moment(t.Label).toDate(),
                    userCTX.authenticationInfos.language ?? 'fr',
                  )
                : t.Label,
          ),
        },
      });
    }
  }, [analyticsCTX.AnalyticsPerformance]);

  return (
    <section className="content">
      {dataToDisplay && analyticsCTX.AnalyticsPerformance && !filtersCTX.filters.performanceFilters.hasBeenModified && (
        <>
          <div className="container-fluid" style={{ paddingTop: '20px', display: 'flex', flexDirection: 'row' }}>
            <DataTable value={dataToDisplay.data.labels} stripedRows className="normalDT previsionDT">
              <Column
                headerStyle={{ width: '200px' }}
                header="Date"
                body={(t) => {
                  return (
                    <span style={{ margin: '10px', whiteSpace: 'nowrap' }}>
                      {filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                      filtersCTX.filters.performanceFilters.GroupBy === 3
                        ? formatDate('DDD ', moment(t + '/' + moment().year(), 'DD/MM/YY').toDate(), {
                            dayNamesShort: Object.values(tr('calendar.dayNamesShort', { returnObjects: true })),
                          }) +
                          shortDateToLocaleString(
                            moment(t + '/' + moment().year(), 'DD/MM/YYYY').toDate(),
                            userCTX.authenticationInfos.language ?? 'fr',
                          )
                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                          ? shortOnlyMonthDateToLocaleString(
                              moment(moment().year() + '/' + t).toDate(),
                              userCTX.authenticationInfos.language ?? 'fr',
                            )
                          : t}
                    </span>
                  );
                }}
              >
                {' '}
              </Column>
              {dataToDisplay?.data.datasets.map((e: any, i: number) => (
                <Column
                  header={() => e.label}
                  body={(t, ii) => {
                    return (
                      <span style={{ marginLeft: '15px', marginRight: '15px' }}>{e.data[ii.rowIndex].toFixed(2)}</span>
                    );
                  }}
                  key={i}
                  // header={shortDateToLocaleStringWithoutYear(e, userCTX.authenticationInfos.language ?? 'fr')}
                ></Column>
              ))}
            </DataTable>
            <LineChart code={0} mergeOptions={data.mergeOptions} data={dataToDisplay.data}></LineChart>
          </div>

          {(filtersCTX.filters.performanceFilters.GroupBy === 0 ||
            filtersCTX.filters.performanceFilters.GroupBy === 3) && (
            <div className="container-fluid" style={{ paddingTop: '20px', width: '100%' }}>
              <DataTable
                value={analyticsCTX.AnalyticsPerformance.Events.concat(data.originalData.Holidays)}
                stripedRows
                className="normalDT previsionDT"
              >
                <Column header="Evènement" body={(k) => k.ShortName ?? 'Jour feriés'}></Column>
                {analyticsCTX.AnalyticsPerformance.Data.map((e: any, i: number) => {
                  return (
                    <Column
                      header={() => {
                        return filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                          filtersCTX.filters.performanceFilters.GroupBy === 3
                          ? shortDateToLocaleStringWithoutYear(
                              moment(e.Label).toDate(),
                              userCTX.authenticationInfos.language ?? 'fr',
                            )
                          : filtersCTX.filters.performanceFilters.GroupBy === 2
                            ? shortOnlyMonthDateToLocaleString(
                                moment(e.Label).toDate(),
                                userCTX.authenticationInfos.language ?? 'fr',
                              )
                            : e.Label;
                      }}
                      key={'f' + i}
                      body={(k) => {
                        return (
                          <div style={{ justifyContent: 'center', display: 'flex' }}>
                            {k.Data.filter(
                              (event: any) =>
                                moment(event.Dates.DateStart).format('YYYYMMDD') ===
                                  moment(e.Label).format('YYYYMMDD') ||
                                (moment(e.Label) > moment(event.Dates.DateStart) &&
                                  moment(e.Label) <= moment(event.Dates.DateEnd)),
                            ).length > 0 ? (
                              <div
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '4px',
                                  fontSize: 0,
                                  backgroundColor:
                                    k.Data.filter(
                                      (event: any) =>
                                        moment(event.Dates.DateStart).format('YYYYMMDD') ===
                                          moment(e.Label).format('YYYYMMDD') ||
                                        (moment(e.Label) > moment(event.Dates.DateStart) &&
                                          moment(e.Label) <= moment(event.Dates.DateEnd)),
                                    )[0]?.Background ?? 'silver',
                                }}
                                title={
                                  k.Data.filter(
                                    (event: any) =>
                                      moment(event.Dates.DateStart).format('YYYYMMDD') ===
                                        moment(e.Label).format('YYYYMMDD') ||
                                      (moment(e.Label) > moment(event.Dates.DateStart) &&
                                        moment(e.Label) <= moment(event.Dates.DateEnd)),
                                  )[0]?.Description ??
                                  k.Data.filter(
                                    (event: any) =>
                                      moment(event.Dates.DateStart).format('YYYYMMDD') ===
                                        moment(e.Label).format('YYYYMMDD') ||
                                      (moment(e.Label) > moment(event.Dates.DateStart) &&
                                        moment(e.Label) <= moment(event.Dates.DateEnd)),
                                  )[0]?.LocalName
                                }
                              >
                                .
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      }}
                    ></Column>
                  );
                })}
              </DataTable>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default PerformanceDetail;
