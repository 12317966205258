import loglevel, { LogLevelNames } from 'loglevel';

import { config } from '@utils/config';

import { createLogging } from './createLogging';
import { LoggerContext } from './types';

export function createWebLogger(context: LoggerContext): loglevel.Logger {
  const logger = loglevel.getLogger('web info');
  logger.methodFactory = createLogging({
    context,
    sentry: { level: config.LOGGER_WEB as LogLevelNames, breadcrumbType: 'user' },
    console: { level: config.LOGGER_WEB as LogLevelNames },
  });
  logger.setLevel((config.LOGGER_WEB as LogLevelNames) ?? 'info');

  if (import.meta.env.VITE_NODE_ENV === 'test') {
    logger.disableAll();
  }

  return logger;
}
