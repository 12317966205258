import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationCN from '../locales/cn/translation.json';
import translationDE from '../locales/de/translation.json';
import translationEN from '../locales/en/translation.json';
import translationES from '../locales/es/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationMA from '../locales/ma/translation.json';
import translationTR from '../locales/tr/translation.json';
// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  ma: { translation: translationMA },
  cn: { translation: translationCN },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
