import { ReactNode, useEffect, useRef, useState } from 'react';

import { createGradient } from '@app/components/Common/charts/chartOption';
import { stackedComponent } from '@app/types/types';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart as ChartJS } from 'chart.js';
import { Button } from 'primereact/button';
import { Line } from 'react-chartjs-2';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface props {
  data: ChartData<'line', number[], unknown>;
  mergeOptions?: ChartOptions<'line'>;
  title?: ReactNode;
  showTitle?: boolean;
  onBtnClick?: () => void;
  stackedComponent?: stackedComponent | null;
  code?: number;
}

const LineChart: React.FC<props> = ({ data, title, showTitle, onBtnClick, stackedComponent, code, mergeOptions }) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    const colors = ['coral', 'orange', 'green', 'blue', 'violine', 'pink', 'green'];
    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        label: dataset.label,
        data: dataset.data,
        backgroundColor: dataset.backgroundColor
          ? dataset.backgroundColor
          : [createGradient(colors[i], chart.ctx, chart.chartArea)],
        borderColor: dataset.borderColor
          ? dataset.borderColor
          : [createGradient(colors[i], chart.ctx, chart.chartArea)],
      })),
    };

    setChartData(chartData);
  }, [data]);

  return (
    <>
      <div
        style={{
          padding: '20px',
          width: '100%',
        }}
      >
        {showTitle && (
          <>
            <Typography component="h1" variant="h6" sx={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Divider variant="middle" />
          </>
        )}
        <div>
          {onBtnClick !== undefined && stackedComponent?.from?.code !== code && stackedComponent?.to?.code !== code && (
            <Button onClick={() => onBtnClick()}>{stackedComponent?.from === undefined ? 'From' : 'TO'}</Button>
          )}

          <Line
            options={{
              ...mergeOptions,
              hover: {
                mode: 'index',
                intersect: false,
              },
              plugins: {
                tooltip: {
                  mode: 'index',
                  intersect: false,
                },
              },
            }}
            ref={chartRef}
            data={chartData}
          ></Line>
        </div>
      </div>
    </>
  );
};

export default LineChart;
