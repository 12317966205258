/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';

import { useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { BookingsPickupItem } from '@app/types/types';
import { shortDateToLocaleString } from '@app/utils/dates';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart as ChartJS } from 'chart.js';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Line } from 'react-chartjs-2';

import { Slider } from '@mui/material';

const PickupLineChart: React.FC = () => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const analyticsCTX = useAnalytics();

  const [data, setData] = useState<ChartData<'line', number[], unknown>>();
  const [date, setDate] = useState('');
  const [maxValue, setMaxValue] = useState(-Infinity);
  const [marks, setMarks] = useState<BookingsPickupItem[]>([]);
  const filtersCTX = useFilters();
  const userCTX = useUser();
  const authData = userCTX.authenticationInfos;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const pickupFilters = filtersCTX.filters.pickupFilters;

  const findMaxValue = (dataArray: any) => {
    let maxValue = -Infinity;

    const checkDatas = (datas: any) => {
      datas.forEach((item: any) => {
        if (item.Value > maxValue) {
          maxValue = item.Value;
        }
      });
    };
    Object.values(dataArray).map((dateEntry: any) => {
      if (dateEntry.ReservationsBrutes) {
        checkDatas(dateEntry.ReservationsBrutes.Datas);
      }
      if (dateEntry.ReservationsNettes) {
        checkDatas(dateEntry.ReservationsNettes.Datas);
      }
      if (dateEntry.Annulations) {
        checkDatas(dateEntry.Annulations.Datas);
      }
      if (dateEntry.ReservationsBrutesNMoinsUn) {
        checkDatas(dateEntry.ReservationsBrutesNMoinsUn.Datas);
      }
      if (dateEntry.ReservationsNettesNMoinsUn) {
        checkDatas(dateEntry.ReservationsNettesNMoinsUn.Datas);
      }
      if (dateEntry.AnnulationsNMoinsUn) {
        checkDatas(dateEntry.AnnulationsNMoinsUn.Datas);
      }
    });

    return maxValue;
  };

  useEffect(() => {
    if (analyticsCTX?.AnalyticsBookingPickupData?.Items) {
      const maxLegend = findMaxValue(analyticsCTX?.AnalyticsBookingPickupData?.Items);
      setMaxValue(maxLegend);
    }
  }, [analyticsCTX?.AnalyticsBookingPickupData?.Items]);

  const options: ChartOptions<'line'> = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          // Créez une annotation pour chaque bande
          ...Array.from({ length: 1 }, (_, i) => ({
            type: 'box',
            id: `a-box-${i}`,
            yMin: i * 100, // Début de la bande
            yMax: maxValue, // Fin de la bande
            xMin: -1,
            xMax: marks[0]?.ReservationsBrutes?.Datas?.length, // Couvre toute la largeur du graphique
            backgroundColor: 'rgba(225, 225, 225, 0)',
            borderWidth: 0,
          })).reduce((acc, annotation) => ({ ...acc, [annotation.id]: annotation }), {}),
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState<ChartOptions<'line'>>(options); // Ajouter un état pour les options
  const optionsChart = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    weekday: 'long',
  } as Intl.DateTimeFormatOptions;
  const [currentIndex, setCurrentIndex] = useState(30);
  const gridData = [
    {
      palier: 24,
      nbJour: 364,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(364, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 23,
      nbJour: 273,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(273, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },

    {
      palier: 22,
      nbJour: 182,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(182, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 21,
      nbJour: 120,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(120, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 20,
      nbJour: 91,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(91, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 19,
      nbJour: 63,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(63, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 18,
      nbJour: 49,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(49, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 17,
      nbJour: 42,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(42, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 16,
      nbJour: 35,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(35, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 15,
      nbJour: 28,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(28, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 14,
      nbJour: 21,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(21, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 13,
      nbJour: 15,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(15, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 12,
      nbJour: 12,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(12, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 11,
      nbJour: 11,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(11, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 10,
      nbJour: 10,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(10, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 9,
      nbJour: 9,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(9, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 8,
      nbJour: 8,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(8, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 7,
      nbJour: 7,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(7, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 6,
      nbJour: 6,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(6, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 5,
      nbJour: 5,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(5, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 4,
      nbJour: 4,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(4, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 3,
      nbJour: 3,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(3, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 2,
      nbJour: 2,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(2, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 1,
      nbJour: 1,
      date: shortDateToLocaleString(
        moment(new Date(date)).subtract(1, 'day').toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    {
      palier: 0,
      nbJour: 0,
      date: shortDateToLocaleString(
        moment(new Date(date)).toDate(),
        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
      ),
    },
    { palier: -1, nbJour: -1, date: '' },
  ];

  useEffect(() => {
    if (filtersCTX.filters.pickupFilters.needReload === true && isTech !== undefined) {
      analyticsCTX.getAnalyticsBookingPickupData(
        moment(filtersCTX.filters.pickupFilters.Date).format('YYYY-MM-DD'),
        filtersCTX.filters.pickupFilters.ListHotels.map((e) => e.IdHotel),
      );

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        pickupFilters: {
          ...prevState.pickupFilters,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authData.selectedHotel,
    filtersCTX,
    filtersCTX.filters.pickupFilters.needReload,
    isTech,
    analyticsCTX,
    pickupFilters,
  ]);
  useEffect(() => {
    if (analyticsCTX?.AnalyticsBookingPickupData?.Items) {
      const newMarks = Object.keys(analyticsCTX.AnalyticsBookingPickupData.Items).map((date) => {
        const item = analyticsCTX?.AnalyticsBookingPickupData?.Items[date as any];

        // Construire un objet qui correspond à l'interface `BookingsPickupItem`
        const bookingItem: BookingsPickupItem = {
          Date: date, // Utiliser la clé comme date, ou fournir une valeur par défaut si `date` peut être `undefined`
          Annulations: item?.Annulations || { Id: '', Datas: [{ Category: 0, Value: 0 }] },
          AnnulationsNMoinsUn: item?.AnnulationsNMoinsUn || { Id: '', Datas: [{ Category: 0, Value: 0 }] },
          ReservationsBrutes: item?.ReservationsBrutes || { Id: '', Datas: [{ Category: 0, Value: 0 }] },
          ReservationsBrutesNMoinsUn: item?.ReservationsBrutesNMoinsUn || {
            Id: '',
            Datas: [{ Category: 0, Value: 0 }],
          },
          ReservationsNettes: item?.ReservationsNettes || { Id: '', Datas: [{ Category: 0, Value: 0 }] },
          ReservationsNettesNMoinsUn: item?.ReservationsNettesNMoinsUn || {
            Id: '',
            Datas: [{ Category: 0, Value: 0 }],
          },
        };

        return bookingItem;
      });

      setMarks(newMarks);
    }
  }, [analyticsCTX.AnalyticsBookingPickupData]);
  useEffect(() => {
    if (marks.length > 0 && currentIndex) {
      setDate(marks[currentIndex].Date);
      setData({
        labels: marks[currentIndex].Annulations.Datas.map((e) => e.Category),
        datasets: [
          {
            label: 'Réservations brutes',
            data: marks[currentIndex].ReservationsBrutes.Datas.map((e) => e.Value),
            borderColor: 'rgba(255, 146, 30, 1)',
            backgroundColor: 'rgba(255, 146, 30, 1)',
            tension: 0.5,
          },
          {
            label: 'Réservations brutes N-1',
            data: marks[currentIndex].ReservationsBrutesNMoinsUn.Datas.map((e) => e.Value),
            borderColor: 'rgba(255, 146, 30, 1)',
            backgroundColor: 'rgba(255, 146, 30, 0)',
            tension: 0.5,

            borderDash: [5, 5], // Ligne en pointillés
          },
          {
            label: 'Annulations',
            data: marks[currentIndex].Annulations.Datas.map((e) => e.Value),
            borderColor: 'rgba(115, 190,210, 1)',
            backgroundColor: 'rgba(115, 190, 210, 1)',
            tension: 0.5,
          },
          {
            label: 'Annulations N - 1',
            data: marks[currentIndex].AnnulationsNMoinsUn.Datas.map((e) => e.Value),
            borderColor: 'rgba(115, 190,210, 1)',
            backgroundColor: 'rgba(115, 190, 210, 0)',
            borderDash: [5, 5], // Ligne en pointillés
            tension: 0.5,
          },
          {
            label: 'Reservations nettes',
            data: marks[currentIndex].ReservationsNettes.Datas.map((e) => e.Value),

            tension: 0.5,

            borderColor: 'rgba(0, 105, 105, 1)',
            backgroundColor: 'rgba(0,105,105, 1)',
          },
          {
            label: 'Reservations nettes N - 1',
            data: marks[currentIndex].ReservationsNettesNMoinsUn.Datas.map((e) => e.Value),
            tension: 0.5,
            borderDash: [5, 5], // Ligne en pointillés
            borderColor: 'rgba(0, 105, 105, 1)',
            backgroundColor: 'rgba(0,105,105, 0)',
          },
        ],
      });
    }
  }, [currentIndex, marks]);

  useEffect(() => {
    // Mise à jour des options avec les événements de survol personnalisés

    if (chartData.datasets.length > 0) {
      const updatedOptions: ChartOptions<'line'> = {
        ...options, // Conserver les options existantes
        onHover: (event, chartElements) => {
          if (chartElements.length > 0) {
            const firstElement = chartElements[0];
            // Votre logique ici
            const dataIndex = firstElement.index;
            // Mettez à jour la ligne sélectionnée basée sur l'index de l'élément survolé
            const correspondingRow = gridData[dataIndex];
            setSelectedRow(correspondingRow);
          }
        },
        plugins: {
          ...options.plugins,
          tooltip: {
            mode: 'index',
            intersect: false,

            callbacks: {
              title: (items) => {
                // eslint-disable-next-line max-len
                return `${gridData.find((e) => e.palier.toString() === items[0].label)?.date} : ${gridData.find((e) => e.palier.toString() === items[0].label)?.nbJour} jours avant arrivée`;
              },
              label: (item) => {
                return `${item.dataset.label} :  ${item.formattedValue} ${
                  item.datasetIndex === 0
                    ? (chartData.datasets[1].data[item.dataIndex] as number) < (item.raw as number)
                      ? '↗'
                      : '↘ '
                    : item.datasetIndex === 2
                      ? (chartData.datasets[3].data[item.dataIndex] as number) < (item.raw as number)
                        ? '↗ '
                        : '↘ '
                      : item.datasetIndex === 4
                        ? (chartData.datasets[5].data[item.dataIndex] as number) < (item.raw as number)
                          ? '↗ '
                          : '↘ '
                        : ''
                }
            
              `;
              },
            },
          },
        },
      };
      setChartOptions(updatedOptions); // Utilisez la fonction de mise à jour de l'état pour les options
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]); // Ce useEffect dépend des props `options`

  useEffect(() => {
    if (data) {
      const chart = chartRef.current;

      if (!chart) {
        return;
      }
      const chartData = {
        ...data,
        datasets: data.datasets,
      };

      setChartData(chartData);
    }
  }, [data]);

  return (
    <>
      {marks && marks.length > 0 && (
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className={`small-box bg-gradient-light`}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    padding: '20px',
                    width: 'calc(100% - 350px)',
                  }}
                >
                  <>
                    <Slider
                      step={1}
                      min={0}
                      marks
                      valueLabelDisplay="on"
                      valueLabelFormat={(e) =>
                        new Date(marks[e].Date)
                          .toLocaleDateString(userCTX.authenticationInfos.language, optionsChart)
                          .toUpperCase()
                      }
                      max={marks.length - 1}
                      onChange={(e, v) => {
                        setCurrentIndex(v as number);
                      }}
                      value={currentIndex}
                    ></Slider>
                  </>
                  <div>
                    <Line options={chartOptions} ref={chartRef} data={chartData}></Line>
                  </div>
                </div>
                <div
                  style={{
                    padding: '20px',
                    width: 'calc(350px)',
                  }}
                >
                  <DataTable
                    selectionMode={'single'}
                    selection={selectedRow}
                    stripedRows
                    className="normalDT"
                    value={gridData}
                  >
                    <Column field="palier" header="Palier"></Column>
                    <Column style={{ whiteSpace: 'nowrap' }} field="nbJour" header="J avant arrivée"></Column>
                    <Column field="date" header="Date"></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PickupLineChart;
